import React, {useEffect} from 'react'


const RefundPolicy = () => {




  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

   return (
     <div className="full-width">
       <div className="container">
         <div className="row">
           <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
             <div className="full-width text-white bg-secondary p-2 p-lg-3 my-2">
               <h1 style={{ marginBottom: "30px" }}>Refund Policy</h1>
               <h4 className="heading-primary full-width">1. INTRODUCTION</h4>
               <p>
                 This policy applies to ______ services offered on our Mobile
                 App “_____” and the website: ___________________. We have
                 provided extensive information for you to view the packages
                 before choosing to subscribe to us. If you have any questions
                 or reservations, please contact us at __________________prior
                 to subscribing to our services.
               </p>
               <p>
                 We, being the service providers for contents available through
                 Mobile App and website, where you are expected to view packages
                 of your choice before paying for the subscription, hence, all
                 subscription fees paid to _______ for such services are
                 non-refundable.
               </p>
               <p>
                 In case, because of any technical glitch at the time of online
                 transaction, the transaction does not occur, the amount in
                 process of transfer by default goes back into your bank
                 account, automatically through payment gateway.
               </p>
               <p>
                 <strong>Chargebacks:</strong>
               </p>
               <p>
                 If we receive a chargeback or payment dispute from a credit
                 card company or bank, your service and/or subscription will be
                 suspended without notice. Applicable chargeback fee will be
                 issued to recover fees passed on to us by the credit company,
                 plus any outstanding balances accrued as a result of the
                 chargeback(s) must be paid in full before service is restored.
                 Instead of issuing a chargeback, contact us to address any
                 billing issues. Requesting a chargeback or opening any sort of
                 dispute for a valid charge from us is fraud, and is never an
                 appropriate or legal means of obtaining a refund.
               </p>
               <p>
                 Please read and make sure you fully understand our refund
                 policy prior to making a payment
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
   );
}

export default RefundPolicy;