import React, { useEffect, useState, useContext } from "react";
import { PersonCircleOutline, Pencil, ChevronForward, Warning, Happy } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";

// Custom import
import { Context } from "../../../Context";
import "./my-profile.scss";
import { firebase_app as firebase } from "../../firebase";
import { LOGIN_POPUP, LOGOUT_SUCCESS } from "../../reducer/types";

const MySubscriptions = () => {
  const [subscriptionList, setSubscriptionList] = useState([]);

  const { state, dispatch } = useContext(Context);

  const history = useHistory();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  useEffect(
    (prevState) => {
      console.log("State - ", state);

      if (state.subscriptions && state.subscriptions.length > 0) {
        setSubscriptionList(state.subscriptions.reverse());
      }

      setTimeout(() => {
        if (!state.isAuthenticated) {
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: true,
            },
          });
        } else {
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: false,
            },
          });
        }
      }, 500);

      return () => {};
    },
    [state.subscriptions]
  );

  return (
    <div className="full-width pt-2">
      {state.isAuthenticated ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width">
                <h1 className="full-width heading-1 font-weight-normal">
                  My Subscriptions
                </h1>
              </div>
            </div>
            <div className="col-12 mt-2">
              {subscriptionList.length <= 0 ? (
                <div className="first-subscription-info my-5 full-width text-center px-2">
                  Hey! Enjoy 100+ movies in High Quality, Add Free and many more
                  benefits <br />
                  <Link to="/subscribe" className="expireSubscriptionBtn">
                    Subscribe Now
                  </Link>
                </div>
              ) : null}

              {
                // Active Subscription
                subscriptionList.length > 0 && state.activeSubscription ? (
                  <div className="full-width active-subscription-block active-subscription pb-2">
                    <div className="subscription-expiry-info full-width">
                      <Happy
                        size="20"
                        cssClasses="subscriptionActiveIcon"
                      />{" "}
                      Your subscription will expired on{" "}
                      {subscriptionList[0].startDate.split("-")[2] +
                        "-" +
                        subscriptionList[0].startDate.split("-")[1] +
                        "-" +
                        subscriptionList[0].startDate.split("-")[0]}
                    </div>
                  </div>
                ) : null
              }

              {
                // Renew Subscription
                subscriptionList.length > 0 && !state.activeSubscription ? (
                  <div className="full-width active-subscription-block pb-2">
                    <div className="subscription-expiry-info full-width">
                      <Warning size="20" cssClasses="subscriptionEpiryIcon" />{" "}
                      Your subscription expired on{" "}
                      {subscriptionList[0].endDate.split("-")[2] +
                        "-" +
                        subscriptionList[0].endDate.split("-")[1] +
                        "-" +
                        subscriptionList[0].endDate.split("-")[0]}
                      <Link
                        to="/subscribe"
                        className="expireSubscriptionBtn"
                        type="button"
                      >
                        Renew Now
                      </Link>
                    </div>
                  </div>
                ) : null
              }

              {
                // Subscription history
                subscriptionList.length > 0 ? (
                  <div className="full-width">
                    <ul className="user-subscription-list list-unstyled full-width">
                      <li className="header-row">
                        <div className="each-subscription-item full-width">
                          <div
                            className="each-subscription-col date"
                            data-name="Date"
                          >
                            Date
                          </div>
                          <div
                            className="each-subscription-col desc"
                            data-name="Description"
                          >
                            Description
                          </div>
                          <div
                            className="each-subscription-col service-period"
                            data-name="Service Period"
                          >
                            Service Period
                          </div>
                          <div
                            className="each-subscription-col sub-total"
                            data-name="Sub Total"
                          >
                            Sub Total
                          </div>
                          <div
                            className="each-subscription-col total"
                            data-name="Total"
                          >
                            Total
                          </div>
                        </div>
                      </li>
                      {subscriptionList.length > 0
                        ? subscriptionList.map((item, index) => (
                            <li key={"ordr_" + index}>
                              <div className="each-subscription-item full-width">
                                <div
                                  className="each-subscription-col date"
                                  data-name="Date"
                                >
                                  {item.startDate.split("-")[2]}-
                                  {item.startDate.split("-")[1]}-
                                  {item.startDate.split("-")[0]}
                                </div>
                                <div
                                  className="each-subscription-col desc"
                                  data-name="Description"
                                >
                                  {item.name}
                                </div>
                                <div
                                  className="each-subscription-col service-period"
                                  data-name="Service Period"
                                >
                                  {item.startDate.split("-")[2]}-
                                  {item.startDate.split("-")[1]}-
                                  {item.startDate.split("-")[0]} to{" "}
                                  {item.endDate.split("-")[2]}-
                                  {item.endDate.split("-")[1]}-
                                  {item.endDate.split("-")[0]}
                                </div>
                                <div
                                  className="each-subscription-col sub-total"
                                  data-name="Sub Total"
                                >
                                  {item.amount === "0" ? "0" : ""}
                                </div>
                                <div
                                  className="each-subscription-col total"
                                  data-name="Total"
                                >
                                  {item.amount}
                                </div>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                ) : (
                  ""
                )
              }
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MySubscriptions;
