import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

// Custom imports
import "./loadingRedirect.scss";
import { LoadingShort } from "../../components/Loading";
import { Context } from "../../../Context";
import {
  LOGIN_POPUP,
  LOGIN_REDIRECT_URL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../../reducer/types";

import {
  firebase_app as fb,
  currentUser,
  isSignEmailLink,
  analytics
} from "../../firebase";

import firebase from "firebase";
import { getUserDetails } from "../../components/API";

const LoginRedirect = () => {
  const [getuId, setGetuId] = useState(null);
  const { state, dispatch } = useContext(Context);
  const [loginError, setLoginError] = useState(null);

  const history = useHistory();

  const locationUrl = window.location.search;

  useEffect(() => {
    console.log("locationUrl = ", locationUrl);

    /* if (locationUrl) {
      // http://localhost:3008/?apiKey=AIzaSyALz_KOGWZ3cFunjD8Y1PebrmFiEV3IwCY&oobCode=m4NseK0p5Gg1z9Vy-uxrf8BAC2PEHAHepdtGlf9ee-IAAAF4hwCa5A&mode=signIn&lang=en

      const getEmailId =
        locationUrl.split("?") !== undefined
          ? locationUrl.split("?")[1].split("&")[0].split("=")[1]
          : null;
      const getuuId =
        locationUrl.split("?") !== undefined
          ? locationUrl.split("?")[1].split("&")[1].split("=")[1]
          : null;

      console.log("Get Email Id = ", getEmailId);
      console.log("Get UserId = ", getuuId);

      setGetuId(getuuId);

    }
 */
    if (fb.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = localStorage.getItem("email");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");

          if (result.user.displayName) {
            result.user.getIdToken().then((tokenId) => {
              getUserDetails(tokenId)
                .then((res) => {
                  console.log("Google JWT Res - ", res);

                  dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                      ...res,
                    },
                  });

                  analytics.setUserId(state._id ? state._id : "");
                  analytics.logEvent("login", {
                    method: "email",
                  });

                  const subscriptionLink = localStorage.getItem("splan");

                  setTimeout(() => {
                    if (
                      subscriptionLink !== undefined &&
                      subscriptionLink !== null
                    ) {
                      history.push("/subscribe");
                    } else if (locationUrl.split("/")[0] === "?r") {

                      const redirectUrl = locationUrl.split("&api")[0].split("?r")[1];


                      console.log("Login Redirect to - ", redirectUrl);
                      console.log(
                        "Login Redirect to - ",
                        locationUrl.split("&api")[0].split("?r")
                      );
                      dispatch({
                        type: LOGIN_REDIRECT_URL,
                        payload: {
                          redirectUrl: null,
                        },
                      });
                      analytics.setUserId(state._id ? state._id : "");
                      analytics.logEvent("login", {
                        method: "email",
                      });
                      history.push(redirectUrl);
                    } else {
                      history.push("/account/profiles");
                    }
                  }, 1000);
                })
                .catch((err) => {
                  setLoginError(err.message);
                });
            });
          } else {
            console.log(false);

            const name = "user_" + Math.floor(Math.random() * 9999) + 1;
            fb.auth()
              .currentUser.updateProfile({
                displayName: name,
                photoURL: null,
              })
              .then((res) => {
                result.user.getIdToken().then((tokenId) => {
                  getUserDetails(tokenId)
                    .then((res) => {
                      console.log("Google JWT Res - ", res);

                      dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                          ...res,
                        },
                      });

                      analytics.setUserId(state._id ? state._id : "");
                      analytics.logEvent("login", {
                        method: "email",
                      });

                      setTimeout(() => {
                        history.push("/account/profiles");
                      }, 1000);
                    })
                    .catch((err) => {
                      setLoginError(err.message);
                      setTimeout(() => {
                        history.push("/account/profiles");
                      }, 5000);
                    });
                });
              })
              .catch((err) => {
                setLoginError(err.message);
                setTimeout(() => {
                  history.push("/account/profiles");
                }, 5000);
              });
          }
        })
        .catch((error) => {
          console.log("Redirect error - ", error);

          setLoginError(error.message);

          firebase
            .auth()
            .signOut()
            .then((res) => {
              // removing localstorage values
              localStorage.removeItem("id");
              localStorage.removeItem("token");
              localStorage.removeItem("email");
              localStorage.removeItem("name");

              dispatch({
                type: LOGOUT_SUCCESS,
              });

              setTimeout(() => {
                history.push("/");
              }, 5000);
            });
        });
    } else {
    }
  }, [state._id,]);

  return (
    <div className="full-width page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div className="full-width bg-primary rounded px-2 pt-3 pb-5 mt-1">
              <div className="redirecting-block full-width">
                {!loginError ? (
                  <>
                    <div className="loadingCircles">
                      <LoadingShort />
                    </div>{" "}
                    <div className="redirect-text">
                      "One Moment! Getting your details..."
                    </div>{" "}
                  </>
                ) : (
                  <div className="redirect-text">
                    <p>{loginError}</p>
                    <p>Please try login again.</p>
                    <p>
                      <small>Redirecting to home page in 5 seconds.</small>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRedirect;
