import {
  LOGIN_POPUP,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  CHECK_EMAIL,
  CREATE_USER,
  THEME,
  PLANS,
  SELECTED_PLAN,
  REMOVE_SELECTED_PLAN,
  PROMO_CODE,
  PPV,
  REMOVE_PPV,
  LOGIN_REDIRECT_URL,
  LANGUAGE,
  PROFILE,
  HEADER
} from "./types";

const localUserId = localStorage.getItem("id");
const localToken = localStorage.getItem("token");
const localUserName = localStorage.getItem("name");
const selectedPlanId = localStorage.getItem('splan');
const selectedPlanName = localStorage.getItem("splanName");
const selectedPlanPrice = localStorage.getItem("splanPrice");
const getLanguage = JSON.parse(localStorage.getItem("lan"));

export const initialState = {
  token: localToken ? localToken : null,
  _id: localUserId ? localUserId : null,
  name: localUserName ? localUserName : null,
  email: null,
  phone: null,
  loginPopup: false,
  isAuthenticated: false,
  theme: "dark-1",
  plans: [],
  selectedPlan: selectedPlanId ? selectedPlanId : null,
  selectedPlanName: selectedPlanName ? selectedPlanName : null,
  selectedPlanPrice: selectedPlanPrice ? selectedPlanPrice : null,
  promoCode: null,
  activeSubscription: false,
  ppv: null,
  redirectUrl: null,
  language: getLanguage ? getLanguage : "en",
  profileName: null,
  header: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    /*
    // Theme
    case THEME:
      localStorage.setItem(
        "theme",
        action.payload.theme ? action.payload.theme : "dark-1"
      );
      return {
        ...state,
        theme: action.payload.theme ? action.payload.theme : "dark-1",
      }; */

      //Toggle Language
      case LANGUAGE:
        return {
          ...state,
          language: action.payload.language
        }

    // Get Plans
    case PLANS:
      return {
        ...state,
        plans: action.payload.plans,
      };

    // Selected Plan
    case SELECTED_PLAN:
      localStorage.setItem("splan", action.payload.selectedPlan);
      localStorage.setItem("splanName", action.payload.selectedPlanName);
      localStorage.setItem("splanPrice", action.payload.selectedPlanPrice);
      return {
        ...state,
        selectedPlan: action.payload.selectedPlan,
        selectedPlanName: action.payload.selectedPlanName,
        selectedPlanPrice: action.payload.selectedPlanPrice,
      };

    // Promo Code
    case PROMO_CODE:
      localStorage.setItem("prc", action.payload.promoCode);
      return {
        ...state,
        promoCode: action.payload.promoCode,
      };

    // Remove Selected Plan
    case REMOVE_SELECTED_PLAN:
      localStorage.removeItem("splan");
      localStorage.removeItem("prc");
      localStorage.removeItem("splanName");
      localStorage.removeItem("splanPrice");
      localStorage.removeItem("splanopen");
      return {
        ...state,
        selectedPlan: null,
        promoCode: null,
        selectedPlanName: null,
        selectedPlanPrice: null,
      };

    // PPV
    case PPV:
      return {
        ...state,
        ppv: {
          ppvPrice: action.payload.ppv.ppvPrice,
          ppvName: action.payload.ppv.ppvName,
          ppvImage: action.payload.ppv.ppvImage,
          ppvId: action.payload.ppv.ppvId,
          ppvDuration: action.payload.ppv.ppvDuration,
          ppvType: action.payload.ppv.ppvType,
        },
      };

      // Remove PPV Object by making null
      case REMOVE_PPV:
        return {
          ...state,
          ppv: null
        }

      // Login Redirect from /auth/redirect
      case LOGIN_REDIRECT_URL:
        return {
          ...state,
          redirectUrl: action.payload.redirectUrl
        };

    // Show & Hide Login Popup
    case LOGIN_POPUP:
      return {
        ...state,
        loginPopup: action.payload.loginPopup,
      };

    //Header
    case HEADER:
      return {
        ...state,
        header: action.payload.header,
      }

    // After Login Success
    case LOGIN_SUCCESS:
      localStorage.setItem(
        "token",
        action.payload.token ? action.payload.token : ""
      );
      localStorage.setItem("id", action.payload._id ? action.payload._id : "");
      localStorage.setItem(
        "email",
        action.payload.email ? action.payload.email : ""
      );
      localStorage.setItem(
        "name",
        action.payload.name ? action.payload.name : ""
      );
      return {
        ...state,
        ...action.payload,
        token: action.payload.token ? action.payload.token : null,
        email: action.payload.email ? action.payload.email : null,
        name: action.payload.name ? action.payload.name : null,
        phone: action.payload.phone ? action.payload.phone : null,
        isAuthenticated: true,
      };

    case PROFILE:

      return {
        ...state,
        profileName: action.payload.profileName ? action.payload.profileName : null,
        activeProfile: action.payload.activeProfile ? action.payload.activeProfile : ""
      };

    case LOGOUT_SUCCESS:
      localStorage.removeItem("email");
      localStorage.removeItem("id");
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      localStorage.removeItem("splan");
      localStorage.removeItem("prc");
      localStorage.removeItem("splanopen");
      sessionStorage.removeItem("profile");
      return {
        ...state,
        isAuthenticated: false,
        activeSubscription: false,
        redirectUrl: null,
        token: null,
        _id: null,
        email: null,
        name: null,
        ppv: null,
        promoCode: null,
        subscriptions: [],
        likes: [],
        dislikes: [],
        tickets: [],
        wishList: [],
        profileName: null
      };


    default:
      return state;
  }
};
