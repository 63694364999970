import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  VolumeMuteOutline,
  VolumeHighOutline,
  Play,
  AddCircleOutline,
  CheckmarkCircle,
  ReloadCircle,
} from "react-ionicons";
import { motion } from "framer-motion";

import { LockClosed } from "react-ionicons";

// custom imports
import {
  url,
  toggleWishList,
  toggleLikeDislike,
  playVideo,
} from "../../components/API";
import { PosterPotrait } from "../../components/Poster";
import { listSettings, potratPosterSlick } from "../Home";
import "./movieDetail.scss";
import NoData from "../../components/NoData";
import { LoadingStack } from "../../components/Loading";
import { Rupee } from "../../components/Misc";
import like from "../../../assets/icons/thumb_up_off.svg";
import likeFilled from "../../../assets/icons/thumb_up_fill.svg";
import unlike from "../../../assets/icons/thumb_down_off.svg";
import unlikeFilled from "../../../assets/icons/thumb_down_fill.svg";
import { Context } from "../../../Context";
import { logFirebaseEvent } from "../../components/Misc";
import {
  LOGIN_POPUP,
  LOGIN_REDIRECT_URL,
  LOGIN_SUCCESS,
  PPV,
} from "../../reducer/types";
import { analytics } from "../../firebase";

// Video imports
import Trailer1 from "../../../assets/vdo/trailer-3.mp4";
import i18next from "i18next";

//Demo Content
import demoMovie from "../../../assets/images/movies.json";
import demoSeries from "../../../assets/images/series.json";

const MovieDetail = () => {
  const [movDet, setMovDet] = useState(null);
  const [simlMov, setSimlMov] = useState([]);
  const [trailer, setTrailer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [muted, setMuted] = useState(true);
  const [subscribe, setSubscribe] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [inWishList, setInWishList] = useState(false);
  const [inLike, setInLike] = useState(false);
  const [inDislike, setInDislike] = useState(false);
  const [replay, setReplay] = useState(false);
  const [showPoster, setShowPoster] = useState(false);
  const [hasTicket, setHasTicket] = useState(false);

  const trailerPlayer = useRef();

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { state, dispatch } = useContext(Context);

  const trailerPlayerToggle = () => {
    setMuted(!muted);
  };

  const updateSchema = (mDet) => {
    return {
      "@context": "https://schema.org",
      "@type": "Movie",
      actor:
        "actors" in mDet
          ? mDet.actors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      director:
        "directors" in mDet
          ? mDet.directors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      producer:
        "producers" in mDet
          ? mDet.producers.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      genre:
        "genres" in mDet
          ? mDet.genres.map((obj, ind) => {
              return { "@type": "Text", name: obj };
            })
          : [],
      //"isFamilyFriendly" :true ,
      copyrightYear: mDet.year,
      isAccessibleForFree: mDet.model === "free" ? true : false,
      duration: mDet.duration + "secs",
      description: mDet.description,
      name: mDet.title,
      image: mDet.cardImage,
      thumbnailURL: mDet.detailImage,
      url: window.location.href,
      startDate: mDet.availability === "restricted" ? mDet.startDate : null,
      endDate: mDet.availability === "restricted" ? mDet.endDate : null,
    };
  };

  const getMovieDetail = (movId) => {
    console.log("Getting Movie Details");
    axios
      .get(url + "/movies/" + movId)
      .then((res) => {
        // set movie details
        setMovDet(res.data);

        console.log("Movie Details res - ", res);

        console.log("Telugu - ", res.data.i18n.te.title);

        // update schema.org
        updateSchema(res.data);

        // set similar movies data
        res.data.similarMovies.length
          ? setSimlMov(res.data.similarMovies)
          : setSimlMov([]);

        /* const trailer = "trailer" in res.data; */

        console.log("set trailer", trailer);

        //res.data.trailer ? setTrailer(Trailer1) : setTrailer(null);

        // setTrailer(Trailer1);
        setLoading(false);

        // Trnalstion keys
        /* i18next.t("key", {
          title: res.data.i18n.te.title,
          desc: res.data.i18n.te.description,
        }); */

        /* i18next.init({
          resources: {
            en: {
              title: res.data.title,
              desc: res.data.description,
            },
            te: {
              title: res.data.i18n.te.title,
              desc: res.data.i18n.te.description,
            },
          },
        }); */

        /*  i18n.t("en", {
          movieTitle: res.data.title,
          desc: res.data.description,
        });

        i18n.t("te", {
          movieTitle: res.data.i18n.te.title,
          desc: res.data.i18n.te.description,
        });

        i18next.init({

          interpolation: {
            format: (value, format, lang) => {
              console.log('Interpolation values - ', value, format, lang);
            }
          }

        }); */

        /*  i18next.init({
          interpolation: {
            format: (value, format, lng) => {
              console.log('inside movie detail lng - ', lng)
              if(lng === "en") {
                alert('In English');
                i18next.t("en", {
                  title: res.data.title,
                  desc: res.data.description,
                });
              } else if (lng === "te") {
                alert("In Telugu");
                i18next.t("te", {
                  title: res.data.i18n.te.title,
                  desc: res.data.i18n.te.description,
                });
              }
            }
          }
        }) */
      })
      .catch((err) => {
        setLoading(false);
      });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  /* const getSubscribePlans = () => {
    axios
      .get(url + "/plans")
      .then((res) => {
        console.log("Plans - ", res.data.plans);
        setSubscribe(res.data.plans);
      })
      .catch((err) => {
        setSubscribe([]);
      });
  }; */

  const detailPage = (id) => {
    history.push("/movies/" + id);
    getMovieDetail(id);
  };

  const playTrailer = (id) => {
    history.push("/movies/" + id);
  };

  // Toggle Wishlist
  const wishListButton = () => {
    console.log("Card Image - ", movDet.cardImage);
    if (state.isAuthenticated && state.profileName) {
      if (inWishList) {
        setInWishList(false);
        toggleWishList(
          state._id,
          state.token,
          movDet._id,
          "movie",
          movDet.cardImage,
          movDet.title,
          true,
          state.profileName
        ).then((res) => {
          console.log("ITEM REMOVED");

          console.log("WishListButton - ", res);

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              ...res,
            },
          });
        });
      } else {
        setInWishList(true);
        toggleWishList(
          state._id,
          state.token,
          movDet._id,
          "movie",
          movDet.cardImage,
          movDet.title,
          false,
          state.profileName
        ).then((res) => {
          console.log("ITEM ADDED");
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              ...res,
            },
          });
        });
      }
    } else {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true,
        },
      });
    }
  };

  // Like Button
  const likeButton = () => {
    if (state.isAuthenticated) {
      setInLike(true);
      toggleLikeDislike(
        state._id,
        state.token,
        movDet._id,
        "movie",
        movDet.cardImage,
        movDet.title,
        false,
        state.profileName
      ).then((res) => {
        console.log("ITEM Liked - ", res);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...res,
          },
        });
      });
    } else {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true,
        },
      });
    }
  };

  // Dislike Button
  const dislikeButton = () => {
    if (state.isAuthenticated) {
      setInDislike(true);
      toggleLikeDislike(
        state._id,
        state.token,
        movDet._id,
        "movie",
        movDet.cardImage,
        movDet.title,
        true,
        state.profileName
      ).then((res) => {
        console.log("Dis-Liked - ", res);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...res,
          },
        });
      });
    } else {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true,
        },
      });
    }
  };

  // Replay Video
  const replayVideo = () => {
    console.log("Replay video");
    setReplay(false);
    setShowPoster(false);
    setTimeout(() => {
      if (window.video !== null && window.video !== undefined)
        window.video.play();
    }, 300);
  };

  let locUrl = window.location.pathname.split("/")[2];

  // Watch Video
  const watchVideo = (model, movObj) => {
    /* if(model === "free") {
      history.push("/watch");
    } else {
      if (state.isAuthenticated && state.activeSubscription) {
        history.push("/watch");
      } else {
        dispatch({
          type: LOGIN_REDIRECT_URL,
          payload: {
            redirectUrl: "/movies/" + locUrl,
          },
        });
        sessionStorage.setItem("goToUrl", JSON.stringify("/watch"));
        history.push("/subscribe");

      }
    } */

    let movieArguments = {
      id: state._id,
      type: model,
      model: model,
      //obj: movObj,
      obj: movObj,
      authenticated: state.isAuthenticated,
      activeSubscription: state.activeSubscription,
      prevUrl: JSON.stringify(window.location.pathname),
      season: null,
      episode: null,
      playTime: 0,
      profileName: state.profileName,
      //ticketPurchased: true,
    };

    let aaoTestMovie = {
      type: "movie",
      id: demoMovie._id,
      model: demoSeries.model,
      uId: demoMovie.uId,
      token: demoMovie.token,
      obj: demoMovie,
      season: null,
      episode: null,
      playTime: 0,
      watchHistory: [],
      authenticated: true,
      activeSubscription: true,
      prevUrl: window.location.pathname,
      profileName: "me",
      ticketPurchased: null,
    };

    let aaoTestSeries = {
      type: "series",
      id: demoSeries._id,
      model: demoSeries.model,
      uId: demoMovie.uId,
      token: demoMovie.token,
      obj: demoSeries,
      season: "episodes" in demoSeries ? demoSeries.episodes[0].season : 0,
      episode: "episodes" in demoSeries ? demoSeries.episodes[0].number : 0,
      playTime: 0,
      watchHistory: [],
      authenticated: true,
      activeSubscription: true,
      prevUrl: window.location.pathname,
      profileName: "me",
      ticketPurchased: null,
    };

    playVideo(aaoTestSeries).then((res) => {
      console.log("Play Btn res - ", res);

      switch (res) {
        case "success":
          window.location = "/watch/";
          break;

        case "login":
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: true,
            },
          });
          break;

        case "plans":
          if (state.isAuthenticated) {
            history.push("/subscribe");
          } else {
            dispatch({
              type: LOGIN_POPUP,
              payload: {
                loginPopup: true,
              },
            });
          }
          break;

        default:
          if (state.isAuthenticated) {
            history.push("/subscribe");
          } else {
            dispatch({
              type: LOGIN_POPUP,
              payload: {
                loginPopup: true,
              },
            });
          }
          break;
      }
    });
  };

  // Rent this movie
  const ppVideo = () => {
    /* dispatch({
      type: LOGIN_REDIRECT_URL,
      payload: {
        redirectUrl: "/movies/" + locUrl,
      },
    });
    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: true,
      },
    }); */

    if (state.isAuthenticated === true) {
      if (hasTicket === true) {
        history.push("/watch");
      } else {
        dispatch({
          type: LOGIN_REDIRECT_URL,
          payload: {
            redirectUrl: "/movies/" + locUrl,
          },
        });
        dispatch({
          type: LOGIN_POPUP,
          payload: {
            loginPopup: true,
          },
        });

        // set PPV in state
        dispatch({
          type: PPV,
          payload: {
            ppv: {
              ppvPrice: movDet.nonSubscriberPrice,
              ppvName: movDet.title,
              ppvImage: movDet.detailImage,
              ppvId: movDet._id,
              ppvDuration: movDet.streamPeriod / 24,
              ppvType: "movies",
            },
          },
        });
      }
    } else {
      dispatch({
        type: LOGIN_REDIRECT_URL,
        payload: {
          redirectUrl: "/movies/" + locUrl,
        },
      });
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true,
        },
      });
      // set PPV in state
      dispatch({
        type: PPV,
        payload: {
          ppv: {
            ppvPrice: movDet.nonSubscriberPrice,
            ppvName: movDet.title,
            ppvImage: movDet.detailImage,
            ppvId: movDet._id,
            ppvDuration: movDet.streamPeriod / 24,
            ppvType: "movies",
          },
        },
      });
    }

    /* if (state.isAuthenticated) {
      if (state.activeSubscription) {
        dispatch({
          type: PPV,
          payload: {
            ppv: {
              ppvPrice: movDet.subscriberPrice,
              ppvName: movDet.title,
              ppvImage: movDet.detailImage,
              ppvId: movDet._id,
              ppvDuration: movDet.streamPeriod/24,
              ppvType: "movies"
            },
          },
        });
      } else {
        dispatch({
          type: PPV,
          payload: {
            ppv: {
              ppvPrice: movDet.nonSubscriberPrice,
              ppvName: movDet.title,
              ppvImage: movDet.detailImage,
              ppvId: movDet._id,
              ppvDuration: movDet.streamPeriod/24,
              ppvType: "movies",
            },
          },
        });
      }
    } else {
      dispatch({
        type: PPV,
        payload: {
          ppv: {
            ppvPrice: movDet.nonSubscriberPrice,
            ppvName: movDet.title,
            ppvImage: movDet.detailImage,
            ppvId: movDet._id,
            ppvDuration: movDet.streamPeriod/24,
            ppvType: "movies",
          },
        },
      });
    } */
  };

  //Already purchased PPV Ticket
  const ppvWatchVideo = () => {
    if (state.isAuthenticated && hasTicket) {
      history.push("/watch");
    } else {
      ppVideo();
    }
  };

  useEffect(() => {
    const video = document.getElementById("#videoTrailerPlayer");

    //if(video !== null && video !== undefined) {
    window.video = video;
    //}

    setInWishList(false);
    setInLike(false);
    setInDislike(false);
    setHasTicket(false);

    getMovieDetail(locUrl);

    console.log("LOCAL URL - ", locUrl);

    /* getSubscribePlans(); */
    setSubscribe(state.plans);

    // Check if this movie already in wishlist
    if (state.isAuthenticated && state.wishList && state.wishList.length > 0) {
      let activeProfile = state.profileName;
      state.wishList.map((item) => {
        if (item.id === locUrl) {
          setInWishList(true);
        }
      });
    }

    // Check if this movie already in Likes
    if (state.isAuthenticated && state.likes && state.likes.length > 0) {
      state.likes.map((item) => {
        if (item.id === locUrl) {
          setInLike(true);
        }
      });
    }

    // Check if this movie already in DisLikes
    if (state.isAuthenticated && state.dislikes && state.dislikes.length > 0) {
      state.dislikes.map((item) => {
        if (item.id === locUrl) {
          setInDislike(true);
        }
      });
    }

    // Check if this movie already Rented
    if (state.isAuthenticated && state.tickets && state.tickets.length > 0) {
      const allTickets = state.tickets;
      const gotTicket = allTickets.find((ticket) => ticket.id === locUrl);
      console.log("Got ticket - ", gotTicket);

      if (gotTicket !== undefined) {
        const todayDate = new Date();
        const date = ("0" + todayDate.getDate()).slice(-2);
        const month = ("0" + (todayDate.getMonth() + 1)).slice(-2);
        const year = todayDate.getFullYear();
        const hours = ("0" + todayDate.getHours()).slice(-2);
        const minutes = ("0" + todayDate.getMinutes()).slice(-2);
        const seconds = ("0" + todayDate.getSeconds()).slice(-2);
        const nowDate = year + "-" + month + "-" + date;
        const nowTime = hours + ":" + minutes + ":" + seconds;

        // server time
        console.log("Time - ", gotTicket.end.split("T")[1]);

        const serverDate = Date.parse(
          gotTicket.end.split("T")[0] + " " + gotTicket.end.split("T")[1]
        );
        const localDate = Date.parse(nowDate + " " + nowTime);

        console.log("Date comparisson - ", serverDate, localDate);

        if (serverDate > localDate) {
          setHasTicket(true);
        } else {
          setHasTicket(false);
        }
      }
    }

    // Sticky Movie Mini Info
    const stickyDiv = document.getElementById("stickyHeader");
    const stickyHeader = stickyDiv ? stickyDiv.offsetTop : 600;

    const onScrolling = window.addEventListener("scroll", () => {
      if (window.pageYOffset > stickyHeader) {
        setScroll(true);
        if (showPoster !== true) {
          if (replay !== true) {
            if (trailerPlayer && trailerPlayer.current) {
              trailerPlayer.current.pause();
            }
          }
        }
      } else {
        setScroll(false);
        if (showPoster !== true) {
          if (replay !== true) {
            if (trailerPlayer && trailerPlayer.current) {
              trailerPlayer.current.play();
            }
          }
        }
      }
    });

    if (trailerPlayer && trailerPlayer.current) {
      trailerPlayer.current.addEventListener("ended", () => {
        console.log("Video Ended");
        setReplay(true);
        setShowPoster(true);
      });
    }

    return () => {
      window.removeEventListener("scroll", onScrolling);

      if (trailerPlayer && trailerPlayer.current) {
        trailerPlayer.current.removeEventListener("ended", () => {});
      }

      setTrailer(null);

      setLoading(false);
    };
  }, [
    state.isAuthenticated,
    state.likes,
    state.dislikes,
    state.wishList,
    locUrl,
    trailerPlayer.current,
    hasTicket,
    state.tickets,
  ]);

  return (
    <div className="full-width movie-detail-page">
      {/* New Detail Bjanner Block */}
      {loading ? (
        <LoadingStack banner="true" wide={true} />
      ) : (
        <div className="full-width movie-detail-block">
          <div className="movie-player-basic-info full-width">
            {/* Video Player Block Starts */}
            {showPoster ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="full-width trailer-video-block posterImage"
                style={{ backgroundImage: `url(${movDet.detailImage})` }}
              >
                {replay ? (
                  <button
                    type="button"
                    className="volumeToggle btn"
                    onClick={() => replayVideo()}
                  >
                    <ReloadCircle size="50" color="#b6babd" />
                  </button>
                ) : null}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="full-width trailer-video-block"
              >
                <i className="trailer-video-player-overlay"></i>
                <div className="trailer-video-player-block full-width">
                  <video
                    id="videoTrailerPlayer"
                    className="trailer-video-player"
                    autoPlay
                    controls={false}
                    ref={trailerPlayer}
                    muted={muted}
                  >
                    <source src={Trailer1} type="video/mp4" />
                  </video>
                </div>

                {!replay ? (
                  <button
                    type="button"
                    className="volumeToggle btn"
                    onClick={() => trailerPlayerToggle()}
                  >
                    {muted ? (
                      <VolumeMuteOutline size="50" color="#b6babd" />
                    ) : (
                      <VolumeHighOutline size="50" color="#b6babd" />
                    )}
                  </button>
                ) : null}
              </motion.div>
            )}
            {/* Video Player Block Ends */}

            {/* Movie Basic Info Starts */}
            {movDet ? (
              <div className="full-width movie-basic-info">
                <div className="movie-basic-poster">
                  <div className="movie-basic-poster-thumbnail-block full-width">
                    <img
                      src={movDet.cardImage}
                      alt=""
                      className="movie-basic-poster-thumbnail img-fluid"
                    />
                  </div>
                </div>

                <div className="movie-basic-info-block">
                  <div className="movie-basic-type-block full-width">
                    {movDet.model === "free" ? (
                      <span className="movie-model free">
                        {t("free", "Free")}
                      </span>
                    ) : movDet.model === "ppv" ? (
                      <span className="movie-model ppv">
                        {t("ppv", "Rent")}
                      </span>
                    ) : movDet.model === "subscription" ? (
                      <span className="movie-model premium">
                        {t("premium", "Premium")}
                      </span>
                    ) : null}
                  </div>
                  <div className="movie-title full-width">
                    <h1 className="movie-title-text full-width">
                      {t("title", movDet.title)} {/* movDet.title */}
                    </h1>
                  </div>
                  <div className="movie-meta-info full-width">
                    {movDet.genre
                      ? movDet.genre.map((gen, ind) => {
                          <span
                            className="each-meta-info dot"
                            key={"gen" + ind}
                          >
                            {gen}
                          </span>;
                        })
                      : null}
                    {movDet.language ? (
                      <span className="each-meta-info dot">
                        {movDet.language}
                      </span>
                    ) : null}
                    {movDet.maturity ? (
                      <span className="each-meta-info dot">
                        {movDet.maturity}
                      </span>
                    ) : null}
                  </div>

                  <div className="movie-actions-block full-width">
                    {
                      // Watch, Rent Button
                      movDet.model === "ppv" ? (
                        <button
                          className={
                            state.activeSubscription || movDet.model === "free"
                              ? "btn watch-try-btn active-subscription-movie"
                              : "btn watch-try-btn"
                          }
                          type="button"
                          onClick={() => ppVideo()}
                        >
                          <span className="full-width button-toggle-text">
                            {!hasTicket ? (
                              <>
                                <span className="desktop-button rent-title-button d-flex full-width">
                                  <Play size="30" color="#ffffff" />
                                  {t("ppv", "Rent")}
                                  <span className="rupee">
                                    <Rupee className="ppvRupee" />
                                  </span>
                                  {state.activeSubscription
                                    ? movDet.subscriberPrice
                                    : movDet.nonSubscriberPrice}
                                </span>
                                <span className="desktop-sub-info rent-sub-title full-width">
                                  {t("watchWithinEnglish", "Watch within")}{" "}
                                  {movDet.streamPeriod / 24}{" "}
                                  {t("watchWithinDaysEnglish", "days")}{" "}
                                  {t("watchWithinTelugu", "")}{" "}
                                </span>{" "}
                              </>
                            ) : (
                              <>
                                <span className="desktop-button rent-title-button d-flex full-width">
                                  <Play size="30" color="#ffffff" />
                                  {t("watch", "Watch")}
                                </span>
                              </>
                            )}
                          </span>
                        </button>
                      ) : movDet.model === "subscription" ||
                        movDet.model === "free" ? (
                        <button
                          className={
                            state.activeSubscription || movDet.model === "free"
                              ? "btn watch-try-btn active-subscription-movie"
                              : "btn watch-try-btn"
                          }
                          type="button"
                          onClick={() => watchVideo(movDet.model, movDet)}
                        >
                          <span className="full-width button-toggle-text premium-button">
                            {state.activeSubscription === false &&
                            movDet.model !== "free" ? (
                              <>
                                <span className="desktop-button rent-title-button desktop-watch-btn d-flex full-width">
                                  <Play size="30" color="#ffffff" />
                                  {t("watch", "Watch")}
                                </span>
                                <span className="desktop-sub-info rent-sub-title full-width">
                                  {t("tryFor", "Try for")}{" "}
                                  <span className="rupee">
                                    <Rupee className="ppvRupee" />
                                  </span>
                                  {state.plans && state.plans.length > 0
                                    ? state.plans[0].price
                                    : null}{" "}
                                </span>{" "}
                              </>
                            ) : movDet.model === "free" ||
                              state.activeSubscription ? (
                              <>
                                <span className="desktop-button rent-title-button desktop-watch-btn d-flex full-width">
                                  <Play size="30" color="#ffffff" />
                                  {t("watch", "Watch")}
                                </span>
                              </>
                            ) : null}
                          </span>
                        </button>
                      ) : null
                    }

                    <button
                      className={
                        inWishList
                          ? "btn add-fav-btn active"
                          : "btn add-fav-btn"
                      }
                      type="button"
                      onClick={() => wishListButton()}
                    >
                      {inWishList ? (
                        <>
                          <CheckmarkCircle size="30" color={"#ffffff"} />
                          {t("addedWishList", "Added Wishlist")}
                        </>
                      ) : (
                        <>
                          <AddCircleOutline size="30" color={"#ffffff"} />
                          {t("addWishList", "Add Wishlist")}
                        </>
                      )}
                    </button>
                    <div className="likeDislikeBlock">
                      <button
                        className={
                          inLike
                            ? "likeDislikeBtn btn like active"
                            : "likeDislikeBtn btn like"
                        }
                        type="button"
                        onClick={() => likeButton()}
                        disabled={inLike}
                      >
                        {inLike ? (
                          <img src={likeFilled} alt="" />
                        ) : (
                          <img src={like} alt="" />
                        )}
                      </button>

                      <button
                        className={
                          inDislike
                            ? "likeDislikeBtn btn unLikeBtn active"
                            : "likeDislikeBtn btn unLikeBtn"
                        }
                        type="button"
                        onClick={() => dislikeButton()}
                        disabled={inDislike}
                      >
                        {inDislike ? (
                          <img src={unlikeFilled} alt="" />
                        ) : (
                          <img src={unlike} alt="" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Movie Basic Info Ends */}
          </div>

          {/* Movie Sticky Starts */}
          {movDet ? (
            <>
              <div
                className={
                  scroll
                    ? "movie-sticky-info-block full-width sticky"
                    : "movie-sticky-info-block full-width"
                }
              >
                <div className="movie-sticky-poster-info">
                  <div className="movie-sticky-thumbnail-block">
                    <img
                      src={movDet.detailImage}
                      alt=""
                      className="movie-basic-poster-thumbnail img-fluid"
                    />
                  </div>
                  <div className="movie-sticky-info">
                    <div className="movie-sticky-model-block full-width">
                      {movDet.model === "free" ? (
                        <span className="movie-model free">
                          {t("free", "Free")}
                        </span>
                      ) : movDet.model === "subscription" ? (
                        <span className="movie-model premium">
                          {t("premium", "Premium")}
                        </span>
                      ) : null}
                    </div>

                    <div className="movie-sticky-title full-width">
                      <div className="movie-sticky-title-text full-width">
                        {t("title", movDet.title)} {/* movDet.title */}
                      </div>
                      <div className="movie-meta-info full-width">
                        {movDet.genre
                          ? movDet.genre.map((gen, ind) => {
                              <span
                                className="each-meta-info dot"
                                key={"gen" + ind}
                              >
                                {gen}
                              </span>;
                            })
                          : null}
                        {movDet.language ? (
                          <span className="each-meta-info dot">
                            {movDet.language}
                          </span>
                        ) : null}
                        {movDet.maturity ? (
                          <span className="each-meta-info dot">
                            {movDet.maturity}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="movie-sticky-actions-block">
                  <div className="watch-try-block">
                    {movDet.model === "ppv" ? (
                      !hasTicket ? (
                        // purchase ticket and watch
                        <button
                          className="btn watch-try-btn"
                          type="button"
                          onClick={() => ppVideo()}
                        >
                          <span className="watch-btn ppv">
                            <Play size="30" color="#ffffff" />
                            {t("ppv", "Rent")}
                            <Rupee className="ppvRupee" />
                            {state.activeSubscription
                              ? movDet.subscriberPrice
                              : movDet.nonSubscriberPrice}
                          </span>

                          <span className="try-text-block">
                            {/* For mobile only price starts */}
                            <span className="d-md-none mobile-ppv-price">
                              <Rupee
                                className="ppvRupee"
                                style={{ fontSize: "13px" }}
                              />
                              {state.activeSubscription
                                ? movDet.subscriberPrice
                                : movDet.nonSubscriberPrice}{" "}
                              -{" "}
                            </span>
                            {/* For mobile only price starts */}
                            {t("watchWithinEnglish", "Watch within")}{" "}
                            <span className="try-rupee-text">
                              {movDet.streamPeriod / 24}{" "}
                              {t("watchWithinDaysEnglish", "days")}{" "}
                              {t("watchWithinTelugu", "")}{" "}
                            </span>
                          </span>
                        </button>
                      ) : (
                        // Directly watch video
                        <button
                          className="btn watch-try-btn"
                          type="button"
                          onClick={() => ppvWatchVideo()}
                        >
                          <span className="watch-btn ppv">
                            <Play size="30" color="#ffffff" />
                            {t("watch", "Watch")}
                          </span>
                        </button>
                      )
                    ) : (
                      <button
                        className="btn watch-try-btn"
                        type="button"
                        onClick={() => watchVideo()}
                      >
                        <span className="watch-btn ppv">
                          <Play size="30" color="#ffffff" />
                          {t("watch", "Watch")}
                        </span>

                        {state.plans &&
                        state.plans.length > 0 &&
                        movDet.model !== "free" ? (
                          <span className="try-text-block">
                            <Play
                              color={"#ffffff"}
                              title="Watch Movie"
                              height="24px"
                              width="24px"
                              className="movie-play-btn-icon"
                            />{" "}
                            {t("tryFor", "Try for")}
                            <br />
                            <span className="try-rupee">
                              <Rupee />
                            </span>{" "}
                            <span className="try-rupee-text">
                              {state.plans[0].price}
                            </span>
                          </span>
                        ) : null}
                      </button>
                    )}
                  </div>
                  <div className="add-wishlist-block">
                    <button
                      className={
                        inWishList
                          ? "btn add-fav-btn active"
                          : "btn add-fav-btn"
                      }
                      type="button"
                      onClick={() => wishListButton()}
                    >
                      {inWishList ? (
                        <>
                          <CheckmarkCircle size="30" color={"#ffffff"} />
                          {t("addedWishList", "Added Wishlist")}
                        </>
                      ) : (
                        <>
                          <AddCircleOutline size="30" color={"#ffffff"} />
                          {t("addWishList", "Add Wishlist")}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {/* Movie Sticky Ends */}

          {movDet ? (
            <div className="full-width movie-desc-block py-3" id="stickyHeader">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-10 offset-md-1">
                    <div className="full-width movie-desc">
                      {t("desc", movDet.description)} {/* movDet.description */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {/* New Detail Banner Block */}

      {/* Trailers & extras Starts */}
      {trailer ? (
        <div className="trailers-extra full-width bg-dark">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="full-width playlist-row mt-2">
                  <h2 className="playlist-heading full-width pb-2">
                    <span className="playlist-heading-title">
                      {t("trailersExtras", "Trailers & Extras")}
                    </span>
                  </h2>
                  <div className="playlist-video-list full-width">
                    <Slider {...potratPosterSlick}>
                      <PosterPotrait
                        id={movDet._id}
                        key={movDet._id + "TXta"}
                        image={movDet.detailImage ? movDet.detailImage : null}
                        title={
                          movDet.title ? movDet.title + " - trailer" : null
                        }
                        genre={movDet.genre ? movDet.genre : null}
                        metaData=""
                        desc={movDet.description ? movDet.description : null}
                        type="movie"
                        wide={true}
                        onClick={() => playTrailer(movDet._id)}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* Trailers & extras Ends */}

      {/* Similar Movies Starts */}
      {simlMov.length ? (
        <div className="full-width mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="full-width playlist-row">
                  <h2 className="playlist-heading full-width pb-2">
                    <span className="playlist-heading-title">
                      {t("similarMovies", "Similar Movies")}
                    </span>
                  </h2>
                  <div className="playlist-video-list full-width">
                    <Slider {...listSettings}>
                      {simlMov.map((movie, index) => {
                        let metaData = [];
                        if (movie.year) {
                          metaData.push(movie.year);
                        }
                        if (movie.maturity) {
                          metaData.push(movie.maturity);
                        }
                        if (movie.language) {
                          metaData.push(movie.language);
                        }
                        return (
                          <PosterPotrait
                            id={movie._id}
                            key={movie._id + "MGItm"}
                            image={movie.cardImage ? movie.cardImage : null}
                            title={movie.title ? movie.title : null}
                            genre={movie.genre ? movie.genre : null}
                            metaData={metaData}
                            desc={movie.description ? movie.description : null}
                            type="movie"
                            onClick={() => detailPage(movie._id)}
                            model={movie.model ? movie.model : null}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* Similar Movies Ends */}
    </div>
  );
};

export default MovieDetail;
