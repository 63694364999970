import React, { useState, useEffect, useContext } from "react";
import { Pencil, LockClosed, InformationCircle } from "react-ionicons";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

// Custom Imports
import "./profiles.scss";
import { Context } from "../../../Context";
import ParentSmiley from "../../../assets/icons/smiley-1.svg";
import KidSmiley from "../../../assets/icons/smiley-kid-1.svg";
import KidSmiley2 from "../../../assets/icons/smiley-kid-2.svg";
import KidSmiley3 from "../../../assets/icons/smileys-kid-3.svg";
import Plus from "../../../assets/icons/plus-solid-white.svg";
import { firebase_app as firebase } from "../../firebase";
import {
  LANGUAGE,
  LOGIN_POPUP,
  LOGOUT_SUCCESS,
  HEADER,
  PROFILE,
} from "../../reducer/types";
import {
  authHeaders,
  getAllProfiles,
  toggleLikeDislike,
  toggleWishList,
  url,
} from "../../components/API";
import axios from "axios";

import { IoIosLock } from "react-icons/io";

const nameRegEx = /^\w+$/;

const editProfileValidation = Yup.object().shape({
  profileName: Yup.string()
    .required("Enter your name")
    .min(3, "Must be exactly 3 characters")
    .max(10, "Must be exactly 10 characters")
    .matches(nameRegEx, "No spaces and special characters"),
  child: Yup.boolean().optional(),
});

const addNewProfileValidation = Yup.object().shape({
  profileName: Yup.string()
    .required("Enter your name")
    .min(3, "Must be exactly 3 characters")
    .max(10, "Must be exactly 10 characters")
    .matches(nameRegEx, "No spaces and special characters"),
  child: Yup.bool().optional(),
});

const Profiles = () => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editProfileData, setEditProfileData] = useState(null);
  const [enableAddProfile, setEnableAddProfile] = useState(false);
  const [profiles, setProfiles] = useState(null);
  const [hasName, setHasName] = useState(false);
  const [profLength, setProfLength] = useState([]);

  const { state, dispatch } = useContext(Context);

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const mangeProfiles = () => {
    setEnableEdit(!enableEdit);
  };

  // Add New Profile
  const addNewProfile = () => {
    setEditProfile(false);
    setEnableEdit(false);
    setEnableAddProfile(true);
  };

  // Cancel Add New Profile
  const cancelAddProfile = () => {
    setEditProfile(false);
    setEnableEdit(false);
    setEnableAddProfile(false);
  };

  // Profile Button Click go to Relavant action
  const showUpdateProfile = (name, child) => {
    console.log("Show update Profile - ", name, child);

    /* toggleWishList(
      state._id,
      state.token,
      'vId1234',
      'series',
      'https://m.media-amazon.com/images/I/71M9AFsF6TL._SL1280_.jpg',
      'Videotitle123',
      false,
      name
    ).then((res) => {
      console.log("Toggle Wishlist - ", res);
      getAllProfilesList();
    }); */

    /*  toggleLikeDislike(
      state._id,
      state.token,
      'video1234',
      'series',
      'https://www.movieimageUrl.com/image.png',
      'DisLike Movie Title 1',
      false,
      name
    ).then((res) => {
      console.log("Likes Dislike - ", res);
      getAllProfilesList();
    }); */

    if (enableEdit && name !== "me") {
      setEditProfile(true);
      setEnableEdit(false);
      setEditProfileData({ profileName: name, child: child });
    } else {
      localStorage.setItem("profile", name);
      let timeStamp = (Date.now() + 60 * 60 * 60 * 1000).toString();
      console.log("Time Stamp - ", timeStamp);
      localStorage.setItem("ts", timeStamp);

      dispatch({
        type: PROFILE,
        payload: {
          profileName: name,
        },
      });
      history.push("/");

      // For Demo Purpose
    }
  };

  // Hide Update Profile
  const cancelUpdateProfile = () => {
    setEditProfile(false);
    setEnableEdit(true);
    setEditProfileData(null);
  };

  // Update Profile details
  const updateProfile = (val) => {
    console.log("Update Profile - ", val);
    setEditProfile(false);
    setEnableEdit(true);
    setEnableAddProfile(false);
  };

  // Add New Profile
  const addNewProfileSubmit = (values, actions) => {
    console.log("new profile values - ", values);

    actions.setSubmitting(false);

    Object.keys(profiles).map((prof) => {
      if (prof === values.profileName) {
        setHasName(true);
      }
    });

    if (!hasName) {
      console.log("Existing Name -", values.profileName);

      const profilePostBody = JSON.stringify({
        name: values.profileName,
        isChild: values.child,
      });

      axios
        .post(
          url + "/users/" + state._id + "/profiles",
          profilePostBody,
          authHeaders(state.token)
        )
        .then((res) => {
          console.log("Add New Profile Res - ", res.data);

          setEditProfile(false);
          setEnableEdit(false);
          setEnableAddProfile(false);

          actions.setSubmitting(true);

          getAllProfilesList();

          /* getAllProfiles(state._id, state.token).then((res) => {
            setProfiles(res.profiles);
            profilesLength();
          }); */
        });
    }
  };

  //Delete Profile
  const deleteProfileSubmit = (name) => {
    console.log(
      "Delete Profile -",
      url + "/" + state._id + "/profiles/" + name
    );

    let confirmAction = window.confirm(
      "If you Delete " +
        name +
        " profile, all Likes, Watch History and Wishlist will also be deleted permanently?"
    );

    if (confirmAction) {
      console.log("Confirmed to delete");
      axios
        .delete(
          url + "/users/" + state._id + "/profiles/" + name,
          authHeaders(state.token)
        )
        .then((res) => {
          setEditProfile(false);
          setEnableEdit(false);
          setEnableAddProfile(false);

          getAllProfilesList();
        });
    }
  };

  // Check profiles length
  /* const profilesLength = () => {
    if (profiles !== null) {
      Object.keys(profiles).map((prof, ind) => {
        if (ind === 4) {
          setProfLength(false);
        }
      });
    }
  };
 */
  //Open Login popup
  const openLogin = () => {
    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: true,
      },
    });
  };

  //Get All Profiles List
  const getAllProfilesList = () => {
    getAllProfiles(state._id, state.token).then((res) => {
      console.log("Profile page - list - ", res);

      let profileNumber = [];

      Object.keys(res.profiles).map((prof, ind) => {
        profileNumber.push(ind);
        /*  if (ind === 3) {
           console.log("Profile length - ", ind);
           setProfLength(false);
         } */
      });

      setProfLength(profileNumber);

      console.log("Profiles number - ", profileNumber);

      setProfiles(res.profiles);
    });
  };

  useEffect(() => {

    dispatch({
      type: HEADER,
      payload: {
        header: false
      }
    });


    if (!state.isAuthenticated) {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true,
        },
      });
    } else {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: false,
        },
      });

      getAllProfilesList();

      /* getAllProfiles(state._id, state.token).then((res) => {
        console.log("Profile page - list - ", res);
        setProfiles(res.profiles);
        profilesLength();
      }); */
    }

    return () => {
      dispatch({
        type: HEADER,
        payload: {
          header: true,
        },
      });
    }
  }, [state.isAuthenticated]);

  return (
    <div className="full-width">
      <div className="container">
        <div className="row">
          {state.isAuthenticated ? (
            <div className="col-12 py-3">
              {!enableAddProfile ? (
                <div className="full-width">
                  {!editProfile ? (
                    <div className="profiles-list-display full-width">
                      <div className="full-width profiles-title page-heading text-center pb-2">
                        {t("whoIsWatching", "Who is watching")}
                      </div>
                      <div className="full-width profiles-list">
                        {profiles
                          ? Object.keys(profiles).map((profile, index) =>
                              index === 0 ? (
                                <div
                                  className={
                                    enableEdit
                                      ? "btn each-profile-btn permanent-profile edit-profile"
                                      : "btn each-profile-btn"
                                  }
                                  onClick={() =>
                                    showUpdateProfile(
                                      profile,
                                      profiles[profile].isChild
                                    )
                                  }
                                  key={"pof" + index}
                                >
                                  <div className="each-profile-pic full-width profile-bg pf-3">
                                    <img src={ParentSmiley} alt="" />
                                  </div>
                                  <div className="each-profile-name full-width">
                                    {profile}
                                  </div>
                                </div>
                              ) : (
                                <button
                                  className={
                                    enableEdit
                                      ? "btn each-profile-btn edit-profile"
                                      : "btn each-profile-btn"
                                  }
                                  type="button"
                                  onClick={() =>
                                    showUpdateProfile(
                                      profile,
                                      profiles[profile].isChild
                                    )
                                  }
                                  key={"pof" + index}
                                >
                                  <div className="each-profile-pic full-width profile-bg pf-3">
                                    <img src={ParentSmiley} alt="" />
                                  </div>
                                  <div className="each-profile-name full-width">
                                    {profiles[profile].isChild ? (
                                      <>
                                        <IoIosLock />{" "} {profile}
                                      </>
                                    ) : (
                                       profile
                                    )}
                                  </div>
                                  <span className="editProfileIconBlock">
                                    <Pencil
                                      color={"#fff"}
                                      width={"30px"}
                                      height={"30px"}
                                      cssClasses="editProfileIcon"
                                    />
                                  </span>
                                </button>
                              )
                            )
                          : ""}
                      </div>
                      <div className="full-width manage-profile-button-block text-center mt-3">
                        {profLength.length > 1 ? (
                          <button
                            className="btn manage-profiles-btn"
                            type="button"
                            onClick={() => mangeProfiles()}
                          >
                            <Pencil
                              color={"#fff"}
                              width={"15px"}
                              height={"15px"}
                              cssClasses="addPlusIcon"
                            />
                            {enableEdit ? "Done" : "Manage Profile"}
                          </button>
                        ) : (
                          ""
                        )}

                        {profLength.length < 4 ? (
                          <button
                            className="btn manage-profiles-btn"
                            type="button"
                            onClick={() => addNewProfile()}
                          >
                            <img src={Plus} alt="" className="addPlusIcon" />
                            Add New Profile
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="full-width profile-edit-form">
                      {/* Update Profile Starts */}
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mt-1">
                            <div className="full-width my-profile-new">
                              <div className="profile-photo-block">
                                <span className="photo-design-block  profile-bg pf-3">
                                  <img src={ParentSmiley} alt="" />
                                </span>
                              </div>
                              <div className="profile-details-info">
                                <div className="profile-name-desg full-width">
                                  <Formik
                                    enableReinitialize
                                    initialValues={{
                                      profileName: editProfileData.profileName,
                                      child: editProfileData.child,
                                    }}
                                    validationSchema={addNewProfileValidation}
                                    onSubmit={(values, actions) => {
                                      updateProfile(values);
                                    }}
                                  >
                                    {(formik) => {
                                      const { isValid, dirty, isSubmitting } =
                                        formik;
                                      return (
                                        <Form className="login-form">
                                          <ul className="form-list list-unstyled full-width mt-3 mb-0">
                                            <li>
                                              <div className="form-item full-width d-flex flex-wrap">
                                                <div className="full-width border-bottom border-gray">
                                                  <Field
                                                    name="profileName"
                                                    id="profileName"
                                                    type="text"
                                                    className="form-control login-input profileNameInput"
                                                    placeholder="Enter Profile Name..."
                                                  />
                                                </div>
                                                <div className="full-width">
                                                  <ErrorMessage
                                                    name="profileName"
                                                    component="div"
                                                    className="form-error"
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="form-item mt-2 full-width d-flex flex-wrap">
                                                <div className="full-width">
                                                  <div className="full-width">
                                                    <label className="switch">
                                                      <Field
                                                        type="checkbox"
                                                        name="child"
                                                        className="switch-input"
                                                      />
                                                      <span className="switch-content">
                                                        {t(
                                                          "lockForChildren",
                                                          "Child?"
                                                        )}
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="full-width text-gray d-flex align-items-center mt-05">
                                                    <small>
                                                      <em>
                                                        <InformationCircle
                                                          width={"15px"}
                                                          height={"15px"}
                                                          color={"#ccc"}
                                                        />{" "}
                                                        If activated, only PG,
                                                        UPG content will be
                                                        served!{" "}
                                                      </em>
                                                    </small>
                                                  </div>
                                                </div>
                                                <div className="full-width">
                                                  <ErrorMessage
                                                    name="profileName"
                                                    component="div"
                                                    className="form-error"
                                                  />
                                                </div>
                                              </div>
                                            </li>

                                            <li>
                                              <div className="form-item full-width mt-2">
                                                <div className="row">
                                                  <div className="col">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary submit-phone-btn full-width"
                                                      disabled={
                                                        isSubmitting
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      {isSubmitting ? (
                                                        <span>
                                                          {t(
                                                            "pleaseWait",
                                                            "Please wait..."
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          {t(
                                                            "update",
                                                            "Update"
                                                          )}{" "}
                                                        </span>
                                                      )}
                                                    </button>
                                                  </div>
                                                  <div className="col">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-outline-gray full-width"
                                                      onClick={() =>
                                                        cancelUpdateProfile()
                                                      }
                                                    >
                                                      <span>Cancel</span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="form-item full-width mt-2">
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-gray delete-profile-btn full-width"
                                                  onClick={() =>
                                                    deleteProfileSubmit(
                                                      editProfileData.profileName
                                                    )
                                                  }
                                                >
                                                  <span>Delete Profile</span>
                                                </button>
                                              </div>
                                            </li>
                                          </ul>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Update Profile Ends */}
                    </div>
                  )}
                </div>
              ) : (
                <div className="full-width">
                  {/* Add Profile Starts */}
                  <div className="full-width profile-edit-form">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mt-1">
                          <div className="full-width my-profile-new">
                            <div className="profile-photo-block">
                              <span className="photo-design-block  profile-bg pf-3">
                                <img src={ParentSmiley} alt="" />
                              </span>
                            </div>
                            <div className="profile-details-info">
                              <div className="profile-name-desg full-width">
                                <Formik
                                  //enableReinitialize
                                  initialValues={{
                                    profileName: "",
                                    child: false,
                                  }}
                                  validationSchema={addNewProfileValidation}
                                  onSubmit={(values, actions) => {
                                    console.log(values);
                                    //updateProfile(values);
                                    addNewProfileSubmit(values, actions);
                                  }}
                                >
                                  {(formik) => {
                                    const {
                                      isValid,
                                      dirty,
                                      isSubmitting,
                                      values,
                                    } = formik;
                                    return (
                                      <Form className="login-form">
                                        <ul className="form-list list-unstyled full-width mt-3 mb-0">
                                          <li>
                                            <div className="form-item full-width d-flex flex-wrap">
                                              <div className="full-width border-bottom border-gray">
                                                <Field
                                                  name="profileName"
                                                  id="profileName"
                                                  type="text"
                                                  className="form-control login-input profileNameInput"
                                                  placeholder="Enter Profile Name..."
                                                />
                                              </div>

                                              <div className="full-width">
                                                <ErrorMessage
                                                  name="profileName"
                                                  component="div"
                                                  className="form-error"
                                                />
                                              </div>
                                              {hasName ? (
                                                <div className="full-width form-error">
                                                  Name already exists
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </li>
                                          <li>
                                            <div className="form-item mt-2 full-width d-flex flex-wrap">
                                              <div className="full-width">
                                                <div className="full-width text-white">
                                                  <label className="switch">
                                                    <Field
                                                      type="checkbox"
                                                      name="child"
                                                      className="switch-input"
                                                    />
                                                    <span className="switch-content">
                                                      {t(
                                                        "lockForChildren",
                                                        "Child?"
                                                      )}
                                                    </span>
                                                  </label>
                                                  {/* <label className="switch">
                                                    <input
                                                      name="child"
                                                      id="child"
                                                      type="checkbox"
                                                      onChange={() => !values.child}
                                                      className="switch-input"
                                                    />
                                                    <span className="switch-content">
                                                      {t(
                                                        "lockForChildren",
                                                        "Child"
                                                      )}
                                                      {values.child === true ? "True" : "False"}
                                                    </span>
                                                  </label> */}
                                                </div>
                                                <div className="full-width text-gray d-flex align-items-center mt-05">
                                                  <small>
                                                    <em>
                                                      <InformationCircle
                                                        width={"15px"}
                                                        height={"15px"}
                                                        color={"#ccc"}
                                                      />{" "}
                                                      If activated, only PG, UPG
                                                      content will be served!{" "}
                                                    </em>
                                                  </small>
                                                </div>
                                              </div>
                                              <div className="full-width"></div>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="form-item full-width mt-2 addProfileBlock">
                                              <button
                                                type="submit"
                                                className="btn btn-primary submit-phone-btn full-width"
                                                disabled={
                                                  isSubmitting ? true : false
                                                }
                                              >
                                                {isSubmitting ? (
                                                  <span>
                                                    {t(
                                                      "pleaseWait",
                                                      "Please wait..."
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {t(
                                                      "addProifle",
                                                      "Add Profile"
                                                    )}{" "}
                                                  </span>
                                                )}
                                              </button>
                                              <button
                                                type="button"
                                                className="cancelAddProfile btn"
                                                onClick={() =>
                                                  cancelAddProfile()
                                                }
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </li>
                                        </ul>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Add Profile Ends */}
                </div>
              )}
            </div>
          ) : (
            <div className="col-12 py-3">
              <div className="full-width pleaseLoginBlock">
                <div className="full-width text-center">
                  Hey!!! Check your profile now! <br />
                  <button
                    className="btn loginNowBtn"
                    type="button"
                    onClick={() => openLogin()}
                  >
                    Click here to Login Now
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profiles;
