import React, { useState, useEffect, useRef, useContext } from "react";
import { animate, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import {
  ArrowBackOutline,
  CloseCircleOutline,
  MailUnreadOutline,
  ChevronForward,
  LogoFacebook,
  LogoGoogle,
  CheckmarkCircle,
  CallOutline,
  Checkmark,
  PersonCircleOutline,
  TrashBinOutline,
  TrashOutline,
} from "react-ionicons";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import * as Yup from "yup";

import {
  firebase_app as firebase,
  recaptcha,
  googleProvider,
  facebookProvider,
  currentUser,
  analytics,
} from "../../firebase";
import axios from "axios";

// Custom import
import "./login.scss";
import { Context } from "../../../Context";
import {
  LOGIN_SUCCESS,
  LOGIN_POPUP,
  SELECTED_PLAN,
  PROMO_CODE,
  LOGOUT_SUCCESS,
  REMOVE_SELECTED_PLAN,
  REMOVE_PPV,
  PPV,
} from "../../reducer/types";
import { getUserDetails, url } from "../API";
import { Rupee } from "../../components/Misc";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z0-9])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,10}$/;

const loginValidation = Yup.object().shape({
  phone: Yup.string()
    .required("Enter your Mobile number")
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .matches(phoneRegExp, "Only numbers please"),
  country: Yup.string(),
});

const otpValidation = Yup.object().shape({
  otp: Yup.string()
    .required("Enter OTP number received on your phone")
    .min(6, "Must be exactly 6 characters")
    .max(6, "Must be exactly 6 characters")
    .matches(/^\d+$/, "Only numbers please"),
});

const discountValidation = Yup.object().shape({
  discount: Yup.string().required("Enter promo code"),
});

const loginValidations = Yup.object().shape({
  loginEmail: Yup.string()
    .email("Please enter valid Email id")
    .required("Please enter registered Email id"),
});

const Login = () => {
  const [phoneLogin, setPhoneLogin] = useState(true);
  const [otp, setOtp] = useState(false);
  const [loginEmail, setLoginEmail] = useState(false);
  const [loginPhoneNumber, setLoginPhoneNumber] = useState(null);
  const [loginPhoneError, setLoginPhoneError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [logedIn, setLogedIn] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [subscribeUrl, setSubscribeUrl] = useState(false);
  const [subscribe, setSubscribe] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [hasPromoCode, setHasPromoCode] = useState(false);
  const [promoError, setPromoError] = useState(null);
  const [discAmount, setDiscAmount] = useState(null);
  const [hasTicket, setHasTicket] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();

  const { state, dispatch } = useContext(Context);

  const recaptchaContainer = useRef();

  const googleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((googleRes) => {
        console.log("Google Res - ", googleRes.additionalUserInfo);

        if (googleRes.user.displayName) {
          googleRes.user
            .getIdToken()
            .then((tokenId) => {
              console.log("Google token id - ", tokenId);

              getUserDetails(tokenId)
                .then((res) => {
                  console.log("Google JWT Res - ", res);
                  dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                      ...res,
                      name: googleRes.additionalUserInfo.profile.name
                        ? googleRes.additionalUserInfo.profile.name
                        : null,
                      email: googleRes.additionalUserInfo.profile.email
                        ? googleRes.additionalUserInfo.profile.email
                        : null,
                      phone: googleRes.additionalUserInfo.profile.phone
                        ? googleRes.additionalUserInfo.profile.phone
                        : null,
                    },
                  });

                  history.push("/account/profiles");

                  analytics.setUserId(res._id ? res._id : "");
                  analytics.logEvent("login", {
                    method: "google",
                  });

                  console.log("google login subscribeUrl - ", subscribeUrl);
                  if (!subscribeUrl) {
                    dispatch({
                      type: LOGIN_POPUP,
                      payload: {
                        loginPopup: false,
                      },
                    });
                  }

                  goToPhoneLogin();
                })
                .catch((err) => {
                  setLoginError(
                    t(
                      "sorrySomethingWentWrong",
                      "Sorry something went wrong! Please try again"
                    )
                  );
                  setTimeout(() => {
                    dispatch({
                      type: LOGIN_POPUP,
                      payload: {
                        loginPopup: false,
                      },
                    });
                  }, 6000);
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 7000);
                });

              /* */
            })
            .catch((err) => {
              setLoginError(err.message);
              setTimeout(() => {
                dispatch({
                  type: LOGIN_POPUP,
                  payload: {
                    loginPopup: false,
                  },
                });
              }, 6000);
              setTimeout(() => {
                window.location.reload(false);
              }, 7000);
            });
        } else {
          const name = "user_" + Math.floor(Math.random() * 9999) + 1;
          firebase
            .auth()
            .currentUser.updateProfile({
              displayName: name,
              photoURL: null,
            })
            .then((res) => {
              googleRes.user.getIdToken().then((tokenId) => {
                console.log("Google token id - ", tokenId);

                getUserDetails(tokenId)
                  .then((res) => {
                    console.log("Google JWT Res - ", res);
                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: {
                        ...res,
                        name: googleRes.additionalUserInfo.profile.name
                          ? googleRes.additionalUserInfo.profile.name
                          : null,
                        email: googleRes.additionalUserInfo.profile.email
                          ? googleRes.additionalUserInfo.profile.email
                          : null,
                        phone: googleRes.additionalUserInfo.profile.phone
                          ? googleRes.additionalUserInfo.profile.phone
                          : null,
                      },
                    });

                    history.push("/account/profiles");

                    analytics.setUserId(res._id ? res._id : "");
                    analytics.logEvent("login", {
                      method: "google",
                    });
                  })
                  .catch((err) => {
                    setLoginError(
                      "Sorry something went wrong! Please try again "
                    );
                    setTimeout(() => {
                      dispatch({
                        type: LOGIN_POPUP,
                        payload: {
                          loginPopup: false,
                        },
                      });
                    }, 6000);
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 7000);
                  });

                /* */
              });
            })
            .catch((err) => {
              setLoginError(err.message);
            });
        }
      })
      .catch((err) => {
        console.log("Google login error - ", err.message);
        setLoginError(err.message);
        setTimeout(() => {
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: false,
            },
          });
        }, 6000);
        setTimeout(() => {
          window.location.reload(false);
        }, 7000);
      });
  };

  const facebookLogin = () => {
    firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((facebookRes) => {
        console.log("Facebook Res - ", facebookRes);

        if (facebookRes.user.displayName) {
          facebookRes.user.getIdToken().then((tokenId) => {
            getUserDetails(tokenId)
              .then((res) => {
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: {
                    ...res,
                    name: facebookRes.additionalUserInfo.profile.name
                      ? facebookRes.additionalUserInfo.profile.name
                      : null,
                    email: facebookRes.additionalUserInfo.profile.email
                      ? facebookRes.additionalUserInfo.profile.email
                      : null,
                    phone: facebookRes.additionalUserInfo.profile.phone
                      ? facebookRes.additionalUserInfo.profile.phone
                      : null,
                  },
                });

                history.push("/account/profiles");

                analytics.setUserId(res._id ? res._id : "");
                analytics.logEvent("login", {
                  method: "facebook",
                });

                console.log("google login subscribeUrl - ", subscribeUrl);
                if (!subscribeUrl) {
                  dispatch({
                    type: LOGIN_POPUP,
                    payload: {
                      loginPopup: false,
                    },
                  });
                }

                goToPhoneLogin();
              })
              .catch((err) => {
                setLoginError("Sorry something went wrong! Please try again");
                setTimeout(() => {
                  dispatch({
                    type: LOGIN_POPUP,
                    payload: {
                      loginPopup: false,
                    },
                  });
                }, 6000);
                setTimeout(() => {
                  window.location.reload(false);
                }, 7000);
              });
          });
        } else {
          const name = "user_" + Math.floor(Math.random() * 9999) + 1;
          firebase
            .auth()
            .currentUser.updateProfile({
              displayName: name,
              photoURL: null,
            })
            .then((res) => {
              facebookRes.user.getIdToken().then((tokenId) => {
                getUserDetails(tokenId)
                  .then((res) => {
                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: {
                        ...res,
                        name: facebookRes.additionalUserInfo.profile.name
                          ? facebookRes.additionalUserInfo.profile.name
                          : null,
                        email: facebookRes.additionalUserInfo.profile.email
                          ? facebookRes.additionalUserInfo.profile.email
                          : null,
                        phone: facebookRes.additionalUserInfo.profile.phone
                          ? facebookRes.additionalUserInfo.profile.phone
                          : null,
                      },
                    });

                    history.push("/account/profiles");

                    analytics.setUserId(res._id ? res._id : "");
                    analytics.logEvent("login", {
                      method: "facebook",
                    });

                    console.log("facebook login subscribeUrl - ", subscribeUrl);
                    if (!subscribeUrl) {
                      dispatch({
                        type: LOGIN_POPUP,
                        payload: {
                          loginPopup: false,
                        },
                      });
                    }

                    goToPhoneLogin();
                  })
                  .catch((err) => {
                    setLoginError(
                      "Sorry something went wrong! Please try again"
                    );
                    setTimeout(() => {
                      dispatch({
                        type: LOGIN_POPUP,
                        payload: {
                          loginPopup: false,
                        },
                      });
                    }, 6000);
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 7000);

                    goToPhoneLogin();
                  });
              });
            })
            .catch((err) => {
              setLoginError(err.message);
              dispatch({
                type: LOGIN_POPUP,
                payload: {
                  loginPopup: true,
                },
              });

              setTimeout(() => {
                dispatch({
                  type: LOGIN_POPUP,
                  payload: {
                    loginPopup: false,
                  },
                });
              }, 6000);

              setTimeout(() => {
                window.location.reload(false);
              }, 7000);

              goToPhoneLogin();
            });
        }

        dispatch({
          type: LOGIN_POPUP,
          payload: {
            loginPopup: false,
          },
        });
        goToPhoneLogin();
      })
      .catch((err) => {
        console.log("Facebook login error - ", err.message);
      });
  };

  const loginWithPhoneNumber = (values) => {
    console.log("Phone number login values - ", values);
    const phoneNumber = values.country + values.phone;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptcha(recaptchaContainer.current))
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("code sent confirmation message - ", confirmationResult);
        setLoginPhoneNumber(phoneNumber);
        setOtp(true);
        setPhoneLogin(false);
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log("code sent error - ", error);
        setLoginPhoneError("Something went wrong, please try again");
        recaptcha().clear();
      });
  };

  const verifyOTP = (values, { setSubmitting }) => {
    window.confirmationResult
      .confirm(values.otp)
      .then((otpVerifyRes) => {
        console.log("Veirfy Otp Res - ", otpVerifyRes);
        if (otpVerifyRes.user.displayName) {
          otpVerifyRes.user.getIdToken().then((tokenId) => {
            getUserDetails(tokenId)
              .then((res) => {
                console.log("Google JWT Res - ", res);

                setOtp(false);

                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: {
                    ...res,
                  },
                });

                history.push('/account/profiles');

                analytics.setUserId(res._id ? res._id : "");
                analytics.logEvent("login", {
                  method: "mobile",
                });
              })
              .catch((err) => {
                setLoginError("Sorry something went wrong! Please try again ");
                setTimeout(() => {
                  dispatch({
                    type: LOGIN_POPUP,
                    payload: {
                      loginPopup: false,
                    },
                  });
                }, 6000);
                setTimeout(() => {
                  window.location.reload(false);
                }, 7000);
              });
          });
        } else {
          console.log(false);

          const name = "user_" + Math.floor(Math.random() * 9999) + 1;
          firebase
            .auth()
            .currentUser.updateProfile({
              displayName: name,
              photoURL: null,
            })
            .then((res) => {
              otpVerifyRes.user.getIdToken().then((tokenId) => {
                getUserDetails(tokenId)
                  .then((res) => {
                    console.log("Google JWT Res - ", res);

                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: {
                        ...res,
                      },
                    });

                    analytics.setUserId(res._id ? res._id : "");
                    analytics.logEvent("login", {
                      method: "mobile",
                    });

                    console.log(
                      "verify otp login subscribeUrl - ",
                      subscribeUrl
                    );
                    if (!subscribeUrl) {
                      dispatch({
                        type: LOGIN_POPUP,
                        payload: {
                          loginPopup: false,
                        },
                      });
                    }

                    goToPhoneLogin();
                  })
                  .catch((err) => {
                    setLoginError(
                      "Sorry something went wrong! Please try again "
                    );
                    setTimeout(() => {
                      dispatch({
                        type: LOGIN_POPUP,
                        payload: {
                          loginPopup: false,
                        },
                      });
                    }, 6000);
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 7000);
                  });
              });
            })
            .catch((err) => {
              setLoginError(err.message);
              setTimeout(() => {
                dispatch({
                  type: LOGIN_POPUP,
                  payload: {
                    loginPopup: false,
                  },
                });
              }, 6000);
              setTimeout(() => {
                window.location.reload(false);
              }, 7000);
            });
        }
      })
      .catch((err) => {
        console.log("OTP err - ", err);
        console.log(err.message);
        //setOtpError("Entered OTP is wrong, Please try again!");
        setOtpError(t("otpWrong", "Entered OTP is wrong, Please try again!"));
        setLoginPhoneError(null);
        setSubmitting(false);
      });
  };

  let emailLoginTimer;

  const loginWithEmail = (values) => {
    console.log("Got Login Details - ", values.loginEmail);

    let urlRedirect;

    if (state.redirectUrl) {
      //urlRedirect = "http://rvrapi.ibee.ai/auth/redirect?r" + state.redirectUrl;
      //urlRedirect = "http://localhost:3010/auth/redirect?r" + state.redirectUrl;
      urlRedirect =
        "https://demo.rvr-webapp.pages.dev/auth/redirect?r" + state.redirectUrl;
    } else {
      //urlRedirect = "http://rvrapi.ibee.ai/auth/redirect";
      //urlRedirect = "http://localhost:3010/auth/redirect";
      urlRedirect = "https://demo.rvr-webapp.pages.dev/auth/redirect";
    }

    const actionCodeSettings = {
      url: urlRedirect,
      handleCodeInApp: true,
    };

    console.log(actionCodeSettings.url);

    firebase
      .auth()
      .sendSignInLinkToEmail(values.loginEmail, actionCodeSettings)
      .then((loginEmailRes) => {
        console.log("check login redirect appended - ", urlRedirect);
        console.log("Login Email Res - ", loginEmailRes);
        localStorage.setItem("email", values.loginEmail);
        setPhoneLogin(false);
        setOtp(false);
        setLoginEmail(false);
        setEmailSentSuccess(true);
        setLogedIn(false);
        localStorage.setItem("splanopen", "s");
        setTimeout(() => {
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: false,
            },
          });

          setEmailSentSuccess(false);
          /* history.push("/"); */
        }, 5000);
      })
      .catch((loginErr) => {
        console.log("Email Link Err - ", loginErr);
        setLoginError(loginErr.message);
      });
  };

  const goToPhoneLogin = () => {
    setPhoneLogin(true);
    setOtp(false);
    setLoginEmail(false);
    setOtpError(null);
  };

  const goToEmailLogin = () => {
    setPhoneLogin(false);
    setOtp(false);
    setLoginEmail(true);
  };

  const loginPopupClose = () => {
    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: false,
      },
    });
    goToPhoneLogin();
    clearTimeout();
  };

  // PPV Popup Close
  const ppvPopupClose = () => {
    loginPopupClose();
    dispatch({
      type: REMOVE_PPV,
    });
  };

  // Select Plan
  const selectPlanId = (id, name, price) => {
    setSelectPlan(id);

    dispatch({
      type: SELECTED_PLAN,
      payload: {
        selectedPlan: id,
        selectedPlanName: name,
        selectedPlanPrice: price,
      },
    });
  };

  const localUrl = useLocation();

  // Apply PromoCode
  const applyDiscount = (values, { setSubmitting }) => {
    console.log("Discount code = ", values);
    axios
      .post(url + "/discounts/validate?code=" + values.discount, null, {
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Promocode Res - ", res);
        if (res.data.success) {
          analytics.setUserId(state._id ? state._id : "");
          analytics.logEvent("purchase_discount", {
            order_id: "",
            item_id: "",
            item_name: "",
            coupon: "",
            item_revenue: "",
            price: "",
            discount: "",
            plan_price: "",
          });

          setPromoError(null);
          setDiscAmount(res.data.discountAmount);
          setPromoCode(values.discount);
          setHasPromoCode(false);

          dispatch({
            type: PROMO_CODE,
            payload: {
              promoCode: values.discount,
            },
          });
        } else {
          setPromoError("Applied coupon code is not Valid");
          setSubmitting(false);
        }
      })
      .catch((err) => {
        setPromoError("Applied coupon code is not Valid");
        setSubmitting(false);
      });
  };

  // Remove PromoCode
  const removePromoCode = () => {
    dispatch({
      type: PROMO_CODE,
      payload: {
        promoCode: null,
      },
    });
  };

  // Toggle ApplyCode From
  const toggleApplyCodeForm = () => {
    setHasPromoCode(false);
    setPromoError(null);
  };

  // Subscribe Popup logout
  const changeUser = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setLogedIn(false);
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  //PayNow Subscription
  const payNow = () => {
    const subscriptionBody = JSON.stringify({
      planId: state.selectedPlan,
      discountCode: state.promoCode ? state.promoCode : "",
    });

    console.log("Pay now values - ", state.selectedPlan, state.promoCode);

    axios
      .post(url + "/orders", subscriptionBody, {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((ordRes) => {
        console.log(ordRes.data);


        // Firebase Begin Checkout
        analytics.setUserId(state._id ? state._id : "");
        analytics.logEvent("begin_checkout", {
          order_id: ordRes.data.id,
          discount: state.promoCode ? state.promoCode : "",
          plan_price: state.selectedPlanPrice ? state.selectedPlanPrice : "",
          items: [
            {
              item_id: state.selectedPlan ? state.selectedPlan : "",
              item_name: state.selectedPlanName ? state.selectedPlanName : "",
              item_revenue: state.selectedPlanPrice
                ? state.selectedPlanPrice
                : "",
              coupon: state.promoCode ? "true" : "false",
              price: state.selectedPlanPrice ? state.selectedPlanPrice : "",
            },
          ],
        });

        // Firebae CouponCode
        if(state.promoCode) {
          analytics.logEvent("purchase_discount", {
            order_id: ordRes.data.id,
            discount: state.promoCode ? state.promoCode : "",
            plan_price: state.selectedPlanPrice ? state.selectedPlanPrice : "",
            items: [
              {
                item_id: state.selectedPlan ? state.selectedPlan : "",
                item_name: state.selectedPlanName ? state.selectedPlanName : "",
                item_revenue: state.selectedPlanPrice
                  ? state.selectedPlanPrice
                  : "",
                coupon: state.promoCode ? "true" : "false",
                price: state.selectedPlanPrice ? state.selectedPlanPrice : "",
              },
            ],
          });
        }

        // Razorpay Starts
        if (ordRes.data.amount <= 0) {
          dispatch({
            type: REMOVE_SELECTED_PLAN,
          });
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: false,
            },
          });
          sessionStorage.removeItem("promo");
          history.push("/account/my-subscriptions");
        } else {
          const rzrpy = new window.Razorpay({
            // key: "rzp_test_UFO2oInWXoDIAA",
            key: "rzp_test_B6q4UA49jje56x",
            name: "RVR OTT",
            order_id: ordRes.data.id,
            handler: function (response) {
              const razorBody = JSON.stringify({
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              });

              axios
                .post(
                  url + "/orders/" + ordRes.data.id + "/charge",
                  razorBody,
                  {
                    headers: {
                      accept: "application/json",
                      Authorization: "Bearer " + state.token,
                    },
                  }
                )
                .then((res) => {
                  console.log("Order ID Charge", res.data);

                  analytics.setUserId(state._id ? state._id : "");
                  analytics.logEvent("purchase", {
                    order_id: ordRes.data.id,
                    discount: state.promoCode ? state.promoCode : "",
                    plan_price: state.selectedPlanPrice
                      ? state.selectedPlanPrice
                      : "",
                    items: [
                      {
                        item_id: state.selectedPlan ? state.selectedPlan : "",
                        item_name: state.selectedPlanName
                          ? state.selectedPlanName
                          : "",
                        item_revenue: state.selectedPlanPrice
                          ? state.selectedPlanPrice
                          : "",
                        coupon: state.promoCode ? "true" : "false",
                        price: state.selectedPlanPrice
                          ? state.selectedPlanPrice
                          : "",
                      },
                    ],
                  });

                  setTimeout(() => {
                    dispatch({
                      type: REMOVE_SELECTED_PLAN,
                    });
                  }, 1000);

                  dispatch({
                    type: LOGIN_POPUP,
                    payload: {
                      loginPopup: false,
                    },
                  });
                  sessionStorage.removeItem("promo");
                  history.push("/account/my-subscriptions");
                });
            },
            prefill: {
              name: state.name ? state.name : "",
              email: state.email ? state.email : "",
              contact: state.phone ? state.phone : "",
            },
            notes: {
              address: "RVR",
            },
            theme: {
              color: "#ec1c29",
            },
          });
          rzrpy.open();
        }
        // Razorpay Ends
      })
      .catch((err) => {
        console.log("Order error - ", err);
      });
  };

  // Pay for PPV Now
  const payPPVNow = () => {
    //Create ticket
    const ticketBody = JSON.stringify({
      movieId: state.ppv.ppvId,
    });

    console.log("PPV Button - ", state.ppv.ppvId);

    axios
      .post(url + "/tickets", ticketBody, {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((ticketRes) => {
        console.log(ticketRes.data);

        // Razorpay Starts
        if (ticketRes.data.amount <= 0) {
          dispatch({
            type: REMOVE_SELECTED_PLAN,
          });
          dispatch({
            type: LOGIN_POPUP,
            payload: {
              loginPopup: false,
            },
          });
          sessionStorage.removeItem("promo");
          history.push("/account/my-subscriptions");
        } else {
          const rzrpy = new window.Razorpay({
            // key: "rzp_test_UFO2oInWXoDIAA",
            key: "rzp_test_B6q4UA49jje56x",
            name: "RVR OTT",
            order_id: ticketRes.data.id,
            handler: function (response) {
              const razorBody = JSON.stringify({
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              });

              axios
                .post(
                  url + "/tickets/" + ticketRes.data.id + "/charge",
                  razorBody,
                  {
                    headers: {
                      accept: "application/json",
                      Authorization: "Bearer " + state.token,
                    },
                  }
                )
                .then((res) => {
                  console.log("Order ID Charge", res.data);
                  dispatch({
                    type: REMOVE_SELECTED_PLAN,
                  });
                  dispatch({
                    type: LOGIN_POPUP,
                    payload: {
                      loginPopup: false,
                    },
                  });
                  sessionStorage.removeItem("promo");
                  history.push("/" + state.ppv.ppvType + "/" + state.ppv.ppvId);

                  setTimeout(() => {
                    dispatch({
                      type: REMOVE_PPV,
                    });
                    window.location.reload(false);
                  }, 1500);
                });
            },
            prefill: {
              name: "",
              email: "",
              contact: "",
            },
            notes: {
              address: "RVR",
            },
            theme: {
              color: "#ec1c29",
            },
          });
          rzrpy.open();
        }
        // Razorpay Ends
      })
      .catch((err) => {
        console.log("Order error - ", err);
      });
  };

  useEffect(
    (prevState) => {
      window.scrollTo(0, 0, animate);
      setHasTicket(false);
      setHasPromoCode(false);

      let updatedPlanId = localStorage.getItem("splan");
      let updatedPromoCode = localStorage.getItem("prc");

      console.log(
        "Localstorage planId & PromoCode - ",
        updatedPlanId,
        updatedPromoCode
      );

      if (state.selectedPlan) {
        setSelectPlan(state.selectedPlan);
      }

      setSubscribe(state.plans);

      if (localUrl.pathname.split("/")[1] === "subscribe") {
        setSubscribeUrl(true);
      } else {
        setSubscribeUrl(false);
      }

      console.log("Ref - ", recaptchaContainer.current);

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //var uid = user.uid;
          setLogedIn(true);

          // Check if this PPV movie already Rented
          if (
            state.isAuthenticated &&
            state.tickets &&
            state.tickets.length > 0 &&
            state.ppv
          ) {
            const allTickets = state.tickets;
            const gotTicket = allTickets.find(
              (ticket) => ticket.id === state.ppv.ppvId
            );
            console.log("Got ticket - ", gotTicket);

            if (gotTicket !== undefined) {
              const todayDate = new Date();
              const date = ("0" + todayDate.getDate()).slice(-2);
              const month = ("0" + (todayDate.getMonth() + 1)).slice(-2);
              const year = todayDate.getFullYear();
              const hours = ("0" + todayDate.getHours()).slice(-2);
              const minutes = ("0" + todayDate.getMinutes()).slice(-2);
              const seconds = ("0" + todayDate.getSeconds()).slice(-2);
              const nowDate = year + "-" + month + "-" + date;
              const nowTime = hours + ":" + minutes + ":" + seconds;

              // server time
              console.log("Time - ", gotTicket.end.split("T")[1]);

              const serverDate = Date.parse(
                gotTicket.end.split("T")[0] + " " + gotTicket.end.split("T")[1]
              );
              const localDate = Date.parse(nowDate + " " + nowTime);

              console.log("Date comparisson - ", serverDate, localDate);

              if (serverDate > localDate) {
                setHasTicket(true);
                dispatch({
                  type: REMOVE_PPV,
                });
                dispatch({
                  type: LOGIN_POPUP,
                  payload: {
                    loginPopup: false,
                  },
                });
              } else {
                setHasTicket(false);
                dispatch({
                  type: LOGIN_POPUP,
                  payload: {
                    loginPopup: true,
                  },
                });
              }
            }
          }

          const goToUrl = sessionStorage.getItem("goToUrl");

          if (!subscribeUrl && state.ppv === null) {
            loginPopupClose();

            // PUsh to Profiles page
            //history.push('/account/profiles');

            if (goToUrl) {
              history.push(JSON.parse(goToUrl));
              sessionStorage.removeItem("goToUrl");
            }

            /* setTimeout(() => {
            history.push('/account/profiles');
          }, 700); */
          } else {
            dispatch({
              type: LOGIN_POPUP,
              payload: {
                loginPopup: true,
              },
            });
          }

          // If user came from Subscribe > Email Login > Subscribe Page
          const subscribePopOpen = localStorage.getItem("splanopen");
          if (
            subscribeUrl === true &&
            subscribePopOpen !== undefined &&
            subscribePopOpen !== null
          ) {
            dispatch({
              type: LOGIN_POPUP,
              payload: {
                loginPopup: true,
              },
            });
          }
        } else {
          // User is signed out
          // ...
          setLogedIn(false);
        }
      });

      return () => {
        //window.removeEventListener("scroll", scrollEvent);
        clearTimeout();
      };
    },
    [
      state.plans,
      state.token,
      localUrl,
      subscribeUrl,
      state.selectedPlan,
      state.promoCode,
    ]
  );

  return (
    <div>
      {state.loginPopup ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="login-outer-block"
          id="loginId"
        >
          <motion.i
            className="login-outer-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
          ></motion.i>

          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="login-form-outer-block"
          >
            <div
              className={
                subscribeUrl || state.ppv
                  ? "login-block subscribeBlock"
                  : "login-block"
              }
            >
              <div className="full-width login-main">
                {
                  //If PPV is selected
                  state.ppv && !subscribeUrl ? (
                    <div className="full-width ppv-block">
                      <div className="ppv-heading-block full-width">
                        <div className="ppv heading-text">
                          <span className="number bg-success">
                            <Checkmark size="20" color={"#fff"} />
                          </span>

                          {t(
                            "selectMovieSeriesTitle",
                            "Select Movie/Series Title"
                          )}
                        </div>
                        <button
                          className="btn login-close-btn"
                          type="button"
                          onClick={() => ppvPopupClose()}
                        >
                          <CloseCircleOutline color={"#999"} />
                        </button>
                      </div>
                      <div className="ppv-image">
                        <img src={state.ppv.ppvImage} alt="" />
                      </div>
                      <div className="ppv-title-info">
                        <div className="ppv-title full-width">
                          {state.ppv.ppvName}
                        </div>
                        <div className="ppv-duration full-width">
                          {t("watchWithinEnglish", "Watch within")}{" "}
                          {state.ppv.ppvDuration}{" "}
                          {t("watchWithinDaysEnglish", "days")}{" "}
                          {t("watchWithinTelugu", "")}{" "}
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  // If in subscribe page and popup opened
                  subscribeUrl ? (
                    <div className="login-subscribe-container">
                      <div className="full-width">
                        <div className="login-form-header full-width plans-header">
                          <div className="login-title">
                            {subscribeUrl ? (
                              state.splan !== null ? (
                                <span className="number bg-success">
                                  <Checkmark size="20" color={"#fff"} />
                                </span>
                              ) : (
                                <span className="number">1</span>
                              )
                            ) : null}
                            {t("package", "Package")}
                          </div>
                          <button
                            className="btn login-close-btn"
                            type="button"
                            onClick={() => loginPopupClose()}
                          >
                            <CloseCircleOutline color={"#999"} />
                          </button>
                        </div>
                        <div className="pop-packages-block full-width">
                          {state.plans.length > 0
                            ? state.plans.map((plan, index) => (
                                <label
                                  key={"subscib" + index}
                                  className="subscription-radio radio"
                                >
                                  <input
                                    type="radio"
                                    className="radio-input"
                                    name="subscription"
                                    onChange={() =>
                                      selectPlanId(
                                        plan._id,
                                        plan.name,
                                        plan.price
                                      )
                                    }
                                    checked={
                                      selectPlan === plan._id ? true : false
                                    }
                                  />
                                  <div className="subscription-details mb-0 full-width">
                                    <i className="selected-icon-block">
                                      <CheckmarkCircle />
                                    </i>
                                    <div className="subscription-details-block full-width">
                                      <div className="each-subscription-column-title full-">
                                        {plan.name}
                                      </div>
                                      <div className="each-subscription-price full-width">
                                        <i className="rupee-currency">
                                          <Rupee />
                                        </i>
                                        <span className="price-text">
                                          {plan.price}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              ))
                            : null}
                        </div>

                        {/* Have Promocode Starts */}
                        <div className="hasPromoCodeBlock full-width">
                          {!state.promoCode ? (
                            !hasPromoCode ? (
                              <div className="hasPromoCodeText full-width text-center">
                                {t("havePromoCode", "Have Promocode ?")}{" "}
                                <button
                                  type="button"
                                  className="btn togglePromoCodeBtn"
                                  onClick={() => setHasPromoCode(true)}
                                >
                                  {t("applyNow", "Apply Now")}
                                </button>
                              </div>
                            ) : (
                              <div className="hasPromoFormBlock full-width">
                                <Formik
                                  initialValues={{ discount: "" }}
                                  validationSchema={discountValidation}
                                  onSubmit={applyDiscount}
                                >
                                  {(formik) => {
                                    const { isValid, dirty, isSubmitting } =
                                      formik;
                                    return (
                                      <Form className="login-form">
                                        <ul className="form-list list-unstyled full-width mb-0">
                                          <li>
                                            <div className="form-item full-width d-flex flex-wrap">
                                              <div className="discount-form-block full-width">
                                                <Field
                                                  name="discount"
                                                  id="discount"
                                                  type="text"
                                                  className="form-control discount-input"
                                                  placeholder="Enter promo code..."
                                                />
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary apply-discount-btn full-width"
                                                  disabled={
                                                    isSubmitting ? true : false
                                                  }
                                                >
                                                  {isSubmitting ? (
                                                    <span>...</span>
                                                  ) : (
                                                    <span>
                                                      {t("applyNow", "Apply")}{" "}
                                                      <Checkmark
                                                        title={"Back"}
                                                        height="14px"
                                                        width="14px"
                                                        color="white"
                                                      />
                                                    </span>
                                                  )}
                                                </button>
                                                <button
                                                  className="btn toggleHavePromoCode-btn"
                                                  type="button"
                                                  onClick={() =>
                                                    toggleApplyCodeForm()
                                                  }
                                                >
                                                  <CloseCircleOutline
                                                    size="20"
                                                    color={"#999"}
                                                    cssClasses="toggleHavePromoIcon"
                                                  />
                                                </button>
                                              </div>
                                              <ErrorMessage
                                                name="discount"
                                                component="div"
                                                className="form-error full-width"
                                              />
                                              {promoError ? (
                                                <div className="form-error full-width discount-error">
                                                  {promoError}
                                                </div>
                                              ) : null}
                                            </div>
                                          </li>
                                        </ul>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              </div>
                            )
                          ) : (
                            <div className="full-width applied-promo-code">
                              <div className="success-promo-message">
                                <CheckmarkCircle size="20" color={"#17b978"} />{" "}
                                {t("promoApplied", "Promo Applied")}
                              </div>
                              <div className="applied-code-block">
                                <span className="appliedCode">
                                  <span className="appliedCode">
                                    {promoCode ? promoCode : null}
                                  </span>
                                  <button
                                    className="removePromoCode btn"
                                    type="button"
                                    onClick={() => removePromoCode()}
                                  >
                                    <TrashOutline
                                      size="15"
                                      cssClasses="deletePromoIcon"
                                      color={"#aaa"}
                                    />
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Have Promocode Ends */}
                      </div>
                    </div>
                  ) : null
                }
                <div className="full-width login-main-block" id="loginHeader">
                  <div className="login-container full-width">
                    <div className="login-form-header login-header full-width">
                      <div className="login-title">
                        {subscribeUrl || state.ppv ? (
                          state.isAuthenticated ? (
                            <span className="number bg-success">
                              <Checkmark size="20" color={"#fff"} />
                            </span>
                          ) : (
                            <span className="number">2</span>
                          )
                        ) : null}
                        {t("login", "Login")}
                        <button
                          className="btn login-close-btn"
                          type="button"
                          onClick={() => loginPopupClose()}
                        >
                          <CloseCircleOutline color={"#999"} />
                        </button>
                      </div>
                    </div>
                    <div className="login-form-content full-width">
                      {(subscribeUrl || state.ppv) && logedIn ? (
                        <div className="full-width subscribe-name-logout">
                          <div className="name-block">
                            <PersonCircleOutline size={20} color={"#999"} />{" "}
                            {firebase.auth().currentUser.displayName}
                          </div>
                          <button
                            className="btn subscribe-logout-btn"
                            type="button"
                            onClick={() => changeUser()}
                          >
                            {t("change", "Change")}
                          </button>
                        </div>
                      ) : null}
                      {state.isAuthenticated ? (
                        !subscribeUrl && !state.ppv ? (
                          <div className="full-width alreadyLoggedIn">
                            <div className="full-width loginHeading success-logedin login-form-block ">
                              {t(
                                "successfullyLogedIn",
                                "You have Successfully LogedIn!"
                              )}
                              <button type="button" className="btn btn-outline-gray mt-2" onClick={() => changeUser()}>
                                Logout
                              </button>
                            </div>
                            {/* <div className="full-width success-sub-info">
                              This will be auto closed in 5 seconds...
                            </div> */}
                          </div>
                        ) : null
                      ) : (
                        <div className="full-width login-form-block">
                          {/* Login Flow Starts */}
                          <div
                            id="recaptchaContainer"
                            ref={(node) => (recaptchaContainer.current = node)}
                          ></div>
                          {!emailSentSuccess ? (
                            <div className="full-width loginBg">
                              {/* Phone Number Form Starts */}
                              {phoneLogin ? (
                                <motion.div
                                  initial={{ opacity: 0, scale: 0.97 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{ delay: 0.3 }}
                                  className="phone-number-login full-width"
                                >
                                  {!subscribeUrl ? (
                                    <div className="full-width login-intro-text">
                                      {t(
                                        "newToWebsite",
                                        "New to our Website or Existing user"
                                      )}
                                    </div>
                                  ) : null}
                                  <div className="full-width loginHeading">
                                    {t("continuePhone", "Continue with Phone!")}{" "}
                                  </div>
                                  <div className="full-width login-form-block d-flex flex-wrap justify-content-center px-0">
                                    {loginPhoneError ? (
                                      <div className="alert alert-danger">
                                        {loginPhoneError}
                                      </div>
                                    ) : null}
                                    <Formik
                                      enableReinitialize
                                      initialValues={{
                                        phone: "",
                                        country: "+91",
                                      }}
                                      validationSchema={loginValidation}
                                      onSubmit={(values) => {
                                        console.log(values);
                                        loginWithPhoneNumber(values);
                                      }}
                                    >
                                      {(formik) => {
                                        const {
                                          isValid,
                                          dirty,
                                          isValidating,
                                          isSubmitting,
                                        } = formik;
                                        return (
                                          <Form className="login-form mt-2">
                                            <ul className="form-list list-unstyled full-width mb-0">
                                              <li>
                                                <div className="form-item full-width">
                                                  <div className="phone-country-block full-width">
                                                    <select
                                                      name="country"
                                                      id="country"
                                                      className="phone-country-code"
                                                      defaultValue="+91"
                                                    >
                                                      <option value="+91">
                                                        +91
                                                      </option>
                                                    </select>
                                                    <Field
                                                      name="phone"
                                                      id="phone"
                                                      type="tel"
                                                      className="form-control phone-input"
                                                      placeholder="Enter your Mobile number"
                                                    />
                                                  </div>
                                                  <div className="full-width">
                                                    <ErrorMessage
                                                      name="phone"
                                                      component="div"
                                                      className="form-error"
                                                    />
                                                  </div>
                                                </div>
                                              </li>

                                              <li>
                                                <div className="form-item full-width mt-2">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary submit-phone-btn full-width"
                                                    id="loginSubmit"
                                                    disabled={
                                                      isSubmitting
                                                        ? !loginPhoneError
                                                          ? true
                                                          : false
                                                        : false
                                                    }
                                                  >
                                                    {isSubmitting ? (
                                                      !loginPhoneError ? (
                                                        <span>
                                                          {t(
                                                            "pleaseWait",
                                                            "Please wait..."
                                                          )}
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          {t(
                                                            "continue",
                                                            "Continue"
                                                          )}{" "}
                                                          <ChevronForward
                                                            title={"Back"}
                                                            height="14px"
                                                            width="14px"
                                                            color="white"
                                                          />
                                                        </span>
                                                      )
                                                    ) : (
                                                      <span>
                                                        {t(
                                                          "continue",
                                                          "Continue"
                                                        )}{" "}
                                                        <ChevronForward
                                                          title={"Back"}
                                                          height="14px"
                                                          width="14px"
                                                          color="white"
                                                        />
                                                      </span>
                                                    )}
                                                  </button>
                                                </div>
                                              </li>
                                            </ul>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  </div>

                                  <div className="or-block full-width text-center my-2 text-gray-2">
                                    {t("or", "OR")}
                                  </div>
                                  <div className="login-other-methods-block full-width text-center">
                                    <div className="full-width">
                                      <button
                                        className="btn toggle-other-login-methods-btn btn-lg mb-15 facebook"
                                        type="button"
                                        onClick={() => facebookLogin()}
                                      >
                                        <span className="toggle-login-method-icon">
                                          <LogoFacebook
                                            color={"#6c757d"}
                                            title={"Login with Facebook"}
                                            height="20px"
                                            width="20px"
                                            cssClasses="social-login-icon"
                                          />
                                        </span>
                                        <span className="toggle-login-method-title">
                                          {t(
                                            "loginFacebook",
                                            "Login with Facebook"
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                    <div className="full-width">
                                      <button
                                        className="btn toggle-other-login-methods-btn btn-lg mb-15 google"
                                        type="button"
                                        onClick={() => googleLogin()}
                                      >
                                        <span className="toggle-login-method-icon">
                                          <LogoGoogle
                                            color={"#6c757d"}
                                            title={"Login with Google"}
                                            height="20px"
                                            width="20px"
                                            cssClasses="social-login-icon"
                                          />
                                        </span>
                                        <span className="toggle-login-method-title">
                                          {t(
                                            "loginGoogle",
                                            "Login with Google"
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                    <div className="full-width">
                                      <button
                                        className="btn toggle-other-login-methods-btn btn-lg mb-15 email"
                                        type="button"
                                        onClick={() => goToEmailLogin()}
                                      >
                                        <span className="toggle-login-method-icon">
                                          <MailUnreadOutline
                                            color={"#6c757d"}
                                            title={"Login with Google"}
                                            height="20px"
                                            width="20px"
                                            cssClasses="social-login-icon"
                                          />
                                        </span>
                                        <span className="toggle-login-method-title">
                                          {t("loginEmail", "Login with Email")}
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </motion.div>
                              ) : null}
                              {/* Phone Number Form Ends */}
                              {/* OTP Validation Starts */}
                              {otp ? (
                                <motion.div
                                  className="otp-validation-form full-width"
                                  initial={{ opacity: 0, scale: 0.9 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                >
                                  <div className="login-top-action-block text-left">
                                    <button
                                      className="btn btn-link login-back-btn"
                                      type="button"
                                      onClick={() => goToPhoneLogin()}
                                    >
                                      <ArrowBackOutline
                                        color={"#2f89fc"}
                                        title={"Back"}
                                        height="18px"
                                        width="18px"
                                      />
                                      &nbsp;&nbsp;
                                      {t("back", "Back")}
                                    </button>
                                  </div>

                                  <div className="full-width login-form-block d-flex flex-wrap justify-content-center px-0">
                                    <Formik
                                      enableReinitialize
                                      initialValues={{ otp: "" }}
                                      validationSchema={otpValidation}
                                      onSubmit={verifyOTP}
                                    >
                                      {(formik) => {
                                        const { isValid, dirty, isSubmitting } =
                                          formik;
                                        return (
                                          <Form className="login-form mt-2">
                                            <div className="full-width otp-title mb-2">
                                              {t(
                                                "enter6DigitCode",
                                                "Enter the 6-digit code sent to"
                                              )}
                                              <br />
                                              {loginPhoneNumber
                                                ? loginPhoneNumber
                                                : null}
                                            </div>
                                            <ul className="form-list list-unstyled full-width mb-0">
                                              <li>
                                                <div className="form-item full-width d-flex flex-wrap">
                                                  <div className="otp-block full-width">
                                                    <Field
                                                      name="otp"
                                                      id="otp"
                                                      type="password"
                                                      className="form-control login-input otp-input"
                                                      placeholder="OTP..."
                                                    />
                                                  </div>
                                                  <ErrorMessage
                                                    name="otp"
                                                    component="div"
                                                    className="form-error full-width"
                                                  />
                                                  {otpError ? (
                                                    <div className="full-width text-danger otpError">
                                                      {otpError}
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </li>

                                              <li>
                                                <div className="form-item full-width mt-2">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary submit-phone-btn full-width"
                                                    disabled={
                                                      isSubmitting
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {isSubmitting ? (
                                                      <span>
                                                        {t(
                                                          "pleaseWait",
                                                          "Please wait..."
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {t(
                                                          "continue",
                                                          "Continue"
                                                        )}{" "}
                                                        <ChevronForward
                                                          title={"Back"}
                                                          height="14px"
                                                          width="14px"
                                                          color="white"
                                                        />
                                                      </span>
                                                    )}
                                                  </button>
                                                </div>
                                              </li>
                                            </ul>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                </motion.div>
                              ) : null}
                              {/* OTP Validation Ends */}
                              {/* Login  Starts */}
                              {loginEmail ? (
                                <motion.div
                                  initial={{ opacity: 0, scale: 0.97 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{ delay: 0.2 }}
                                  className="otp-validation-form full-width"
                                >
                                  <div className="full-width login-form-block d-flex flex-wrap justify-content-center p-0">
                                    <Formik
                                      enableReinitialize
                                      initialValues={{ loginEmail: "" }}
                                      validationSchema={loginValidations}
                                      onSubmit={(values) => {
                                        console.log(values);
                                        loginWithEmail(values);
                                      }}
                                    >
                                      {(formik) => {
                                        const { isValid, dirty, isSubmitting } =
                                          formik;
                                        return (
                                          <Form className="login-form">
                                            {!subscribeUrl ? (
                                              <div className="full-width login-intro-text">
                                                {t(
                                                  "newToWebsite",
                                                  "New to our Website or Existing user"
                                                )}
                                              </div>
                                            ) : null}
                                            <div className="full-width loginHeading">
                                              {t(
                                                "continueEmail",
                                                "Continue with Email!"
                                              )}
                                            </div>
                                            <ul className="form-list list-unstyled full-width mt-2 mb-0">
                                              {loginError ? (
                                                <li>
                                                  <div className="full-width alert alert-danger">
                                                    {loginError}
                                                  </div>
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              <li>
                                                <div className="form-item full-width d-flex flex-wrap">
                                                  <div className="full-width border-bottom border-gray">
                                                    <Field
                                                      name="loginEmail"
                                                      id="loginEmail"
                                                      type="email"
                                                      className="form-control login-input"
                                                      placeholder="Enter Emaild id"
                                                    />
                                                  </div>
                                                  <div className="full-width">
                                                    <ErrorMessage
                                                      name="loginEmail"
                                                      component="div"
                                                      className="form-error"
                                                    />
                                                  </div>
                                                </div>
                                              </li>

                                              <li>
                                                <div className="form-item full-width mt-2">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary submit-phone-btn full-width"
                                                    disabled={
                                                      isSubmitting
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {isSubmitting ? (
                                                      <span>
                                                        {t(
                                                          "pleaseWait",
                                                          "Please wait..."
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {t(
                                                          "continue",
                                                          "Continue"
                                                        )}{" "}
                                                        <ChevronForward
                                                          title={"Back"}
                                                          height="14px"
                                                          width="14px"
                                                          color="white"
                                                        />
                                                      </span>
                                                    )}
                                                  </button>
                                                </div>
                                              </li>
                                            </ul>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                  <div className="or-block full-width text-center my-2 text-gray-2">
                                    {t("or", "OR")}
                                  </div>
                                  <div className="social-login-block full-width text-center">
                                    <div className="login-other-methods-block full-width text-center">
                                      <div className="full-width">
                                        <button
                                          className="btn toggle-other-login-methods-btn btn-lg mb-15 facebook"
                                          type="button"
                                          onClick={() => facebookLogin()}
                                        >
                                          <span className="toggle-login-method-icon">
                                            <LogoFacebook
                                              color={"#6c757d"}
                                              title={"Login with Facebook"}
                                              height="20px"
                                              width="20px"
                                              cssClasses="social-login-icon"
                                            />
                                          </span>
                                          <span className="toggle-login-method-title">
                                            {t(
                                              "loginFacebook",
                                              "Login with Facebook"
                                            )}
                                          </span>
                                        </button>
                                      </div>
                                      <div className="full-width">
                                        <button
                                          className="btn toggle-other-login-methods-btn btn-lg mb-15 google"
                                          type="button"
                                          onClick={() => googleLogin()}
                                        >
                                          <span className="toggle-login-method-icon">
                                            <LogoGoogle
                                              color={"#6c757d"}
                                              title={"Login with Google"}
                                              height="20px"
                                              width="20px"
                                              cssClasses="social-login-icon"
                                            />
                                          </span>
                                          <span className="toggle-login-method-title">
                                            {t(
                                              "loginGoogle",
                                              "Login with Google"
                                            )}
                                          </span>
                                        </button>
                                      </div>
                                      <div className="full-width">
                                        <button
                                          className="btn toggle-other-login-methods-btn btn-lg email"
                                          type="button"
                                          onClick={() => goToPhoneLogin()}
                                        >
                                          <span className="toggle-login-method-icon">
                                            <CallOutline
                                              color={"#6c757d"}
                                              title={"Login with Google"}
                                              height="20px"
                                              width="20px"
                                              cssClasses="social-login-icon"
                                            />
                                          </span>
                                          <span className="toggle-login-method-title">
                                            {t(
                                              "loginPhone",
                                              "Login with Phone"
                                            )}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              ) : null}
                              {/* Login  Ends */}
                            </div>
                          ) : (
                            <motion.div
                              initial={{ x: "100px", y: "100px", opacity: 0 }}
                              animate={{ x: 0, y: 0, opacity: 1 }}
                              className="otp-validation-form mt-4 full-width thanks-message bg-success rounded shadow"
                            >
                              <div className="thanks-title w-100 d-flex align-items-center">
                                <MailUnreadOutline
                                  title={"Success"}
                                  height="32px"
                                  width="32px"
                                  color="white"
                                  cssClasses="mr-1"
                                />
                                {t(
                                  "autoLoginLinkSend",
                                  "AutoLogin Link sent to your email."
                                )}
                              </div>
                              <div className="thanks-info mt-1 w-100">
                                {t(
                                  "weHaveSentEmail",
                                  "We have sent you and email with Link for AutoLogin. Kindly check mail, and click on Login button or copy paste link in your browser."
                                )}
                              </div>
                              <div className="w-100 pt-1">
                                <small>
                                  <em>
                                    {t(
                                      "inNext5Seconds",
                                      "In next 5 seconds, this popup will be closed."
                                    )}
                                  </em>
                                </small>
                              </div>
                            </motion.div>
                          )}

                          {/* Login Flow Ends */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {
                  // If in subscribe page and popup opened
                  subscribeUrl ? (
                    <div
                      className={
                        state.selectedPlan && state.token
                          ? "full-width login-billing-block"
                          : "full-width login-billing-block no-plan-login"
                      }
                      id="billingHeading"
                    >
                      <div className="login-form-header full-width confirm-pay">
                        <div className="login-title">
                          <span className="number">3</span>{" "}
                          {t("comfirmPay", "Confirm & Pay")}
                        </div>
                      </div>
                      <div className="pop-billing-info-block full-width">
                        {state.selectedPlan && state.token !== null ? (
                          <ul className="pop-billing-list list-unstyled full-width">
                            <motion.li
                              initial={{ x: 50, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ delay: 0.1 }}
                            >
                              <div className="pop-billing-item full-width">
                                <div className="pop-billing-item-title">
                                  {state.selectedPlanName}
                                </div>
                                <div className="pop-billing-item-value">
                                  <span className="rupee">
                                    <Rupee />
                                  </span>
                                  {state.selectedPlanPrice}
                                </div>
                              </div>
                            </motion.li>
                            {state.promoCode ? (
                              <>
                                <motion.li
                                  initial={{ x: 50, opacity: 0 }}
                                  animate={{ x: 0, opacity: 1 }}
                                  transition={{ delay: 0.2 }}
                                >
                                  <div className="pop-billing-item full-width">
                                    <div className="pop-billing-item-title">
                                      {t("discount", "Discount")}
                                    </div>
                                    <div className="pop-billing-item-value">
                                      -
                                      <span className="rupee">
                                        <Rupee />
                                      </span>
                                      {discAmount}
                                    </div>
                                  </div>
                                </motion.li>
                                <motion.li
                                  initial={{ x: 50, opacity: 0 }}
                                  animate={{ x: 0, opacity: 1 }}
                                  transition={{ delay: 0.3 }}
                                >
                                  <div className="pop-billing-item full-width grandTotalBlock">
                                    <div className="pop-billing-item-title">
                                      {t("total", "Total")}
                                    </div>
                                    <div className="pop-billing-item-value">
                                      <span className="rupee">
                                        <Rupee />
                                      </span>
                                      {state.selectedPlanPrice - discAmount}
                                    </div>
                                  </div>
                                </motion.li>
                              </>
                            ) : null}
                            <motion.li
                              initial={{ x: 50, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ delay: 0.4 }}
                            >
                              <button
                                className="btn btn-primary confirm-pay-btn"
                                type="button"
                                onClick={() => payNow()}
                              >
                                {t("comfirmPay", "Confirm & Pay")}
                              </button>
                            </motion.li>
                          </ul>
                        ) : (
                          <div className="pop-billing-unselected full-width">
                            {t(
                              "pleaseSelectPlan",
                              "Please Select Plan &amp; Login for Subscription Payment"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null
                }

                {
                  //If PPV is selected
                  state.ppv ? (
                    <div
                      className={
                        state.selectedPlan && state.token
                          ? "full-width login-billing-block"
                          : "full-width login-billing-block no-plan-login"
                      }
                      id="billingHeading"
                    >
                      <div className="login-form-header full-width confirm-pay">
                        <div className="login-title">
                          <span className="number">3</span>{" "}
                          {t("comfirmPay", "Confirm & Pay")}
                        </div>
                      </div>
                      <div className="pop-billing-info-block full-width">
                        {state.ppv && state.ppv.ppvPrice ? (
                          <ul className="pop-billing-list list-unstyled full-width">
                            <motion.li
                              initial={{ x: 50, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ delay: 0.1 }}
                            >
                              <div className="pop-billing-item full-width">
                                <div className="pop-billing-item-title">
                                  {state.ppv.ppvDuration}{" "}
                                  {t("watchWithinDaysEnglish", "days")}{" "}
                                  {t("watchWithinTelugu", "")}{" "}
                                </div>
                                <div className="pop-billing-item-value">
                                  <span className="rupee">
                                    <Rupee />
                                  </span>
                                  {state.ppv.ppvPrice}
                                </div>
                              </div>
                            </motion.li>
                            <motion.li
                              initial={{ x: 50, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ delay: 0.4 }}
                            >
                              <button
                                className="btn btn-primary confirm-pay-btn"
                                type="button"
                                onClick={() => payPPVNow()}
                              >
                                {t("comfirmPay", "Confirm & Pay")}
                              </button>
                            </motion.li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </motion.div>
        </motion.div>
      ) : null}
    </div>
  );
};

export default Login;
