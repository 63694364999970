export const LOGIN_POPUP = "LOGIN_POPUP";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const LOGIN_WITH_PHONE = "LOGIN_WITH_PHONE";
export const CREATE_USER = "CREATE_USER";
export const THEME = "THEME";
export const PLANS = "PLANS";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const REMOVE_SELECTED_PLAN = "REMOVE_SELECTED_PLAN";
export const PROMO_CODE = "PROMO_CODE";
export const PPV = "PPV";
export const REMOVE_PPV = "REMOVE_PPV";
export const LOGIN_REDIRECT_URL = "LOGIN_REDIRECT_URL";
export const LANGUAGE = "LANGUAGE";
export const PROFILE = "PROFILE";
export const HEADER = "HEADER";
