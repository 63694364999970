export const Rupee = () => {
  return (
    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
      />
    </svg>
  );
};


export const RupeeWhite = () => {
  return (
    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path
        fill="#ffffff"
        d="M8,3H18L17,5H13.74C14.22,5.58 14.58,6.26 14.79,7H18L17,9H15C14.75,11.57 12.74,13.63 10.2,13.96V14H9.5L15.5,21H13L7,14V12H9.5V12C11.26,12 12.72,10.7 12.96,9H7L8,7H12.66C12.1,5.82 10.9,5 9.5,5H7L8,3Z"
      />
    </svg>
  );
};


export const AnimationContainer = {
  hidden: { opacity: 0, y: 10 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.7,
    },
  },
};

export const AnimationItem = {
  hidden: { opacity: 0, y: 15, scale: 0.85 },
  show: { opacity: 1, y: 0, scale: 1 },
};

export const logFirebaseEvent = (evt, data) => {
    if (
      window.wf !== undefined &&
      "logEvent" in window.wf
    ) {
      window.wf.logEvent(evt, data);
    }
  };