import React, {useEffect} from 'react'


const PrivacyPolicy = () => {




  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

   return (
     <div className="full-width">
       <div className="container">
         <div className="row">
           <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
             <div className="full-width text-white bg-secondary p-2 p-lg-3 my-2">
               <h1 style={{ marginBottom: "30px" }}>Privacy Policy</h1>
               <h4 className="heading-primary full-width">1. INTRODUCTION</h4>
               <p>
                 This Privacy Policy explains our practices with regard to the
                 collection, handling, storing, disclosing and otherwise using
                 certain information that we collect from you, including
                 personal information.
               </p>
               <p>
                 This information is obtained by ______________, its group
                 companies and affiliates (“_______”) in relation to our
                 services offered on our Mobile App “_____” and the website:
                 ___________________.
               </p>
               <p>
                 This Privacy Policy is concerned with information relating to
                 _______ services, and therefore, should be read along with the
                 “Terms of Use”. The Terms of Use is the contract that allows
                 you to access the services and content of your choice from us.
               </p>
               <p>
                 By accessing and/or using the services that we offer, you
                 accept the terms of this Privacy Policy. If you do not agree
                 with any or all of the contents of this Privacy Policy, please
                 do not access and/or use the services. To clarify, users who
                 subscribe to ________ services must attain the age of majority
                 in their respective jurisdiction. Individuals who are under the
                 relevant age of majority may utilize the services only under
                 the supervision and approval of their parents and/or legal
                 guardian.
               </p>
               <p>
                 We request you to review the Privacy Policy from time-to-time.
                 If you continue to use the Services after any Privacy Policy
                 amendments, we will reasonably infer this as your acceptance of
                 such revised Privacy Policy. Further, ______ retains the right
                 at any time to deny or suspend access to all, or any part of,
                 the service to anyone who ______ reasonably believes has
                 violated any provision of this Privacy Policy.
               </p>
               <h4 className="heading-primary full-width">
                 2. HOW TO CONTACT US
               </h4>
               <p>
                 We have a customer support team that will address your
                 grievances through email, chat and voice call. For queries to
                 be addressed through email, users can reach out to
                 support@______. The chat and voice call details are as follows:
                 +91-___________.
               </p>
               <p>
                 For data related issues, users can contact our IT Head/Security
                 officer at: support@______.
               </p>
               <p>
                 Users can also contact our respective Grievance Officer for any
                 kind of grievance related to the content, features, offers,
                 subscription and/or payment related queries. Our Grievance
                 Officer can be reached at: +91-_______________.
               </p>
               <h4 className="heading-primary full-width">
                 3. INFORMATION COLLECTED FROM YOU
               </h4>
               <p>We collect the following information about You, such as: -</p>
               <p>
                 <strong>A) Information You give us</strong>
               </p>
               <p>
                 Your Contact Information: Name, date of birth, email id, phone
                 number, Contact Us
               </p>
               <p>
                 Your Social Media Information: Logins of your social media
                 platforms, such as Google, Facebook, Twitter, etc.
               </p>
               <p>
                 <strong>B) Information we collect automatically</strong>
               </p>
               <p>
                 Your Device Information: Log information, hardware model,
                 operating system version, unique device identifiers, and mobile
                 network information including geographical location information
                 and usage information.
               </p>
               <p>
                 Your Subscription Details: Plan of Subscription, Date of
                 Subscription, End date of Subscription and payment method you
                 opt for.
               </p>
               <p>
                 Your Browsing Information: Watch list, watch history, cookies,
                 likes and dislikes, location of streaming, duration of
                 streaming, user events, user sessions, type of content you
                 watch, number of clicks on our service platform, saved content,
                 downloaded content, etc.
               </p>
               <p>
                 Your API Information: The open API data collected by our
                 analytics team.
               </p>
               <p>Your Demographic Information</p>
               <p>
                 <strong>
                   C) Information we collect from affiliates, partners and
                   service providers
                 </strong>
               </p>
               <p>
                 Your Financial Information: For the purpose of payment for the
                 subscription plan, ___, either by itself, or through its
                 third-party payment gateway, may collect the details of your
                 card like debit and credit, any details of user registration
                 with any other payment options like Pay-U, G-pay, Paytm, etc.
               </p>
               <p>
                 Third party marketing agencies and consultancies: Information
                 regarding potential subscribers, type of preferred content,
                 etc.
               </p>
               <p>
                 <strong>D) Information we collect from third parties</strong>
               </p>
               <p>Third party open data</p>
               <h4 className="heading-primary full-width">
                 4. PURPOSE / USE OF INFORMATION
               </h4>
               <p>
                 The collected information will be used for the following
                 purposes only:
               </p>
               <p>
                 A) To inform, engage and entertain you via ______’s services
                 and the content only.
               </p>
               <p>
                 B) To be used strictly for the business purpose of ______, with
                 no other information being shared with any third party (except
                 as explicitly stated in this Privacy Policy). For instance,
                 collected information will be used to analyze the behavior or
                 historical patterns of the usage to gauge the user’s interests
                 in various content available on the platform.
               </p>
               <p>
                 C) Keeping the user informed about _______ services and other
                 relevant products and advertisements, and the modes of
                 communicating with the user may extend to email campaigns,
                 social media targeting or engagement platforms, in-app or push
                 app notifications, electronic messages, etc.
               </p>
               <p>
                 D) To ensure your compliance with our Terms of Use, as well as
                 all applicable laws and regulations.
               </p>
               <p>
                 E) To verify your accounts and activity, to protect you from
                 any suspicious or fraudulent activity by necessary monitoring,
                 and to identify violations of _____ policies and the law.
               </p>
               <p>
                 F) To provide you a better quality of service and analyze your
                 interests to give you preferable and recommendation-based
                 services as per your likings.
               </p>
               <p>
                 G) To notify you about our updated offers and services via
                 in-app notifications, push notifications and emails and
                 newsletter.
               </p>
               <p>
                 H) To analyze user preferences and send notification of any
                 products or advertisements of third party which can be of your
                 interest and as per your preference(s).
               </p>
               <p>
                 I) To serve relevant products and advertisements based on user
                 consent and preference(s).
               </p>
               <h4 className="heading-primary full-width">
                 5. DISCLOSURE OF INFORMATION
               </h4>
               <p>
                 A) We do not share the collected information with any third
                 party for any purposes other than those provided above. At the
                 same time, we may disclose this information to the following
                 persons (that includes both ______ internal members as well as
                 third party service providers) to achieve the above-stated
                 purposes:
               </p>
               <p>
                 Users data will be used by the marketing team such as emails,
                 in-app.
               </p>
               <p>
                 User ID and personal data of users will be used by the customer
                 support team to address any issues raised by the users or
                 preventive mechanism.
               </p>
               <p>Transaction data will be used by the finance team.</p>
               <p>
                 The analytics team will access the user’s data for processing
                 click, session, events etc.
               </p>
               <p>
                 The IT Team will have the users IP address and location data.
               </p>
               <p>
                 The third-party “Software-as-a-Service” provider(s) of _______
                 will access the relevant and necessary information of the users
                 in relation to the services and the content
               </p>
               <p>
                 Third-party payment gateways and other payment service
                 providers will be able to access the necessary financial
                 details of the users.
               </p>
               <p>
                 B) For legal reasons: We will share your information with third
                 parties if we in good faith believe that such sharing is
                 necessary to comply with any legal obligation of _____,
                 including meeting any applicable laws, regulation,
                 investigation of potential violations; detecting, preventing,
                 or otherwise addressing fraud, security or technical issues;
                 protecting against any harm to the rights, property or safety
                 of _____, our users or the public as required or permitted by
                 law (including compliance with our Terms of Use).
               </p>
               <p>
                 C) Business transfers: In connection with any reorganization,
                 restructuring, merger or sale, or other transfer of assets, we
                 will transfer information, including personal information,
                 provided that the receiving party agrees to respect your
                 personal information in a manner that is consistent with our
                 Privacy Policy.
               </p>
               <p>
                 D) With your consent: We will share any of your personal
                 information for any purpose not stated above, only after you
                 have given us your consent to do so with any third party.
               </p>
               <h4 className="heading-primary full-width">
                 6. INFORMATION STORAGE
               </h4>
               <p>
                 We and the third parties obtaining your information retain your
                 information in a manner that identifies you only for the time
                 period necessary for fulfilling the purposes mentioned under
                 this Policy.
               </p>
               <h4 className="heading-primary full-width">
                 7. WHAT RIGHTS YOU HAVE
               </h4>
               <p>
                 A) You can always choose not to provide the requested
                 information to us, even though it may result you in not
                 availing certain features if, or the entire, _______ services.
               </p>
               <p>
                 B) You shall the right to access your personal information that
                 we hold and raise a request with us for rectifying, modifying
                 or deleting your personal information by raising a request to
                 our customer support/grievance officer, with the necessary
                 contact details provided above. Our customer support/grievance
                 officer shall process your request and revert to You within 48
                 working hours from the date of such request.
               </p>
               <p>
                 C) If you think that any of your personal information that we
                 hold is inappropriate/incorrect/inaccurate, and you want us to
                 rectify the same, you can approach us at support@_____________,
                 or reach us out at +91-_____________.
               </p>
               <p>
                 D) If you want to access and/or edit any of your personal
                 information, you can click on My Account
               </p>
               <p>
                 E) If you want any of your personal information to be deleted,
                 you can approach us at support@______________, or reach us at
                 +91-_____________or My account
               </p>
               <p>
                 F) If you want to withdraw your consent or raise any objection
                 to the use of your information for receiving any direct
                 marketing information to which you previously opted-in, you can
                 do so by unsubscribing to such direct marketing by contacting
                 our customer support at above mentioned addresses. If you
                 withdraw/object your consent, our use of your information
                 before you withdrew/objected shall still be lawful.
               </p>
               <h4 className="heading-primary full-width">
                 8. SECURITY OF YOUR INFORMATION
               </h4>
               <p>
                 A) We, as well as third parties obtaining your information, are
                 committed to ensuring that your personal information is
                 protected and secure with us. In order to prevent unauthorized
                 access or divulgence of your personal information, we, as well
                 as third parties obtaining your information, have put in place
                 suitable technical and organizational measures (such as
                 physical, electronic and managerial procedures) to safeguard
                 and secure the personal information we collect from You.
               </p>
               <p>
                 B) We have appointed “Data Protection Officers” who will
                 protect your information and help withstand it from breach.
               </p>
               <p>
                 C) We perform periodical “Data Protection Impact Assessment” to
                 identify risks to user information and “Data Protection
                 Compliance Reviews” to ensure those risks are addressed.
               </p>
               <p>
                 D) All the Information we collect from you is in encrypted form
                 by Secure Server Software (SSL) before it is sent to _____, and
                 from _______ to other third parties.
               </p>
               <p>
                 E) Identity theft and the practice currently known as
                 “phishing” are of great concern to us. Safeguarding information
                 to help protect you from identity theft is a top priority. We
                 do not and will not at any time request your details of credit
                 card, or debit card, PIN in a non-secure, or unsolicited
                 e-mail, or telephone communication.
               </p>
               <p>
                 F) We use support services of third-party platforms and/or
                 companies to direct you to payment gateways when you opt to pay
                 for our Services. Your financial information is collected,
                 stored and retained by such third-party platforms. We and such
                 third-party platforms undertake measures designed to provide a
                 security level that is appropriate to the risks of processing
                 your personal information. However, you are requested to check
                 and consent to the “Privacy Policy” of such third-party
                 platforms in order to accept how such third-party platforms
                 handle your information.
               </p>
               <h4 className="heading-primary full-width">
                 9. Cookies and Internet Advertising
               </h4>
               <p>
                 We and our service providers use cookies and other technologies
                 (such as web beacons), as well as resettable device
                 identifiers, for various reasons. We want you to be informed
                 about our use of these technologies, so this section explains
                 the types of technologies we use, what they do, and your
                 choices regarding their use.
               </p>
               <p>
                 Cookies and similar technologies, web beacons, and resettable
                 device identifiers
               </p>
               <p>
                 Cookies are small data files that are commonly stored on your
                 device when you browse and use websites and online services. We
                 use other technologies such as browser storage and plugins
                 (e.g., HTML5, IndexedDB, and WebSQL). Like cookies, these other
                 technologies may store small amounts of data on your device.
                 Web beacons (also known as clear gifs or pixel tags) often work
                 in conjunction with cookies. In many cases, declining cookies
                 will impair the effectiveness of web beacons associated with
                 those cookies.
               </p>
               <p>
                 If you use ______ app on a mobile device, tablet, or streaming
                 media device, we may collect a resettable device identifier
                 from your device. Resettable device identifiers (also known as
                 advertising identifiers) are similar to cookies and are found
                 on many mobile devices and tablets (for example, the
                 "Identifier for Advertisers" (or IDFA) on Apple iOS devices and
                 the "Google Advertising ID" on Android devices), and certain
                 streaming media devices. Like cookies, resettable device
                 identifiers are used to make online advertising more relevant
                 and for analytics and optimization purposes.{" "}
               </p>
               <p>Why does _______ use these technologies?</p>
               <p>
                 We use these types of technologies for various reasons, like
                 making it easy to access our services by remembering you when
                 you return; to provide, analyze, understand and enhance the use
                 of our services; to enforce our terms, prevent fraud; to
                 improve site performance, monitor visitor traffic and actions
                 on our site; and to deliver and tailor our marketing or
                 advertising, and to understand interactions with our emails,
                 marketing, and online ads on third party sites.{" "}
               </p>
               <p>
                 To help you better understand how we use cookies and resettable
                 device identifiers, please see the information below:
               </p>
               <p>
                 Essential cookies: These cookies are strictly necessary to
                 provide our website or online service. For example, we and our
                 service providers may use these cookies to authenticate and
                 identify our members when they use our websites and
                 applications so we can provide our service to them. They also
                 help us to enforce our Terms of Use, prevent fraud and maintain
                 the security of our service.{" "}
               </p>
               <p>
                 Performance and functionality cookies: These cookies help us to
                 customize and enhance your online experience with ________. For
                 example, they help us to remember your preferences and prevent
                 you from needing to re-enter information you previously
                 provided (for example, during member sign up). We also use
                 these cookies to collect information (such as popular pages,
                 conversion rates, viewing patterns, click-through and other
                 information) about our visitors' use of the ____________service
                 so that we can enhance and personalize our website and service
                 and conduct market research. Deletion of these types of cookies
                 may result in limited functionality of our service.
               </p>
               <p>
                 Advertising cookies and resettable device identifiers: These
                 cookies and resettable device identifiers use information about
                 your use of this and other websites and apps, your response to
                 ads and emails, and to deliver ads that are more relevant to
                 you and for analytics and optimization purposes. These types of
                 ads are called "interest-based advertising." The advertising
                 cookies associated with our service belong to our service
                 providers.{" "}
               </p>
               <p>
                 In connection with our use of these technologies, some of the
                 websites and apps where we advertise, as well as advertising
                 technology companies that we use to purchase, deliver,
                 optimize, and/or measure our advertisements (collectively
                 “Advertising Partners”), may receive limited information from
                 us as part of our campaign targeting, measurement, and
                 optimization (e.g., steps completed in sign-up and site visit
                 or app open/install information).
               </p>
               <p>
                 Common uses of this type of information are to judge the
                 effectiveness of and optimize ad campaigns, by allowing
                 Advertising Partners to see when someone who saw an ad later
                 signed up for our service. Another common use is to make sure
                 we do not serve ads trying to get individuals to sign up for
                 the ______ service if they are already a _______ user.{" "}
               </p>
               <p>
                 _____uses contractual and technical measures designed to
                 prevent Advertising Partners from accessing information
                 regarding specific title selections you make, URLs you land on,
                 or shows you have watched on our service. We do not share
                 information about title selections or your shows you have
                 watched on our service.{" "}
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
   );
}

export default PrivacyPolicy;