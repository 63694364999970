import axios from "axios";
import qs from "qs";
import { analytics } from "../../firebase";

// custom imports
import { Context } from "../../../Context";
import { IoMdFastforward } from "react-icons/io";

export const url = "https://tandav-api.ibee.ai";
//export const url = "https://rvr-ott-api.ibee.sh";





//export const url = "http://188.42.97.42:8001";
//export const url = "https://ott-api.ibee.ai";
// export const url = "https://rvr-api.ibee.ai";

export const headers = {
  headers: {
    accept: "application/json",
  },
};

export const formHeaders = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    accept: "application/json",
  },
};

export const authHeaders = (token) => {
  return {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

// Get Subscribers
export const getSubscribePlans = async () => {
  await axios.get(url + "/plans").then((res) => {
    console.log("Plans - ", res);
    return res.data;
  });
  return true;
};

// Get User Data
export const getUserData = async (id, token) => {
  const details = await axios
    .get(url + "/users/" + id, {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("User Details - ", res.data);

      const uData = Object.assign(res.data, { token: token });
      return uData;
    })
    .catch((err) => {
      return err;
    });

  return details;
};

// Get user details
export const getUserDetails = async (token) => {
  const userDetails = await axios
    .post(
      url + "/oauth2/firebase",
      qs.stringify({
        access_token: token,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    )
    .then(async (firebaseRes) => {
      console.log("firebase id = ", firebaseRes.data.id);
      console.log("firebase token = ", firebaseRes.data.access_token);
      return getUserData(firebaseRes.data.id, firebaseRes.data.access_token);
    });

  return userDetails;
};

//Get All Profile details
export const getAllProfiles = async (id, access_token) => {
  const profiles = await axios
    .get(url + "/users/" + id + "/profiles", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + access_token,
      },
    })
    .then((res) => {
      console.log("All Profiles - ", res.data);
      return res.data;
    });

    return profiles;
}




// Old one
/* export const getUserDetails = async (token) => {

  const userDetails = await axios
    .post(
      url + "/oauth2/firebase",
      qs.stringify({
        access_token: token,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    )
    .then( async(firebaseRes) => {
      console.log("firebase id = ", firebaseRes.data.id);
      console.log("firebase token = ", firebaseRes.data.access_token);
       const userData = await axios.get(url + "/users/" + firebaseRes.data.id, {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + firebaseRes.data.access_token,
          "Content-Type": "application/json",
        },
      }).then(res => {
        return [{success: true}, res.data, { token: firebaseRes.data.access_token }];
      }).catch(err => {
        return [
          { success: false },
        ];
      });

      return userData;
    })
    .catch((err) => {
      console.log('oAuth/Firebase error - ', err);
    });

  return userDetails;
}; */

// Add to wishlist
export const toggleWishList = async (
  id,
  token,
  videoId,
  videoType,
  videoImage,
  videoTitle,
  remove = false,
  name
) => {
  console.log("Add wishlist got - ", videoId, videoType, videoImage, remove);

  let wishListBody;

  if (remove) {
    wishListBody = JSON.stringify({
      removeWishToWatch: {
        id: videoId,
        type: videoType,
        image: videoImage,
      },
    });
  } else {
    wishListBody = JSON.stringify({
      wishToWatch: {
        id: videoId,
        type: videoType,
        image: videoImage,
      },
    });
  }

  /*  const userId = localStorage.getItem('id');
  const token = localStorage.getItem('token'); */

  const listData = await axios
    .patch(url + "/users/" + id + '/profiles/'+ name, wishListBody, {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("Add Wishlist - ", res.data);

      if(!remove) {
        analytics.setUserId(id ? id : "");
        analytics.logEvent("video_playback", {
          video_event: "wishlist",
          video_id: videoId,
          video_title: videoTitle,
          video_position: 0,
          video_duration: 0,
          video_progress: 0,
          profile_id: "",
          user_id: id,
          video_type: videoType,
          episode: 0,
          season: 0,
        });

      }

      const userDet = getUserData(id, token);
      return userDet;
    })
    .catch((err) => {
      console.log("Add Wishlist error - ", err);
      return err.message;
    });

  return listData;
};

export const toggleLikeDislike = async (
  id,
  token,
  videoId,
  videoType,
  videoImage,
  videoTitle,
  remove = false,
  name
) => {
  console.log("Add wishlist got - ", videoId, videoType, videoImage, remove);

  let wishListBody;
  let eventType;

  if (remove) {
    wishListBody = JSON.stringify({
      dislikes: {
        id: videoId,
        type: videoType,
        image: videoImage,
      },
    });
    eventType = "dislike";
  } else {
    wishListBody = JSON.stringify({
      likes: {
        id: videoId,
        type: videoType,
        image: videoImage,
      },
    });
    eventType = "like";
  }

  /*  const userId = localStorage.getItem('id');
  const token = localStorage.getItem('token'); */

  const listData = await axios
    .patch(url + "/users/" + id + '/profiles/' + name, wishListBody, {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("Like Item - ", res.data);
      const userDet = getUserData(id, token);

      analytics.setUserId(id ? id : "");
      analytics.logEvent("video_playback", {
        video_event: eventType,
        video_id: videoId,
        video_title: videoTitle,
        video_position: 0,
        video_duration: 0,
        video_progress: 0,
        profile_id: "",
        user_id: id,
        video_type: videoType,
        episode: 0,
        season: 0,
      });


      return userDet;
    })
    .catch((err) => {
      console.log("Like, Dislike error -", err);

      return err.message;
    });

  return listData;
};


// Play Button
export const playVideo = async ({
  id,
  type,
  model,
  obj,
  authenticated,
  activeSubscription,
  prevUrl,
  season,
  episode,
  playTime,
  profileName,
  ticketPurchased,
  watchHistory
}) => {
  const pushArguments = {
    //pathname: "/watch/",
    model: type === "trailer" ? "free" : model,
    type: type,
    id: id,
    obj: obj,
    season: season === null || season === undefined ? undefined : season,
    episode: episode === null || episode === undefined ? undefined : episode,
    playTime: playTime,
    profileName: profileName,
    watchHistory: watchHistory ? watchHistory : [],
  };

  //Check if Shaka Player Supports
  //let playerSupport = "yes";
  const playerSupport =
    await window.shaka === null || window.shaka === undefined
      ? true
      : window.shaka.Player.isBrowserSupported()
      ? true
      : false;

  if (playerSupport) {
    switch (model) {
      case "free":
        if (authenticated === true) {
          console.log("This case is FREE Video");
          sessionStorage.setItem("rvrplay", JSON.stringify(pushArguments));
          return "success";
          //return pushArguments;
          //break;
        } else {
          sessionStorage.setItem(
            "prevUrl",
            prevUrl ? JSON.stringify(prevUrl) : null
          );
          return "login";
          //break;
        }

      case "subscription":
        console.log("Came to subscription case");
        if (authenticated && activeSubscription) {
          console.log("This case is SUBSCRIPTION Video & user SUBSCRIBED");
          sessionStorage.setItem("rvrplay", JSON.stringify(pushArguments));
          return "success";
          //return pushArguments;
          //break;
        } else {
          console.log("User Not subscribed so go to plans,url");
          sessionStorage.setItem(
            "prevUrl",
            prevUrl ? JSON.stringify(prevUrl) : null
          );
          sessionStorage.setItem("subrequired", true);
          return "plans";
          //break;
        }

      /*  case "ppv":
        if (authenticated && ticketPurchased === true) {
          // console.log("This case is PPV Video & user SUBSCRIBED");
          return "success";
          //break;
        } else {
          sessionStorage.setItem("prevUrl", prevUrl ? prevUrl : null);
          return plans;
          break;
        } */

      default:
        return "plans";
    }
  } else {
    return "playerNotSupported";
  }
};