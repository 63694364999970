import React, {useEffect} from 'react';
import "./terms-conditions.scss";


const TermsConditions = () => {


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


   return (
     <div className="full-width">
       <div className="container">
         <div className="row">
           <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
             <div className="full-width text-white bg-secondary p-2 p-lg-3 my-2">
               <h1 style={{marginBottom: "30px"}}>Terms &amp; Conditions</h1>
               <p>
                 Terms of use (“Terms”) for the services offered on our Mobile
                 App “_____” and the website: ___________________. _____________
                 Private Limited (“___”, “Us”, “We”, “Our” or “Company”, which
                 includes its successors or assigns) is the owner of Mobile App
                 “_____” and the website: ___________________and provides You
                 (as defined below) and its other Authorized Users (as defined
                 below) with a personalized content viewing experience through
                 the Mobile App “_____” and the Website: ___________________ (as
                 defined below).
               </p>
               <p>
                 The Terms provides terms and conditions applicable for any User
                 of Website, App, Content (as defined below) and/or the Services
                 (as defined below). These terms should be read along with _____
                 Privacy Policy, Refund Policy, and all applicable laws, rules,
                 regulations and guidelines governing this document
                 (collectively, this "Agreement").
               </p>
               <p>
                 By installing, downloading, accessing, updating, subscribing,
                 upgrading, or otherwise using the Services and Content, You
                 consent to: (1) fully complying with this Agreement and Privacy
                 Policy available at Privacy Policy, (2) using the Services and
                 Content only as per the manner and purpose provided under this
                 Agreement, and (3) being solely responsible for the transfer of
                 any payments made through Website/App in exchange for accessing
                 the Services requested by You. By interacting with the
                 Website/App or otherwise availing the Services, You confirm
                 that You accept this Agreement and agree to abide by the same.
                 If You do not agree with the provisions of this Agreement in
                 entirety, do not Interact with Website and/or the App. Further,
                 in case of disagreement with the above, You are not entitled to
                 avail, use and access any of the Content and Services, and any
                 use thereafter shall be unauthorized.
               </p>
               <h4 className="heading-primary full-width">1. DEFINITIONS </h4>
               <p>
                 1.1 “Account Information” shall mean Your information,
                 including Personal Information, that We require in order for
                 You to effectively use Our Services (as detailed below).
               </p>
               <p>
                 1.2 “Affiliates” shall mean _______ directors, agents,
                 officers, employees, agents, affiliates, successors, assigns,
                 managers, members, subsidiaries, partners, attorneys and
                 service providers (such as directors, producers, licensors).
               </p>
               <p>
                 1.3 “App” shall mean the _____ mobile application named ____
                 that can be accessed and used by Authorized Users to avail the
                 Services through the Authorized Devices.
               </p>
               <p>
                 1.4 “Authorized Devices” shall refer to the computers,
                 televisions, mobiles, tablets, and other devices that are
                 compatible and permitted by the Agreement for accessing Content
                 and Services via the App.
               </p>
               <p>
                 1.5 “Authorized Users” shall mean the Users who have selected a
                 valid Subscription plan and paid the relevant Subscription Fees
                 to ________ (along with free trials as per Clause 2.9), in
                 accordance with the Terms.
               </p>
               <p>
                 1.6 “Content” shall include, but not be limited to, the
                 available audio, visual and audio-visual content such as
                 movies, web-series, documentaries, music, etc. that are
                 provided to Authorized Users through Website and the App
               </p>
               <p>
                 1.7 “Personal Information” shall have the meaning assigned to
                 this term under the IT (Reasonable Security Practices and
                 Procedures and Sensitive Personal Data or Information) Rules,
                 2011, and includes any analogous meaning(s) provided under the
                 applicable laws of the Users (for e.g., “Personal Data” defined
                 under the European Union’s General Data Protection Regulation
                 2016/679).
               </p>
               <p>
                 1.8 “Profile” shall refer to the account created on Website or
                 on the App that contain Your Account Information.
               </p>
               <p>
                 1.9 “Service(s)” shall mean the access, use and/or viewing of
                 certain Content displayed, recommended or provided by ______ to
                 Authorized Users, along with all features and functionalities;
                 tools; materials; applications; user interface;
                 recommendations; comments; reviews; contests, etc. provided via
                 Website and the App.
               </p>
               <p>
                 1.10 “SPD” or “Sensitive Personal Data” shall have the meaning
                 assigned to this term under the IT (Reasonable Security
                 Practices and Procedures and Sensitive Personal Data or
                 Information) Rules, 2011, and includes any analogous meaning(s)
                 provided under the applicable laws of the Users (for e.g.,
                 “Genetic Data” and “Biometric Data” defined under the European
                 Union’s General Data Protection Regulation 2016/679).
               </p>
               <p>
                 1.11 “User” or “You” or “Your” or “Yours” shall refer to all
                 Authorized Users of the Services, along with other users and
                 any third-party who use _______ Content and the Services in any
                 way whatsoever, and further includes any person who contributes
                 any information, content, private and public messages,
                 advertisements and any other materials or services on, or
                 towards, Website, App, Content and/or the Services.
               </p>
               <p>1.12 “Website” shall mean URL : ______________________</p>
               <h4 className="heading-primary full-width">
                 2. TERMS OF SERVICES{" "}
               </h4>
               <h6 className="full-width heading-secondary">
                 2.1 Introduction to Service Features
               </h6>
               <p>
                 ______is providing the Services to its Authorized Users through
                 Website and the App, that are interactive platforms created for
                 Your engagement, entertainment and personal use.
               </p>
               <p>
                 For a better experience, the Services personalize the content
                 and features availed by You, including continuation of past
                 content viewing, future content recommendations and other
                 services that might interest You. However, Your right to use
                 any Service and to access Content on _____ is conditional upon
                 Your full compliance with the terms and conditions of the
                 Agreement.
               </p>
               <h6 className="full-width heading-secondary">
                 2.2 Creation of Your Profile
               </h6>
               <p>
                 a) In order to use the Services offered by Us on Website/App,
                 You are required to create a Profile and provide Us with the
                 requested Account Information. You shall provide true,
                 accurate, current and complete information when creating Your
                 Profile. Upon creating the Profile, You may use the Services
                 subject to fully agreeing and abiding with the Agreement.
               </p>
               <p>
                 b) You acknowledge and agree Your registration details and any
                 other data collected by ____ is subject to ______ Privacy
                 Policy. In order to render the Services effectively, _____ may
                 collect information such as language, location, contact
                 details, address, time zone where the Services are used, and
                 use these to provide content recommendations, in accordance
                 with this Agreement.
               </p>
               <h6 className="full-width heading-secondary">
                 2.3 Access to Content
               </h6>
               <p>
                 a) Content will be made available to Authorized Users upon
                 their Subscription to the Services (other than free trials as
                 provided in the Terms) through Website and the App, subject to
                 this Agreement.
               </p>
               <p>
                 b) If You are below the age of majority in your jurisdiction,
                 You may use the Service only with the consent, guidance and
                 supervision of a parent or legal guardian. If You are a minor
                 and continue accessing the Service, ______ will assume that You
                 have reviewed this Agreement with Your parent/legal guardian
                 and Your parent/legal guardian understands and agrees to it on
                 Your behalf.
               </p>
               <p>
                 c) If You are below the age of majority in your jurisdiction,
                 You and Your parents/legal guardians confirm that the Service
                 is offered to You for Your enjoyment and these Terms shall
                 constitute a legally binding user agreement between the Company
                 and Your parents/legal guardians who are contracting on behalf
                 of You. To clarify, where Users are below the age of majority,
                 all references to "User", "You" and "Your" shall mean and
                 include You and Your parents/legal guardians acting on Your
                 behalf.
               </p>
               <p>
                 d) Some Content offered on the Service may not be suitable for
                 certain Users, and therefore, user discretion/parental
                 discretion is advised. Also, some Content offered on the
                 Service may not be appropriate for viewership by persons below
                 the age of majority. Parents/legal guardians are advised to
                 exercise discretion before allowing their children and/or wards
                 to access this Service and/or any Content.
               </p>
               <h6 className="full-width heading-secondary">
                 2.4 User’s License
               </h6>
               <p>
                 a) ______ grants You a limited, revocable, non-sublicensable,
                 non-exclusive and non-transferable license to access and use
                 the Content and Services on Website and APP for your personal
                 and non-commercial purposes, and only in accordance with the
                 Agreement.
               </p>
               <p>
                 b) Please note that the availability and Your access to the
                 Content and the Services is subject to _______ sole discretion
                 and may be dependent upon Your geographical location. Further,
                 You acknowledge that the Services are being rendered to You on
                 the condition that not all Content or Services will be
                 available to all Users. The Content and Services may vary based
                 on Your location and time and would be subject to various
                 parameters such as specifications, device, internet
                 availability and speed, bandwidth, etc. Thus, _____ may use
                 technologies and methods to verify Your geographic location in
                 order to effectively render the Services to You. You will not
                 use any technology, technique (including impersonation), or
                 other methods to change, obscure, disguise, or mislead Your
                 location to ______.
               </p>
               <p>
                 c) You hereby agree and acknowledge that You are accessing the
                 Website/App and availing the Services at Your own risk, choice
                 and initiative.
               </p>
               <p>
                 d) The Services are provided to You only on the terms that:
               </p>
               <p>
                 i) You will not copy, distribute, or make any derivative works
                 of the Content and the Website/App in any medium, without
                 ______ prior written consent,
               </p>
               <p>
                 ii) You will not alter or modify any part of the Website/App,
                 other than as may be reasonably necessary to use the
                 Website/App for its intended purposes,
               </p>
               <p>
                 iii) You will use the Services and Content, as well as
                 otherwise act, in accordance with this Agreement and applicable
                 laws, and
               </p>
               <p>
                 iv) Not attempt, facilitate, induce, aid and abet, or encourage
                 others to act in violation of this Agreement.
               </p>
               <h4 className="full-width secondary-heading">
                 2.5 Compatible Systems
               </h4>
               <p>
                 The Services can be availed through certain Authorized Devices
                 which _____________ approves from time to time, for which the
                 current list of “Compatible Systems” is set out below. In some
                 cases, whether an Authorized Device is (or remains) capable of
                 running a Compatible System may depend on the software or
                 platform provided or maintained by the relevant device
                 manufacturer or other third parties. As a result, Authorized
                 Devices that run Compatible System at one time may cease to run
                 Compatible System(s) in the future.
               </p>
               <p>The Website works best with:</p>
               <p>- Latest version of Google Chrome</p>
               <p>- Latest version of Firefox</p>
               <p>- Latest version of Safari</p>
               <p>- Latest version of Microsoft Edge</p>
               <p>- Microsoft Windows 10 or above</p>
               <p>- JavaScript and cookies enabled</p>
               <p>
                 The App works best as per the system requirements specified
                 under the respective App stores and devices details, with a
                 minimum system requirement of:
               </p>
               <p>- Android v.5.0 or above</p>
               <p>- iOS 9 or above</p>
               <h4 className="full-width secondary-heading">
                 2.5 Compatible Systems
               </h4>
               <p>
                 a) You agree that _____________ shall be under no liability
                 whatsoever in the event of any non-availability of the Content
                 and Services on the Website/App, or any portion thereof, that
                 is directly or indirectly caused due to an act of God, war,
                 disease, revolution, riot, civil commotion, internet outage,
                 strike, lockout, flood, fire, satellite failure, failure of any
                 public utility, man-made disaster, or any other cause beyond
                 the reasonable control of _____________.
               </p>
               <p>
                 b) Further, You agree that Your use of the Services and the
                 Content gives You the opportunity to view, publish, transmit,
                 submit posts and/or comment in accordance with these Terms. You
                 agree that You will not misuse the Content and/or User material
                 that You access and view as part of the Service, including
                 without limitation, by downloading/storing the Content
                 illegally or via any other non-permitted means, or infringing
                 any of _____________’s (such as the App, Website),
                 _____________ Affiliates, or licensor’s copyright, trademark,
                 design and other intellectual property or proprietary rights.
               </p>
               <p>
                 c) You agree that the Content provided on the Website/App is
                 strictly for Your private viewing only and not for public
                 exhibition (regardless of any charges or not for such
                 exhibition). You hereby agree to refrain from further
                 broadcasting or making available the Content provided on the
                 Website/App to any third party.
               </p>
               <p>
                 d) In case the Website/App allows You to download or stream any
                 of the Content therein, You agree that You will not use, sell,
                 transfer or transmit the Content to any third party; or upload
                 the Content on any other website, webpage or software, directly
                 or indirectly, such that it violates, or causes any damage or
                 injury, to any rights of _____________, _____________
                 Affiliates, _____________’s Licensor or any third party
                 including privacy rights, publicity rights, and intellectual
                 property rights.
               </p>
               <p>
                 e) You may encounter third party applications while using the
                 Website/App, including, without limitation, websites, widgets,
                 software, services that interact with the Website and App. Your
                 use of such third-party applications shall be subject to such
                 third-party’s terms of use, license terms and privacy policies.
                 _____________ shall not be liable for any representations,
                 warranties or obligations made by such third-party applications
                 to You in any manner whatsoever.
               </p>
               <h4 className="full-width secondary-heading">
                 2.7 User Postings & General Restrictions
               </h4>
               <p>
                 You shall not host, display, upload, modify, publish, transmit,
                 update or share any information that belongs to, or relates to
                 another User, and to which You have no right of access.
                 Further, You shall act in full conformity of the following:
               </p>
               <p>
                 a) You shall not use the Services to upload, post, edit,
                 transmit, link or otherwise deal with any information that is
                 offensive, defamatory, obscene, vulgar, excessively violent,
                 blasphemous, hateful, racially and ethnically inciteful,
                 unlawful, threatens public health or safety, tortious, false or
                 misleading, defamatory, libelous, hateful, or discriminatory,
                 or violates any applicable law in force in any manner
                 whatsoever.
               </p>
               <p>b) You shall not use the Services to:</p>
               <p>i) harass or harm another person,</p>
               <p>ii) exploit or endanger a minor.</p>
               <p>
                 iii) impersonate or attempt to impersonate any person or
                 entity;
               </p>
               <p>
                 iv) Violate any patent, trademark, trade secret, copyright,
                 privacy, publicity or any other intellectual, industrial and/or
                 proprietary rights of _____________, _____________ Affiliates,
                 _____________’s licensors or any other third party.
               </p>
               <h4 className="full-width secondary-heading">
                 2.8 General Usage Policies
               </h4>
               <p>
                 a) You shall not collect or use any information provided on the
                 Website and App, and belonging to another User (Including
                 Personal Information and Sensitive Personal Information), to
                 impersonate or misrepresent such User, or to deceive or menace
                 a third party, or for any commercial or solicitation purposes,
                 or for facilitating/undertaking/aiding any other business by
                 using the Content or Services in any manner whatsoever (For
                 example, by attempting to distribute or advertise an index of
                 any portion of the Content, organizing contests, sales).
               </p>
               <p>
                 b) You shall not use any information (i) that is provided on
                 the Website and App, and/or (ii) that is belonging to other
                 User(s), in breach of applicable law, including the Information
                 Technology Act, 2000 (as amended) and Copyright Act, 1957 (as
                 amended).
               </p>
               <p>
                 c) You shall not take any action that unreasonably encumbers
                 the Website’s and/or App’s infrastructure, or interferes or
                 attempts to interfere with the proper functioning of the
                 Website and App, or the delivery of the Services.
               </p>
               <p>
                 d) You shall not use any technology or automated system, using,
                 but not limited to, “robots”, “spiders”, “offline readers”,
                 “bots”, etc. (other than by individually performed searches on
                 publicly accessible search engines for the sole purpose of, and
                 solely to the extent necessary for, creating publicly available
                 search indices (but not caches or archives) of the Content, and
                 excluding those search engines or indices that host, promote,
                 or link to any infringing or unauthorized content) to access
                 the Services or Content, Website and/or App for any purpose
                 whatsoever, without _____________’s prior written approval.
               </p>
               <p>
                 e) Use the Services to advertise or promote competing services;
               </p>
               <p>
                 including but not limited to, names, addresses, phone numbers,
                 or email addresses, through, but not limited to, a mirrored,
                 competitive, or third-party site.
               </p>
               <p>
                 g) You shall not in any way transmit more request messages to
                 the Website and/or App servers in a given period of time than a
                 human can reasonably produce in the same period by using
                 conventional methods.
               </p>
               <p>
                 h) You shall not directly or indirectly through the use of any
                 device, software, internet site, web-based service, or by any
                 other means:
               </p>
               <p>
                 i) remove, alter, bypass, avoid, violate, interfere with, or
                 circumvent any patent, trade secret, copyright, design,
                 trademark, or any other intellectual property or proprietary
                 information relating to the Content, Services, Website and the
                 App;
               </p>
               <p>
                 ii) bypass any content protection or access control measure
                 associated with the Content, Services, Website, and App,
                 including but not limited to, geo-filtering mechanisms,
                 parental controls, User information, etc.
               </p>
               <p>
                 iii) Send or cause to be sent (directly or indirectly)
                 unsolicited bulk messages or other unsolicited bulk
                 communications of any kind through the Website or App. If You
                 do so, You acknowledge You will have caused substantial harm to
                 _____________, but that the amount of harm would be extremely
                 difficult to measure. As a reasonable estimation of such harm,
                 and by way of liquidated damages and not as a penalty, You
                 agree to pay _____________ INR 5000/- (Indian Rupees five
                 thousand only) for each actual or intended recipient of such
                 communication without prejudice to any other rights of action
                 that _____________ may have against You in law or equity;
               </p>
               <p>
                 iv) Participation in any contest(s) and/or game(s) on the
                 Website/App, or viewing of certain Content, may be subject to
                 further terms and conditions, and the same will be notified by
                 _____________ from time to time on the Website or otherwise.
               </p>
               <h4 className="full-width secondary-heading">2.9 Free Trial</h4>
               <p>
                 Some of the Subscription plans, or limited time offers, may
                 start with a free trial. This free trial period may last for
                 any period of time determined by _____________ on its sole
                 discretion as provided by the Subscription plan/offer details.
                 Your eligibility for a free trial, including the time and
                 access to any Content during such free trial, is at the
                 discretion of _____________. Further, such free trial(s) can be
                 modified, suspended or terminated by _____________ at any point
                 of time without giving notice to You.
               </p>
               <h4 className="full-width secondary-heading">
                 2.10 Subscription Fees:
               </h4>
               <p>
                 a) The Content on the App and/or Website can only be availed by
                 Authorized Users who conform to the provisions of this
                 Agreement. To become an Authorized User, You may be required to
                 provide Your Login Credentials that may contain Personal
                 Information and Sensitive Personal Information, in order for
                 you to effectively avail the Services. Further, in order to
                 avail the Services as an Authorized User (“Subscription”), You
                 may be required to make certain payments in the manner detailed
                 below (“Subscription Fees”).
               </p>
               <p>
                 b) To facilitate Your viewership and access, the Services can
                 be packaged by _____________ through different subscription
                 plans (at _____________’s sole discretion) such that the
                 Content or Services may be accessible: (a) free of charge which
                 may include advertisements or commercials, or (b) via
                 subscription through payment of a subscription fee with or
                 without advertisements/commercials or (c) via a pay-per-view
                 model with or without advertisements/commercials or (d) with a
                 combination of any of the foregoing.
               </p>
               <p>
                 c) Notwithstanding anything stated above, _____________
                 reserves the right to change, terminate or otherwise amend the
                 Subscription plans, Subscription Fees and billing cycles, at
                 its sole discretion and at any time. Such amendments shall be
                 effective upon posting on the Website/App and Your continued
                 use of the Service shall be deemed to be Your conclusive
                 acceptance of such amendments.
               </p>
               <p>
                 Further, _____________ reserves the right to change,
                 supplement, alter or remove any of the Content available to
                 different subscription plans and subject to a Subscription Fee
                 as it deems fit. _____________ does not guarantee the
                 availability of any specific/minimum Content under any
                 subscription plan that is subject to a Subscription Fee.
                 _____________ shall have the discretion to (i) make certain
                 Content that is a part of the Subscription available on more
                 than one end user Compatible System concurrently, (ii) not make
                 certain Content that is a part of the Subscription available on
                 more than one end user Compatible System.
               </p>
               <h4 className="full-width secondary-heading">2.11 Payment</h4>
               <p>
                 a) Users can opt from various payment methods such as debit
                 card, credit card, internet banking that are provided on
                 Website/App during their registration (“Payment Methods”) for
                 payment of the Subscription Fees.
               </p>
               <p>
                 b) The Users will be billed as per their selected subscription
                 plan and via the Payment Method elected by them at the time of
                 registration. Any changes in the Subscription Plan opted by You
                 is effective only after the expiry of the current Subscription
                 period for which You have already been billed. Accordingly, the
                 Subscription as per revised plan opted by You shall be
                 effective only after the expiry of the then current
                 Subscription period.”
               </p>
               <p>
                 c) The selected subscription plan may automatically commence at
                 the end of the free trial period, if applicable, unless You
                 cancel the Subscription before the end of the free trial
                 period.
               </p>
               <p>
                 d) The cancellation of the Subscription can be done through the
                 “Account” section of Website and/or App. It may take a few days
                 for Your payment made on Website and/or App to be reflected in
                 Your “Account” section.
               </p>
               <p>
                 e) You cannot change your selected Payment Method until the
                 expiry of your chosen subscription period. Further, if Your
                 selected Payment Method is no longer available, expires, or
                 fails for any reason whatsoever, You will be solely responsible
                 and liable for payment of any uncollected amounts, and
                 _____________ reserves the right to terminate the Subscription
                 offered to You.
               </p>
               <p>
                 f) The Subscription Fee will be billed from the beginning of
                 Your membership, or at the end of free trial period, as
                 applicable. Further, on the end of the chosen Subscription
                 period (as per the Subscription plan chosen by You), the
                 Subscription when renewed by You shall continue unless and
                 until: (i) You cancel Your Subscription, or (ii) the
                 Subscription is otherwise suspended or discontinued pursuant to
                 these Terms. To view information regarding the above or
                 cancelling Your Subscription, You need to visit the “My
                 Account” section on My Account.
               </p>
               <p>
                 g) The Subscription Fee Specified for each Subscription plan
                 are inclusive of applicable taxes for Indian Subscribers and
                 subject to the applicable law of other subscribers. Further,
                 the subscription Fees paid to _____________ for availing the
                 Services are final and non-refundable. Please refer our Refund
                 Policy in the Refund Policy Section, which shall be deemed to
                 form part and parcel of this Agreement.
               </p>
               <p>
                 h) _____________ will be using third party payment gateways to
                 process and facilitate the payment of Your Subscription Fee to
                 _____________. On successful registration to the Subscription,
                 _____________ will intimate You of the same through e-mail.
                 _____________ may offer different kinds of Subscription plans,
                 each of these Subscription plans being subject to different
                 limitations and restrictions and the cost of each of these
                 Subscription plans may vary.
               </p>
               <p>
                 i) You further understand and acknowledge that Website/App only
                 facilitates the third-party payment gateway(s) used for
                 processing payments. This facility is fully managed by the
                 third-party payment gateway provider(s) and You are required to
                 follow all the terms and conditions of such third-party payment
                 gateway provider(s).
               </p>
               <p>
                 j) You are responsible for the accuracy and authenticity of the
                 information provided by You to third-party payment gateways,
                 including the bank account number/credit card details and the
                 like. You agree and acknowledge that _____________ shall not be
                 liable and in no way be held responsible for any losses
                 whatsoever, whether direct, indirect, incidental or
                 consequential, including without limitation any losses due to
                 delay in processing of payment instruction or any credit card
                 fraud.
               </p>
               <p>
                 k) You can reach out to Us for any issue related to payment
                 processing on the Website/App, and the same will be forwarded
                 to the concerned third-party payment gateway provider for
                 redressal.
               </p>
               <p>
                 l) _____________ may, at any time, introduce different kinds of
                 offers, or rewards, or benefits or plans for the use and avail
                 of the Services of the _____________ App/Website, each being
                 subject to different limitations and restrictions.
               </p>
               <h4 className="full-width secondary-heading">
                 2.12 Non-Compliance
               </h4>
               <p>
                 In case of any non-compliance with this Agreement, We shall
                 have the right to immediately:
               </p>
               <p>a) Limit the usage of the Website and/or App,</p>
               <p>b) Remove any non-compliant information,</p>
               <p>c) Suspend the account of the User, and</p>
               <p>d) limit any benefits/privileges of the Users, and/or</p>
               <p>
                 e ) forfeit, or obtain refund of, any fees earned by the Users
                 from such non-compliant usage.
               </p>
               <h4 className="full-width secondary-heading">
                 2.13 Cancellation of Services
               </h4>
               <p>
                 In the event You cancel Our Services, upon Interacting with the
                 Website and App, the amount paid for such subscription shall
                 not be refunded to you, however the subscription will remain
                 active and can be availed till the end of the billing period.{" "}
               </p>
               <h4 className="full-width secondary-heading">
                 2.14 User Consent to use Information
               </h4>
               <p>
                 You understand, acknowledge and give Your consent for the
                 sharing of Your information, including Personal Information, in
                 accordance with the Privacy Policy of _____________, may be
                 accessed at Privacy Policy
               </p>
               <h4 className="full-width secondary-heading">
                 2.15 Representations and Warranties of the User
               </h4>
               <p>You represent and warrant to Us at all times that:</p>
               <p>
                 a) You are either (i) a major or, (ii) under the supervision of
                 a legal guardian in case of being a minor, at the time of using
                 the Services.
               </p>
               <p>
                 b) You shall not use the Website/App or any of the Services or
                 the information of other Users, available on the Website/App,
                 beyond the contemplated functionality and purpose of the
                 Website/App and the Services.
               </p>
               <p>
                 c) You shall not attempt to gain unauthorized access or in any
                 way exceed the authorized access (i) to the Website/App or any
                 of the Services, or (ii) any networks, servers or computer
                 systems connected to the Website/App or Services.
               </p>
               <p>
                 d) You shall not use the Website/App or any of the Services or
                 the personal information available on the Website, in a way
                 that may damage or impair (i) the Website/App or the Services,
                 or (ii) Our underlying systems and security.
               </p>
               <p>
                 e) You shall not use the Website/App or any of the Services or
                 any information on the Website/App, including any Personal
                 Information and Sensitive Personal Information available on the
                 Website/App, in any unlawful manner, or in a manner which
                 promotes or encourages illegal or fraudulent activity,
                 including (without limitation) intellectual property
                 infringement or data theft of the Content.
               </p>
               <p>
                 f) You shall not re-sell, rent, license, or otherwise use any
                 part of the Website/App or Services to obtain any personal or
                 third-party gain.
               </p>
               <p>
                 g) You shall not use any of _____________, _____________
                 Affiliates, _____________ licensor’s intellectual property,
                 including but not limited to, the copyrighted content displayed
                 on the Website and/or App, for any personal or third-party gain
                 without Our express written consent or authorization.
               </p>
               <p>
                 h) You shall not decipher, decompile, modify, disassemble,
                 adapt, translate or reverse engineer any part of the Website,
                 App and/or the Services (e.g., by creating a shadow website
                 that mirrors the said Services).
               </p>
               <p>
                 i) Damage, disable, disrupt, impair, create an undue burden on,
                 Interfere with, or gain unauthorized access to the Services or
                 the Content, as well as _____________ and _____________
                 Affiliate’s servers, networks or accounts;
               </p>
               <p>
                 j) Remove, disable, manipulate, block, cover, or obscure any
                 advertisements, as well as any parts of the Content.
               </p>
               <h4 className="heading-primary full-width">
                 3. REGISTRATION/CREATION OF PROFILE
               </h4>
               <h4 className="full-width secondary-heading">
                 3.1 Account Creation and Deletion
               </h4>
               <p>
                 In order to use Our Website/App and avail Our Services, You
                 must sign up and create a Profile, which would include Your
                 Personal Information. You may delete Your Profile at any time,
                 for any reason, by following the instructions provided on the
                 Website/App. However, You must note that We reserve the right
                 to retain Your Account Information and other information for
                 the purposes provided under _____________’s Privacy Policy
               </p>
               <p>
                 (a) all of the Account Information You submit to Us is true,
                 accurate and complete in all respects;
               </p>
               <p>
                 (b) You will maintain the accuracy of the Account Information
                 that You provide Us with at all times; And
               </p>
               <p>
                 (c) You will update any and all changes in the Account
                 Information by intimating us as per the Terms and
                 _____________’s Privacy Policy.
               </p>
               <h4 className="full-width secondary-heading">
                 3.2 User Responsibilities
               </h4>
               <p>
                 You are responsible for maintaining the confidentiality of Your
                 Login Credentials and are fully responsible for all activities
                 that occur under Your Profile (e.g., once logged in). You agree
                 to immediately notify Us of any unauthorized use, or suspected
                 unauthorized use of Your Profile, or any other breach of
                 security, in relation to Your personal information on the
                 Website. Upon such notification from You, We will reasonably
                 assist You in changing Your password.
               </p>
               <h4 className="heading-primary full-width">
                 4. ACCESS TO THE WEBSITE AND APP
               </h4>
               <h4 className="full-width secondary-heading">
                 4.1 Certain Restrictions
               </h4>
               <p>
                 The rights granted to You in these Terms are subject to the
                 following restrictions:
               </p>
               <p>
                 (a) except as otherwise authorized, You shall not license,
                 sell, re-sell, lease, transfer, assign, distribute, exchange,
                 host, or by any other means copy, download, capture, reproduce,
                 duplicate, archive, distribute, upload, publish, modify,
                 translate, broadcast, perform, display, sell, deep-link,
                 transmit or retransmit, make any derivative works of, or
                 otherwise commercially exploit any (i) Content, (ii) Services,
                 or (iii) the information (including Personal Information and
                 Sensitive Personal Information) of Users available on the
                 Website or App; whether in whole or in part;
               </p>
               <p>
                 (b) You shall not modify, make derivative works of,
                 disassemble, reverse compile or reverse engineer any part of
                 the Website, App and Services, or the personal information of
                 other Users, available on the Website;
               </p>
               <p>
                 (c) You shall not access the Website, App and/or its Services,
                 or the personal information of other Users, available on the
                 Website/App in order to build a similar or competitive website,
                 app, product, or service; and
               </p>
               <p>
                 (d) except as expressly stated herein, no part of the Website,
                 App and/or its Service, or the personal information of other
                 Users, available on the Website, may be copied, reproduced,
                 distributed, republished, indexed, framed, downloaded, linked,
                 displayed, posted or transmitted in any form or by any means.
                 Unless otherwise indicated, any future release, update, or
                 other addition to functionality or content of the Website shall
                 be subject to these Terms. All copyright and other proprietary
                 notices on the Website must be retained on all copies thereof.
               </p>
               <h4 className="full-width secondary-heading">
                 4.2 Modification, Suspension or Discontinuation
               </h4>
               <p>
                 You agree that _____________ has the right to temporarily
                 suspend access to the whole or any part of the Services for any
                 reason whatsoever (including technical/operational reasons) and
                 shall be under no liability to You in such an event. Further,
                 _____________ may, but shall not be obliged to, give You notice
                 of any interruption of access to the Services as is reasonably
                 practicable. _____________ will restore access to the Services
                 as soon as reasonably practicable after temporary suspension.
               </p>
               <p>
                 Notwithstanding anything stated above, We reserve the right, at
                 any time and in its sole discretion, to change, modify,
                 suspend, or discontinue the Website/App (in whole, or in part)
                 or any of its Services (in whole, or in part), in compliance
                 with the applicable legal and regulatory framework. You agree
                 that We will not be liable to You or to any third party for any
                 change, modification, suspension, or discontinuation of the APP
                 and/or Website or its Services, or any part of the APP and/or
                 Website or its Services thereof.
               </p>
               <h4 className="full-width secondary-heading">
                 4.3 No Support or Maintenance
               </h4>
               <p>
                 If You have any questions, complaints or claims with respect to
                 the Website, App, Content and/or Services, then such
                 correspondence should be directed to: Contact us However, You
                 acknowledge and agree that We will have no obligation to
                 provide You with any uninterrupted or immediate support or
                 maintenance in connection with the Website, App and/or
                 Services, except as provided under _____________’s Privacy
                 Policy.
               </p>
               <h4 className="full-width secondary-heading">4.4 Ownership</h4>
               <p>
                 Excluding Your Account Information, You acknowledge that the
                 Website, App, the entire Content, Services, and all the
                 intellectual property rights, including copyrights, patents,
                 trademarks, designs, and trade secrets in relation to the above
                 are solely owned by _____________, _____________ Affiliates
                 and/or _____________’s Licensors (“Lawful Owners”).
               </p>
               <p>
                 Notwithstanding anything stated in the Agreement, the Lawful
                 Owners own and retain any all intellectual and proprietary
                 rights to the Content and Services throughout the territory of
                 the world in perpetuity. These Terms do not transfer to You or
                 any third party any rights, title or interests in, or to such,
                 intellectual property rights, except for the limited license
                 that is required to view/use the Website, App, Content and
                 Services per the Agreement.
               </p>
               <h4 className="heading-primary full-width">
                 5. PROVIDED INFORMATION & ACCEPTABLE USE POLICY
               </h4>
               <h4 className="full-width secondary-heading">5.1 Risks</h4>
               <p>
                 You represent and warrant that the Account Information, or any
                 other information, whether personal or sensitive, provided to
                 Us through an email, support forum on the Website, etc. (“Other
                 Information”) does not violate Our ‘Acceptable Use Policy’
                 clause below. You may not represent or imply to any person or
                 entity, in any way whatsoever, that the Account Information or
                 Other Information that You provide Us with is sponsored or
                 endorsed by Us in any manner. Because You alone are responsible
                 for Your Account Information and the Other Information that You
                 provide Us with. You may expose Yourself to liability if, for
                 example, the Account Information or Other Information violates
                 the ‘Acceptable Use Policy’ clause. You must note that We are
                 not obligated to create a backup copy of Your Account
                 Information or Other Information that You provide Us with; We
                 will not be held liable or responsible for the deletion of the
                 Account Information or any Other Information that You provide
                 Us with.
               </p>
               <h4 className="full-width secondary-heading">
                 5.2 Acceptable Use Policy
               </h4>
               <p>
                 The following Terms, along with the provisions of Clause 2 of
                 the Terms, constitutes our “Acceptable Use Policy”:
               </p>
               <p>
                 a) You agree not to use the Website and/or its Services to
                 collect, upload, create, transmit, display, or distribute the
                 Account Information or Other Information in any way such: (i)
                 that it violates any third-party right, including any
                 copyright, trademark, patent, moral right, privacy right, right
                 of publicity, or any other intellectual property or proprietary
                 right; or (ii) that it is in violation of any law, regulation,
                 or obligations or restrictions imposed by any third party; or
                 (iii) that it is hateful, violent, discriminatory, defamatory,
                 racially or ethnically objectionable, unlawful, obscene,
                 sexually suggestive, or pornographic.
               </p>
               <p>b) In addition, You agree not to:</p>
               <p>
                 (i) display, upload, modify, transmit, publish, or distribute
                 to, or through, the Website, App or otherwise, any computer
                 viruses, worms, or any software codes, files or programs,
                 intended to damage, limit or alter a computer system, computer
                 network, or data, and/or gain unauthorized access to a computer
                 or computer network;
               </p>
               <p>
                 (ii) manipulate the Website to send unsolicited or unauthorized
                 advertising, promotional materials, junk mail, spam, chain
                 letters, pyramid schemes, or any other form of duplicative or
                 unsolicited messages, whether commercial or otherwise;
               </p>
               <p>
                 (iii) manipulate the Website to harvest, collect, gather, or
                 assemble information or data, personal or sensitive regarding
                 any other users or individuals, including their e-mail
                 addresses, with or without their consent;
               </p>
               <p>
                 (iv) interfere with, disrupt, or create an undue burden on
                 servers or networks connected to the Website or the data hosted
                 within the Website, or violate the regulations, policies,
                 restrictions or procedures of such networks;
               </p>
               <p>
                 (v) attempt to gain unauthorized access to a third party’s
                 information on the Website (or to other computer systems or
                 networks connected to or used together with the Website),
                 whether through password mining or any other means;
               </p>
               <p>
                 (vi) harass or interfere with any other user’s use and
                 enjoyment of the Website and/or it Services; or
               </p>
               <p>
                 (vi) use software or automated agents or scripts to produce
                 multiple accounts on the Website, or to generate automated
                 searches, requests, or queries to (or to strip, scrape, or mine
                 data from) the Website.
               </p>
               <h4 className="full-width secondary-heading">5.3 Enforcement</h4>
               <p>
                 a) We reserve the right to review Your Account Information or
                 any other information from time to time, and to investigate
                 and/or take appropriate action against You if you violate the
                 ‘Acceptable Use Policy’ clause, or any other provision of these
                 Terms, or otherwise create liability for Us or any other person
                 or Entity, in Our sole discretion. Such action may include (i)
                 removing or modifying Your Profile, (ii) terminating Your
                 Profile in accordance with the ‘Term and Termination clause’,
                 and/or (iii) reporting You to, and cooperative with, law
                 enforcement authorities.
               </p>
               <p>
                 b) Any and all of Your information can be shared in the above
                 circumstances with competent authorities upon any demand or
                 request by them, or in a good faith belief, that such access,
                 preservation, or disclosure is reasonably necessary to: (a)
                 comply with any applicable law; (b) enforce the Terms; (c)
                 respond to Your Service requests and/or for any customer
                 service; or (d) protect the rights, property or personal safety
                 of _____________, _____________ Affiliates, other Users and the
                 public.
               </p>
               <h4 className="full-width secondary-heading">5.4 Feedback</h4>
               <p>
                 If You provide Us with any feedback or suggestions regarding
                 the Website, Content and/or the Services (“Feedback”), You
                 hereby assign to Us all rights in such Feedback and agree that
                 We shall have the right to use and fully exploit such Feedback
                 and related information in any manner We deem appropriate. We
                 will treat any Feedback You provide to Us as non-confidential
                 and non-proprietary; therefore, kindly do not submit to Us any
                 information or ideas that You consider to be confidential or
                 proprietary.
               </p>
               <h4 className="heading-primary full-width">
                 6. Indemnification
               </h4>
               <p>
                 By using the website, app, services and/or the content in any
                 manner whatsoever, you agree, to the maximum extent permitted
                 by law, to defend and indemnify _____________, _____________
                 affiliates and _____________ licensor’s from any and all
                 claim(s), demand(s), damages, obligations, losses, liabilities,
                 costs or expenses (including but not limited to, all applicable
                 costs and advocate fees) made against us by any third party
                 that is due to, in relation to, or arising out of:
               </p>
               <p>(a) your use of the website, app or the services,</p>
               <p>
                 (b) your violation of these terms and/or _____________’s
                 privacy policy,and
               </p>
               <p>
                 (c) your violation of applicable laws or regulations. We
                 reserve the right, at your expense, to assume the exclusive
                 defense and control of any matter for which you are required to
                 fully indemnify us, and you agree to cooperate with our defense
                 of these claims. Additionally, you agree not to settle any
                 matter without our prior written consent. We will use
                 reasonable efforts to notify you of any such claim, action or
                 proceeding upon becoming aware of it, to the extent permissible
                 by applicable law. This indemnity clause is applicable to
                 violations described in or contemplated in these terms
               </p>
               <h4 className="heading-primary full-width">7. Release</h4>
               <p>
                 Subject to applicable law, you hereby release and forever
                 discharge _____________ and _____________ affiliates from, and
                 hereby waive and relinquish, each and every past, present and
                 future dispute, claim, controversy, demand, right, obligation,
                 liability, action and cause of action of every kind and nature,
                 that arises or has arisen, directly or indirectly out of, or
                 that relates directly or indirectly to, the Website, App and/or
                 its Services (including any interactions with, or act or
                 omission of, other users, or any third-party links &
                 advertisements). Further, if you have a dispute with one or
                 more user(s), you forever release _____________ and
                 _____________ affiliates from any and all claims, demands and
                 damages (actual and consequential) of every kind and nature,
                 known and unknown, arising out of, or in any way connected
                 with, your use of the Website, App and/or the Services and/or
                 any submitted content.
               </p>
               <h4 className="heading-primary full-width">
                 8. Disclaimer of Warranties
               </h4>
               <p>
                 8.1 You agree that your use of the Website/App shall be at your
                 own risk (to the fullest extent permitted by applicable law),
                 and for the same, _____________ and/or _____________ affiliates
                 disclaim all warranties, express, implied, statutory or
                 otherwise, and make no warranties, representations or
                 guarantees in connection with this Website/App, the Services
                 offered on or through this Website/App, all submitted content
                 on, and in relation to, this Website/App and/or Services,
                 unless otherwise stated, to the maximum extent permitted by
                 applicable law. Any and all data, material, information,
                 submitted content and/or services contained or presented on
                 this Website/App is provided to you on an “as-is”, “as
                 available” and basis with no warranty of implied warranty of
                 merchantability, fitness for a particular purpose,
                 completeness, availability, security, compatibility, or
                 non-infringement of third-party rights. _____________ does not
                 endorse, warrant, guarantee, or assume responsibility for any
                 product or service advertised or offered by a service providers
                 or any other third party through the Website and/or App, or any
                 hyperlinked site, or other advertisement. We make no warranty
                 that the Website/App will meet your requirements, will be
                 available on an uninterrupted, timely, secure, or error-free
                 basis, or will be accurate, reliable, legal, safe, or free of
                 viruses or any other harmful code. You should use your best
                 judgment and exercise caution.
               </p>
               <p>
                 8.2 We will not be liable to you (whether under the law of
                 contract, the law of torts or under any other applicable law or
                 otherwise), in relation to the contents of, or use of, or
                 otherwise in connection with, the Services and the Website/App:
               </p>
               <p>
                 (a) to the extent that the Website, App and Services are
                 provided free-of-charge - for any kind of loss whatsoever (such
                 as direct, indirect, special or consequential loss);
               </p>
               <p>
                 (b) for any other services - for any indirect, special or
                 consequential loss, business losses, loss of revenue, income,
                 profits or anticipated savings, loss of contracts or business
                 relationships, loss of reputation or goodwill, or loss or
                 corruption of information or data, or security intrusion.
               </p>
               <h4 className="heading-primary full-width">
                 9. Limitation of liability
               </h4>
               <p>
                 9.1. To the maximum extent permitted by law, in no event shall
                 _____________ and/or _____________ affiliates be liable to you
                 for any direct, indirect, consequential, exemplary, incidental,
                 special or punitive damages, lost profits or lost/stolen data
                 arising from, or relating to the Terms; or your use of, or
                 inability to use, the Website, App, Services and/or submitted
                 content, even if we have been advised of the possibility of
                 such damages. Any access to, and use of, the Website, App,
                 Services and the content is at your sole discretion and risk,
                 and you will be solely responsible for any damage to you or
                 your property, including your device or computer system, or
                 loss of any data, including account information, resulting
                 therefrom. The foregoing limitation of liability shall apply to
                 the fullest extent permitted by law.
               </p>
               <p>
                 9.2. Notwithstanding anything stated herein, all exclusions in
                 this limitation of liability clause shall be applicable to the
                 maximum extent permitted by law (e.g., some jurisdictions may
                 not permit exclusion of liability for fraudulent
                 misrepresentation).
               </p>
               <h4 className="heading-primary full-width">
                 10. THIRD-PARTY LINKS & ADVERTISEMENTS
               </h4>
               <h4 className="full-width secondary-heading">
                 10.1. Third-Party Links & Advertisements
               </h4>
               <p>
                 The Website/App may contain links to third-party links &
                 advertisements (“External Links”) that You may access and/or
                 use at Your sole discretion. You acknowledge and agree that
                 such External Links are not under Our control, and therefore We
                 shall not be responsible, in any manner, for any transactions
                 related to External Links. You must note that We provide access
                 to these External Links only as a convenience to You, and We do
                 not review, approve, monitor, endorse, warrant, or make any
                 representations with respect to External Links, in any manner
                 whatsoever. You shall use all External Links at Your own risk
                 and should exercise a suitable level of caution and discretion
                 in doing so. When You click on any of the External Links, the
                 applicable third party’s terms and policies shall apply to You,
                 including the third party’s privacy and data gathering
                 practices. You should independently undertake whatever
                 investigation You feel necessary or appropriate before
                 proceeding with any transaction in connection with such
                 External Links. By using the Services, You acknowledge and
                 agree that _____________ is not responsible or liable to You
                 for any content or other materials hosted and served from any
                 website or destination other than the App or the Website.
                 Further, _____________ takes no responsibility for the External
                 Links posted on the Website/App nor does it take any
                 responsibility for the products or services provided by such
                 advertisers and other third parties.
               </p>
               <h4 className="full-width secondary-heading">
                 10.2. User’s Note
               </h4>
               <p>
                 You acknowledge and agree that We will not be responsible for
                 any loss or damage that You may incur as a result of Your
                 Interaction with Our Website,App or the use of Services, or the
                 manipulation of Account Information and Other Information. If
                 there is a dispute between You and any other User, We are under
                 no obligation to become involved and resolve the dispute for
                 You.
               </p>
               <h4 className="heading-primary full-width">
                 11. TERM AND TERMINATION
               </h4>
               <p>
                 11.1. Subject to this ‘Term and Termination clause’, these
                 Terms will remain in full force and effect while You use the
                 Website, App and/or any of its Services, in any way whatsoever.
                 "Upon Termination of a Subscription, the You (erstwhile
                 Authorised User) acknowledge that You may still be contacted by
                 Us to subscribe to Our Services or for other promotional or
                 marketing purposes. You understand that this communication may
                 override any Do Not Disturb (DND) registry or DNC or National
                 Customer Preference Register (NCPR) that You may have
                 registered for. Your data collected at the time of your initial
                 Subscription to _____________ shall be subject to _____________
                 Privacy Policy, available at https://www._____________.
               </p>
               <p>
                 11.2. We may suspend or terminate Your rights to use the
                 Website and App (including Your Account) at any time and for
                 any reason, at Our sole discretion, including for any use of
                 the Website, App and/or its Services in violation of these
                 Terms.
               </p>
               <p>
                 11.3. Upon termination of Your rights under these Terms, Your
                 Profile and right to access and use the Website, App and/or its
                 Services, will terminate immediately. You understand that any
                 termination of Your Profile may involve deletion of the Account
                 Information and any other Information associated with Your
                 Profile from all Our databases. We will not have any liability
                 whatsoever to You for any termination of Your rights under
                 these Terms, including for termination of Your Profile.
               </p>
               <h4 className="heading-primary full-width">12. GENERAL</h4>
               <h4 className="full-width secondary-heading">
                 12.1. Electronic Communication
               </h4>
               <p>
                 You (a) consent to receive communications from Us in an
                 electronic form; and (b) agree that all Terms and conditions,
                 agreements, notices, disclosures, and other communications that
                 We provide to You electronically satisfy any legal requirement
                 that such communications would satisfy if it were to be in a
                 hardcopy writing. You give consent to _____________ and
                 _____________ Affiliates to contact You using Your contact
                 information at any time during Your association with
                 _____________ as per _____________’s Privacy Policy. You
                 further acknowledge and agree that You may be contacted for the
                 above purposes using the contact details provided by You,
                 including via phone calls, emails, text messages, and social
                 media apps such as WhatsApp.
               </p>
               <h4 className="full-width secondary-heading">12.2. Waiver</h4>
               <p>
                 Any waiver of Our rights or remedy under these Terms shall only
                 be effective if it is in writing, executed by Our duly
                 authorized representative, and shall be applicable only to the
                 circumstances for which it is given. Our failure to exercise or
                 enforce any right or remedy under these Terms shall not operate
                 as a waiver of such right or remedy, nor shall it prevent any
                 future exercise or enforcement of such right or remedy. No
                 single or partial exercise of any right or remedy shall
                 preclude or restrict the further exercise of any such right, or
                 remedy, or other rights or remedies.
               </p>
               <h4 className="full-width secondary-heading">
                 12.3. Headings and Interpretation
               </h4>
               <p>
                 The section titles in these Terms are for convenience only and
                 have no legal or contractual effect. The words “including” and
                 “includes” means “including without limitation”.
               </p>
               <h4 className="full-width secondary-heading">
                 12.4. Severability
               </h4>
               <p>
                 The provision(s) of these Terms shall be sought to be
                 harmoniously interpreted with each other, as well as applicable
                 laws (including the applicable laws based on the jurisdiction
                 of the User’s), and upheld to the fullest extent permissible
                 under applicable laws. Further, the provisions contained in
                 this Agreement shall be enforceable independent of each other,
                 and their validity shall not be affected, if any other
                 provision(s) are held to be invalid. If any provision(s) of
                 these Terms are, for any reason, held to be invalid or
                 unenforceable, the other provisions of these Terms will be
                 unimpaired (to the maximum extent permissible), and the invalid
                 or unenforceable provision(s) will be deemed modified so that
                 they are valid and enforceable to the maximum extent permitted
                 by applicable laws. Further, if any of those provision(s) are
                 void, but would be valid if some part of the provision(s) were
                 deleted, the provision(s) in question shall apply with such
                 modification as may be necessary to make them valid.
               </p>
               <h4 className="full-width secondary-heading">
                 12.5. Relationship between You and Us
               </h4>
               <p>
                 You confirm that You do not have an employment, contractor,
                 agency or partnership relationship with _____________.
                 _____________ is merely providing You the Services on your
                 request. Further, You are acting on either on Your own or
                 behalf of another person, in the manner stated in the
                 Agreement.
               </p>
               <h4 className="full-width secondary-heading">
                 12.6. Assignment
               </h4>
               <p>
                 These Terms, and Your rights and obligations herein, may not be
                 assigned, subcontracted, delegated, or otherwise transferred by
                 You without Our prior written consent, and any attempted
                 assignment, subcontract, delegation, or transfer in violation
                 of the foregoing will be null and void. However, You
                 acknowledge and agree that We reserve the right to freely
                 assign Our rights and obligations under these Terms to
                 whomsoever We please. The Terms and conditions set forth in
                 these Terms shall be binding upon assignees.
               </p>
               <h4 className="full-width secondary-heading">
                 12.7. Governing Law and Jurisdiction
               </h4>
               <p>
                 These Terms and any dispute or claim arising out of or in
                 connection with their subject matter or formation (including
                 non-contractual disputes or claims) shall be governed by and
                 construed in accordance with the laws prevalent in India. You
                 agree that the courts of Hyderabad shall have exclusive
                 jurisdiction to settle any dispute, or claim, arising out of,
                 or in relation to, these Terms.
               </p>
               <h4 className="full-width secondary-heading">
                 12.8. Contact Information
               </h4>
               <p>
                 If You wish to contact Us in writing, or if these Terms require
                 You to give notice to Us in writing, please contact Us at
                 Contact us.
               </p>
               <h4 className="full-width secondary-heading">
                 12.9. Entire Agreement and Amendments
               </h4>
               <p>
                 These Terms together with the Privacy Policy and Refund Policy
                 aexpressly supersede and completely replace any and all prior
                 ‘Terms of Use’ published by _____________ regarding the above
                 subject-matter. _____________ shall not be bound by or liable
                 to You for any pre-existing or contemporaneous written or oral
                 representations or warranties, made by anyone, with respect to
                 the Website, App, Content and Services, including by any
                 _____________ Affiliates.
               </p>
               <p>
                 _____________ reserves the right, at its sole discretion, to
                 modify the Terms from time to time (“Updated Terms of Use”).
                 The Updated Terms of Use shall be effective immediately and
                 shall supersede these Terms of Use. _____________ shall not be
                 under an obligation to notify you of any changes to the Terms
                 of Use. You shall be solely responsible for reviewing the Terms
                 of Use from time to time for any modifications. By continuing
                 to use the Website, App, Content and/or Services after the
                 Updated Terms of Use have been published, You affirm Your
                 agreement to the Updated Terms of Use.
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
   );
}

export default TermsConditions;