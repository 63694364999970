import React, { useState, useEffect, useContext } from "react";
import { AddCircleOutline, Play, CheckmarkCircle } from "react-ionicons";
import { useTranslation } from "react-i18next";

// Custom import
import "./poster.scss";
import { getUserData, getUserDetails, toggleWishList } from "../API";
import { Context } from "../../../Context";
import { LOGIN_POPUP, LOGIN_SUCCESS } from "../../reducer/types";

export const PosterPotrait = (props) => {
  const [inWishlist, setInWishlist] = useState(false);

  const {t} = useTranslation();

  const { state, dispatch } = useContext(Context);


  const wishlistButton = () => {

    if(state.isAuthenticated && state.profileName) {
      if (inWishlist) {
        setInWishlist(false);
        toggleWishList(
          state._id,
          state.token,
          props.id,
          props.type,
          props.image,
          true,
          state.profileName
        ).then((res) => {
          console.log("ITEM REMOVED");

          console.log("WishListButton - ", res);

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              ...res,
            },
          });
        });
      } else {

        setInWishlist(true);
        toggleWishList(
          state._id,
          state.token,
          props.id,
          props.type,
          props.image,
          false,
          state.profileName
        ).then((res) => {
          console.log("ITEM ADDED");

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              ...res,
            },
          });
        });
      }


    } else {
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: true
        }
      })
    }

    /* if(inWishlist) {
      setInWishlist(false);
      toggleWishList(props.id, props.type, props.image, true);
      getUserDetails(state.ftoken);
    } else {
      setInWishlist(true);
      toggleWishList(props.id, props.type, props.image);
      getUserDetails(state.ftoken);
    } */

  };

  useEffect((prevProps) => {

    if (state.isAuthenticated && state.wishList && state.wishList.length > 0) {
      state.wishList.map(item => {
        if (item.id === props.id) {
          console.log('This video exists in wishlist');
          setInWishlist(true);
        }
      });
    }


  }, []);

  return (
    <div className={props.wide ? "posterPotrait posterWide" : "posterPotrait"}>
      <div className="posterThumbnail">
        {props.image ? <img src={props.image} alt="" /> : null}
        <button
          type="button"
          className="poasterPlayBtn btn p-0"
          onClick={props.onClick}
          title={t("watch", "Watch")}
        >
          <Play
            color={"#fff"}
            height="44px"
            width="44px"
            title={t("watch", "Watch")}
            cssClasses={"playIcon"}
          />
        </button>
        <button
          type="button"
          className={
            inWishlist
              ? "addWishListBtn btn p-0 added"
              : "addWishListBtn btn p-0"
          }
          onClick={() => wishlistButton()}
        >
          {!inWishlist ? (
            <>
              <span className="addWishListTitle">
                {t("addWishList", "Add Wishlist")}
              </span>
              <AddCircleOutline
                color={"#fff"}
                cssClasses="addWishList"
                title={t("addedWishList", "Added Wishlist")}
                height="28px"
                width="28px"
              />
            </>
          ) : (
            <>
              <span className="addWishListTitle">
                {t("addedWishList", "Added Wishlist")}
              </span>
              <CheckmarkCircle
                color={"#fff"}
                cssClasses="addWishList"
                title={"Add to Wishlist"}
                height="28px"
                width="28px"
              />
            </>
          )}
        </button>
      </div>
      <div className="posterInfo">
        <button
          type="button"
          className="posterInfoContent btn"
          onClick={props.onClick}
        >
          <div className="poster-title full-width text-truncate">
            {props.title}
          </div>
          {props.metaData && props.metaData.length > 0 ? (
            <div className="meta-list full-width">
              {props.metaData.map((meta, index) => (
                <span className="each-meta-item" key={index + "metPost"}>
                  {meta}
                </span>
              ))}
            </div>
          ) : null}
          {props.model ? (
            <div className="movie-model-block full-width">
              {props.model === "ppv" ? (
                <span className="movie-model ppv">{t("ppv", "Rent")}</span>
              ) : props.model === "subscription" ? (
                <span className="movie-model premium">
                  {t("premium", "Premium")}
                </span>
              ) : (
                <span className="movie-model free">
                  {t("free", "Free")}
                </span>
              )}
            </div>
          ) : null}
        </button>
      </div>
    </div>
  );
};
