import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import {
  ArrowBackOutline,
  MenuOutline,
  SearchOutline,
  CloseOutline,
  PersonCircleOutline,
  ChevronDownOutline,
  CloseCircle,
  LanguageOutline,
  LockClosed
} from "react-ionicons";
import { debounce } from "lodash";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Custom Imports
import "./header.scss";
import { firebase_app as firebase } from "../../firebase";
import { url } from "../API";
import { Rupee } from "../../components/Misc";
import { Context } from "../../../Context";
import {
  LANGUAGE,
  LOGIN_POPUP,
  LOGIN_REDIRECT_URL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PROFILE,
} from "../../reducer/types";
import ParentSmiley from "../../../assets/icons/smiley-1.svg";
import KidSmiley from "../../../assets/icons/smiley-kid-1.svg";

import RVRLogo from "../../../assets/images/aadhan2.png";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [logedIn, setLogedIn] = useState(false);
  const [subscribe, setSubscribe] = useState([]);
  const [userMenu, setUserMenu] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const { t, i18n } = useTranslation();

  const { state, dispatch } = useContext(Context);

  const searchTextbox = useRef();

  const firebaseUser = firebase.auth().currentUser;



  const searchChanged = useCallback(
    debounce((text) => {
      let value = text;
      history.push("/search?q=" + value);
    }, 500),
    []
  );

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setLogedIn(false);
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const mobileSearchToggle = () => {
    setMobileSearch(!mobileSearch);
    const srchTxtBox = searchTextbox.current;

    srchTxtBox.focus();

    if (srchTxtBox.value.length > 0) {
      srchTxtBox.value = "";
    } else {
    }

    console.log("Search Textbox - ", srchTxtBox.length);
  };

  /* const getSubscribePlans = () => {
    axios
      .get(url + "/plans")
      .then((res) => {
        console.log("Plans - ", res);
        setSubscribe(res.data.plans);
      })
      .catch((err) => {
        setSubscribe([]);
      });
  }; */

  const loginShow = () => {
    setUserMenu(false);

    console.log('Header Location = ', window.location.pathname);
    dispatch({
      type: LOGIN_REDIRECT_URL,
      payload: {
        redirectUrl: window.location.pathname,
      },
    });

    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: true,
      },
    });

  };

  // Change Language
  const changeLanguage = (lang) => {
    console.log('Change language - ', lang);
    localStorage.setItem('lan', JSON.stringify(lang));
    i18n.changeLanguage(lang);
    setLanguageDropdown(false);
    dispatch({
      type: LANGUAGE,
      payload: {
        language: lang
      },
    });
  }

  useEffect(() => {
    // Subscription pricing
    /* getSubscribePlans(); */


    setSubscribe(state.plans ? state.plans : []);

    if(state.token && state._id) {
      setLogedIn(true);
      setUserMenu(false);

      let localStorageTS = localStorage.getItem('ts');

      if(localStorage.getItem('profile') && localStorage.getItem('ts')) {
        if (parseFloat(localStorageTS) <= Date.now()) {
          localStorage.removeItem("ts");
          localStorage.removeItem("profile");
          history.push("/account/profiles");
        } else {

          dispatch({
            type: PROFILE,
            payload: {
              profileName: localStorage.getItem("profile"),
            },
          });

        }
      } else {
        history.push("/account/profiles");
      }


      /* if(!sessionStorage.getItem('profile')) {
        history.push('/account/profiles');

      } else {
        dispatch({
          type: PROFILE,
          payload: {
            profileName: sessionStorage.getItem('profile')
          }
        })
      } */

    } else {
      setLogedIn(false);
    }





  }, [state.plans, state.token, state._id]);

  return (
    <header className="full-width header">
      <div className="container">
        <div className="row">
          <div className="col-5 col-md-2 col-lg-1 col-xl-1 order-0">
            <div className="branding full-width">
              <button
                className="mobile-menu-toggle-btn btn d-md-none"
                type="button"
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                <MenuOutline
                  cssClasses="text-gray"
                  title={"Back"}
                  height="24px"
                  width="24px"
                />
              </button>
              <Link to="/" className="branding-link" title="RVR">
                <img src={RVRLogo} alt="RVR" />
              </Link>
            </div>
          </div>
          <div className="col-md-5 col-lg-6 col-xl-6 order-2 order-md-1">
            <div
              className={
                mobileMenu
                  ? "full-width main-nav-block show-menu"
                  : "full-width main-nav-block"
              }
            >
              <i className="mobile-nav-overlay d-md-none"></i>
              <div className="main-nav-list-block full-width">
                <button
                  className="closeMobileNavBtn btn btn-link d-md-none"
                  type="button"
                  onClick={() => setMobileMenu(!mobileMenu)}
                >
                  <CloseCircle
                    cssClasses="text-gray-2"
                    title={"Back"}
                    height="22px"
                    width="22px"
                    color={"#ccc"}
                  />
                </button>
                <ul className="main-nav-list mb-0 list-unstyled full-width">
                  {state.isAuthenticated === false &&
                  state.activeSubscription === false ? (
                    <li className="d-md-none pb-2">
                      <NavLink
                        to="/subscribe"
                        className="mobileSubscribe"
                        exact={true}
                        activeClassName="activeLink"
                        onClick={() => setMobileMenu(!mobileMenu)}
                      >
                        {t("tryFor", "Try for")}{" "}
                        <span className="try-rupee">
                          <Rupee />
                        </span>{" "}
                        <span className="try-rupee-text">
                          {state.plans && state.plans.length > 0
                            ? state.plans[0].price
                            : null}
                        </span>{" "}
                      </NavLink>
                    </li>
                  ) : null}

                  {state.isAuthenticated &&
                  state.activeSubscription === false &&
                  subscribe.length > 0 ? (
                    <li className="d-md-none pb-2">
                      <NavLink
                        to="/subscribe"
                        className="mobileSubscribe"
                        exact={true}
                        activeClassName="activeLink"
                        onClick={() => setMobileMenu(!mobileMenu)}
                      >
                        {t("tryFor", "Try for")}{" "}
                        <span className="try-rupee">
                          <Rupee />
                        </span>{" "}
                        <span className="try-rupee-text">
                          {state.plans && state.plans.length > 0
                            ? state.plans[0].price
                            : null}
                        </span>{" "}
                      </NavLink>
                    </li>
                  ) : null}
                  <li>
                    <NavLink
                      exact={true}
                      to="/"
                      activeClassName="activeLink"
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {t("home", "Home")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/movies"
                      exact={true}
                      activeClassName="activeLink"
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {t("movies", "Movies")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/series"
                      exact={true}
                      activeClassName="activeLink"
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {t("series", "Series")}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      exact={true}
                      to="/tv"
                      activeClassName="activeLink"
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {t("liveTv", "Live TV")}
                    </NavLink>
                  </li> */}
                  {logedIn ? (
                    <li className="d-md-none d-lg-block">
                      <NavLink
                        exact={true}
                        to="/account/wishlist"
                        activeClassName="activeLink"
                        onClick={() => setMobileMenu(!mobileMenu)}
                      >
                        {t("wishlist", "WishList")}
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-7 col-md-5 col-lg-5 col-xl-5 order-1 order-md-2">
            <div className="full-width header-right-actions-block">
              {subscribe.length > 0 ? (
                <NavLink
                  exact={true}
                  to="/subscribe"
                  className="headerSubscribeBtn d-none d-md-inline-flex"
                  activeClassName="activeLink"
                >
                  {t("tryFor", "Try for")}{" "}
                  <span className="try-rupee">
                    <Rupee />
                  </span>{" "}
                  <span className="try-rupee-text">
                    {subscribe[0] ? subscribe[0].price : null}
                  </span>
                </NavLink>
              ) : null}

              <button
                className={
                  mobileSearch
                    ? "btn mobileHeaderSearchBtn mobile-search-active"
                    : "btn mobileHeaderSearchBtn"
                }
                type="button"
                onClick={() => mobileSearchToggle()}
              >
                <SearchOutline
                  cssClasses="text-gray-2 searchIcon"
                  title={"Back"}
                  height="20px"
                  width="20px"
                />
                <CloseOutline
                  cssClasses="text-gray-2 searchCloseIcon"
                  title={"Back"}
                  height="18px"
                  width="18px"
                />
              </button>
              <div
                className={
                  languageDropdown
                    ? "toggleLanguageBlock openLanguageMenu"
                    : "toggleLanguageBlock"
                }
              >
                <button
                  className="btn toggleLanguageBtn"
                  type="button"
                  onClick={() => setLanguageDropdown(!languageDropdown)}
                >
                  <LanguageOutline cssClasses="languageIcon" />
                </button>
                <ul className="languages-list list-unstyled">
                  <li>
                    <button
                      className="each-language btn selected"
                      type="button"
                      onClick={() => changeLanguage("en")}
                    >
                      {t("translation:te", "English")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="each-language btn"
                      type="button"
                      onClick={() => changeLanguage("te")}
                    >
                      {/* తెలుగు */}
                      {t("translation:te", "తెలుగు")}
                    </button>
                  </li>
                </ul>
              </div>
              <div
                className={
                  mobileSearch
                    ? logedIn
                      ? "header-search-block show-mobile-search"
                      : "header-search-block show-mobile-search before-login"
                    : "header-search-block"
                }
              >
                <input
                  type="text"
                  className="searchInput"
                  onChange={(e) =>
                    searchChanged(
                      e.target.value
                        .slice(0, 20)
                        .toLowerCase()
                        .replaceAll(" ", "+")
                        .replace(/[^a-z0-9'+]{1,15}/gi, "")
                    )
                  }
                  ref={(node) => (searchTextbox.current = node)}
                />
              </div>
              {!logedIn ? (
                <button
                  type="button"
                  className="headerLoginBtn"
                  onClick={() => loginShow()}
                >
                  {t("login", "Login")}
                </button>
              ) : (
                <div className="header-my-account">
                  <button
                    className={
                      userMenu
                        ? "btn my-account-toggle-btn open-menu"
                        : "btn my-account-toggle-btn"
                    }
                    type="button"
                    onClick={() => setUserMenu(!userMenu)}
                  >
                    <span className="header-profile-icon profile-bg pf-3">
                      <img src={ParentSmiley} alt="" />
                    </span>{" "}
                    <span className="profile-user-name">
                      {
                        /* state.isAuthenticated
                        ? firebaseUser
                          ? firebase.auth().currentUser.displayName
                            ? firebase.auth().currentUser.displayName
                            : "My Account"
                          : null
                        : null */
                        state.profileName
                      }
                    </span>
                  </button>

                  {userMenu ? (
                    <ul className="my-account-list list-unstyled mb-0">
                      {/* <li>
                        <NavLink
                          to="/"
                          className="my-account-item-link profile-icon-name"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          <span className="header-menu-profile-icon profile-bg pf-3">
                            <img src={ParentSmiley} alt="" />
                          </span>
                          <span className="header-menu-profile-name">
                            Profile Name 1
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="my-account-item-link profile-icon-name"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          <span className="header-menu-profile-icon profile-bg pf-1">
                            <img src={ParentSmiley} alt="" />
                          </span>
                          <span className="header-menu-profile-name">
                            Profile Name 2
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="my-account-item-link profile-icon-name"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          <span className="header-menu-profile-icon profile-bg pf-2">
                            <img src={KidSmiley} alt="" />
                          </span>
                          <span className="header-menu-profile-name">
                            <LockClosed
                              width={"15px"}
                              height={"15px"}
                              color={"#ccc"}
                            />{" "}
                            Children
                          </span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to="/account/profiles"
                          className="my-account-item-link"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          {t("manageProfiles", "Manage Profiles")}
                        </NavLink>
                      </li>
                      <li>
                        <i className="sep-div w-100" />
                      </li>
                      <li>
                        <NavLink
                          to="/account/my-subscriptions"
                          className="my-account-item-link"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          {t("mySubscriptions", "My Subscriptions")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/account/profiles"
                          className="my-account-item-link"
                          exact={true}
                          activeClassName="my-account-active-link"
                          onClick={() => setUserMenu(false)}
                        >
                          {t("account", "Account")}
                        </NavLink>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="my-account-item-link btn logout-btn"
                          onClick={() => logout()}
                        >
                          {t("logout", "Logout")}
                        </button>
                      </li>
                    </ul>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
