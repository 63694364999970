import React, {useEffect, useContext} from "react";
import { Context } from "../../../Context";
import { LOGIN_POPUP } from "../../reducer/types";
import "./page404.scss";

const Page404 = () => {

  const {state, dispatch} = useContext(Context);

  useEffect(() => {
    window.scroll(0, 0, "smooth");
      dispatch({
        type: LOGIN_POPUP,
        payload: {
          loginPopup: false
        }
      })
  }, [])
  return (
    <div className="full-width">
      <div className="page-error">
        Page 404!!! <br />
        Page Not found
      </div>
    </div>
  );
};

export default Page404;
