import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Custom Imports
import {
  LOGIN_POPUP,
  THEME,
  LOGIN_SUCCESS,
  PLANS,
  HEADER,
  LANGUAGE
} from "../reducer/types";
import { firebase_app as firebase } from "../firebase";
import "../../assets/styles/bootstrap/bootstrap.scss";
import { url } from "../components/API";

import Header from "../components/Header";
import Footer from "../components/Footer";

import Home from "../pages/Home";
import TV from "../pages/TV";
import Movies from "../pages/Movies";
import Series from "../pages/Series";
import Page404 from "../pages/Page404";
import Genre from "../pages/Genre";
import MovieDetail from "../pages/MovieDetail";
import SeriesDetail from "../pages/SeriesDetail";
import Subscribe from "../pages/Subscribe";
import LoginRedirect from "../pages/LoginRedirect";
import AboutUs from "../pages/AboutUs";
import TermsConditions from "../pages/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import FAQs from "../pages/FAQs";
import Search from "../pages/Search";
import Wishlist from "../pages/Wishlist";
import MyProfile from "../pages/MyProfile";

import { Context } from "../../Context";
import MySubscriptions from "../pages/MySubscriptions";
import { getUserDetails } from "../components/API";
import Watch from "../pages/Watch";
import Profiles from "../pages/Profiles";
import RefundPolicy from "../pages/Refunds";

const PagesRouter = () => {
  const [logedIn, setLogedIn] = useState(false);
  const [theme, setTheme] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [headerToggle, setHeaderToggle] = useState(true);
  const [lang, setLang] = useState('en')

  const { state, dispatch } = useContext(Context);

  const history = useHistory();

  const { t, i18n } = useTranslation();


  /* const changeTheme = (theme) => {
    setTheme(theme);
    dispatch({
      type: THEME,
      payload: {
        theme: theme,
      },
    });
  } */

  const getSubscribePlans = async () => {
    await axios.get(url + "/plans").then((res) => {
      console.log("Plans - ", res);
      dispatch({
        type: PLANS,
        payload: {
          ...res.data,
        },
      });
    });

    return true;
  };

  useEffect(() => {
    setLang(state.language);

    setTheme("dark-1");
    getSubscribePlans();

     i18n.on("languageChanged", (e) => {
       dispatch({
         type: LANGUAGE,
         payload: {
           language: i18n.language,
         },
       });
       setLang(e);
     });

     dispatch({
       type: LANGUAGE,
       payload: {
         language: i18n.language,
       },
     });


    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((loginRes) => {
            console.log("Name - ", loginRes.claims.name);
            let sessionProfile = sessionStorage.getItem("profile");

            getUserDetails(loginRes.token)
              .then((res) => {
                console.log(" Router Get User Details - ", res);

                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: {
                    ...res,
                  },
                });

              })
              .catch((err) => {
                console.log("Get user detials err - ", err);
              });


          });
      } else {
        // User is signed out
        setLogedIn(false);
        //console.log("Header user err - ", user);
      }
    });

  }, []);

  return (
    <div className={`full-width page bodyBg ${state.theme} ${state.language}`}>
      <Router>
        {state.header ? <Header /> : ""}
        <div className="full-width min-height">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            {/* <Route exact path="/tv">
              <TV />
            </Route> */}

            <Route exact path="/movies">
              <Movies />
            </Route>
            <Route exact path="/movies/:id">
              <MovieDetail />
            </Route>
            <Route exact path="/series">
              <Series />
            </Route>
            <Route exact path="/series/:id">
              <SeriesDetail />
            </Route>
            {/* <Route exact path="/genre/:id">
              <Genre />
            </Route>
            */}
            <Route path={"/search"}>
              <Search />
            </Route>

            {/* Pages */}
            <Route exact path="/about-us">
              <AboutUs />
            </Route>
            <Route exact path="/terms-conditions">
              <TermsConditions />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/refund-policy">
              <RefundPolicy />
            </Route>

            <Route exact path="/faqs">
              <FAQs />
            </Route>

            {/* Authentication */}
            <Route exact path="/auth/redirect">
              <LoginRedirect />
            </Route>

            <Route exact path="/account/wishlist">
              <Wishlist />
            </Route>

            <Route exact path="/account/my-profile">
              <MyProfile />
            </Route>

            <Route exact path="/account/my-subscriptions">
              <MySubscriptions />
            </Route>

            <Route exact path="/subscribe">
              <Subscribe />
            </Route>

            {/* <Route exact path="/watch">
              <Watch />
            </Route> */}

            <Route exact path="/account/profiles">
              <Profiles />
            </Route>

            <Route component={Page404} />
          </Switch>
        </div>
        {state.header ? <Footer /> : ""}
      </Router>
    </div>
  );
};

export default PagesRouter;
