import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import {CheckmarkCircle } from "react-ionicons";
import { useTranslation } from "react-i18next";

// Custom imports
import "./subscribe.scss";
import { url } from "../../components/API";
import {Context} from '../../../Context';
import {LOGIN_POPUP, SELECTED_PLAN} from '../../reducer/types';

// Animation
import { AnimationContainer, AnimationItem } from "../../components/Misc";

import { Close, Checkmark, ChevronForward } from "react-ionicons";
import { Rupee } from "../../components/Misc";

const Subscribe = () => {
  const [subscribe, setSubscribe] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [promoCode, setPromoCode] = useState(null);

  const {state, dispatch} = useContext(Context);
  const history = useHistory();
  const {t} = useTranslation();



  const subscribePlan = (id, name, price) => {
    console.log('Selected plan - ', name);

    dispatch({
      type: SELECTED_PLAN,
      payload: {
        selectedPlan: id,
        selectedPlanName: name,
        selectedPlanPrice: price,
      },
    });

    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: true,
      },
    });
  }

  useEffect(() => {
    /* getSubscribePlans(); */
    setSubscribe(state.plans);
    console.log("Select plan - ", selectPlan);


  }, [state.plans]);

  return (
    <div className="full-width py-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="full-width subscribe-block">
              <div className="full-width subscribe-title mb-2 text-center">
                {t("subscribeToGetMore", "Subscribe to get more out of RVR")}
              </div>
              <div className="full-width subscription-columns">
                <motion.div
                  className="row justify-content-center"
                  variants={AnimationContainer}
                  initial="hidden"
                  animate="show"
                >
                  {subscribe.length
                    ? subscribe.map((plan, index) => (
                        <motion.div
                          className="col-12 col-md-6 col-lg-3"
                          variants={AnimationItem}
                          initial="hidden"
                          animate="show"
                          key={"subscib" + index}
                        >
                          <div className="each-subscription-column full-width">
                            <div className="subscription-radio radio">
                              {/* <input
                                type="radio"
                                className="radio-input"
                                name="subscription"
                                onChange={() => setSelectPlan(plan._id)}
                                checked={selectPlan === plan._id ? true : false}
                              /> */}
                              <div className="subscription-details full-width">
                                <i className="selected-icon-block">
                                  <CheckmarkCircle />
                                </i>
                                <div className="subscription-details-block full-width">
                                  <div className="each-subscription-column-title full-">
                                    {plan.name}
                                  </div>
                                  <div className="each-subscription-price full-width">
                                    <i className="rupee-currency">
                                      <Rupee />
                                    </i>
                                    <span className="price-text">
                                      {plan.price}
                                    </span>
                                  </div>
                                  <div className="each-subscription-benefits full-width">
                                    {plan.features.length ? (
                                      <ul className="each-subscription-benefits-list list-unstyled full-width mb-0">
                                        {plan.features.map((benefit, index) => (
                                          <li key={"ben" + index}>{benefit}</li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </div>
                                  <div
                                    className="each-subscription-action full-width"
                                    onClick={() =>
                                      subscribePlan(
                                        plan._id,
                                        plan.name,
                                        plan.price
                                      )
                                    }
                                  >
                                    <button className="buy-subscription-btn btn btn-primary full-width">
                                      Subscribe
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      ))
                    : null}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
