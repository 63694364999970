import React, { useEffect, useState, useContext, useRef } from "react";
import {
  PersonCircleOutline,
  Pencil,
  ChevronForward,
  CloseCircleOutline,
} from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import { animate, motion } from "framer-motion";
import {
  ArrowBackOutline,
  MailUnreadOutline,
  LogoFacebook,
  LogoGoogle,
  CallOutline,
} from "react-ionicons";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ParentSmiley from "../../../assets/icons/smiley-1.svg";
import KidSmiley from "../../../assets/icons/smiley-kid-1.svg";
import KidSmiley2 from "../../../assets/icons/smiley-kid-2.svg";
import KidSmiley3 from "../../../assets/icons/smileys-kid-3.svg";

// Custom import
import { Context } from "../../../Context";
import "./my-profile.scss";
import "../../components/Login/login.scss";
import { firebase_app as firebase } from "../../firebase";
import {
  LANGUAGE,
  LOGIN_POPUP,
  LOGOUT_SUCCESS,
  HEADER,
} from "../../reducer/types";

// Validation Name
const nameValidation = Yup.object().shape({
  name: Yup.string()
    .required("Enter your name")
    .min(5, "Must be min 5 letters"),
});

const MyProfile = () => {
  const [updateName, setUpdateName] = useState(false);
  const [profileNameError, setProfileNameError] = useState(null);

  const { state, dispatch } = useContext(Context);

  const languageRef = useRef();

  const maturityRef = useRef();

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  //Open Login popup
  const openLogin = () => {
    dispatch({
      type: LOGIN_POPUP,
      payload: {
        loginPopup: true,
      },
    });
  };

  // Open update name popup
  const editProifleName = () => {
    setUpdateName(true);
  };

  // Close Update Name Popup
  const loginPopupClose = () => {
    setUpdateName(false);
  };

  // Got name value
  const updateProfileName = (val) => {
    console.log("Updating name value = ", val.name);

    firebase
      .auth()
      .currentUser.updateProfile({
        displayName: val.name,
        photoURL: null,
      })
      .then((res) => {
        loginPopupClose();
      })
      .catch((err) => {
        setProfileNameError(err.message);
      });
  };

  // Toggle Language
  const languageChanged = () => {
    i18n.changeLanguage(languageRef.current.value);
    dispatch({
      type: LANGUAGE,
      payload: {
        language: languageRef.current.value,
      },
    });
  };

  useEffect(
    () => {
      console.log("State - ", state);




      if (!state.isAuthenticated) {
        dispatch({
          type: LOGIN_POPUP,
          payload: {
            loginPopup: true,
          },
        });
      } else {
        dispatch({
          type: LOGIN_POPUP,
          payload: {
            loginPopup: false,
          },
        });
      }

      return () => {
        dispatch({
          type: HEADER,
          payload: {
            header: true,
          },
        });
      };
    },
    [state.isAuthenticated, state.language, state.header]
  );

  return (
    <div className="full-width page">
      {state.isAuthenticated ? (
        <div className="container">
          <div className="row mt-3">
            <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mt-1">
              <div className="full-width my-profile-new">
                <div className="profile-photo-block">
                  <span className="photo-design-block gr-3">
                    <img src={KidSmiley} alt="" />
                  </span>
                </div>
                <div className="profile-details-info">
                  <div className="profile-name-desg full-width">
                    <div className="profile-name full-width">
                      {firebase.auth().currentUser.displayName
                        ? firebase.auth().currentUser.displayName
                        : "My Account"}{" "}
                      <button className="edit-profile-name btn" type="button">
                        <Pencil
                          color={"#aaa"}
                          title="Edit"
                          height="10px"
                          width="10px"
                          cssClasses="ml-05 edit-user-name"
                        />
                      </button>
                    </div>
                    <div className="profile-phone-mobile full-">
                      {firebase.auth().currentUser.email
                        ? firebase.auth().currentUser.email
                        : ""}
                      {firebase.auth().currentUser.phone
                        ? firebase.auth().currentUser.phone
                        : ""}
                    </div>
                  </div>

                  <div className="each-row full-width">
                    <div className="each-row-label full-width">
                      Lock for Children
                    </div>
                    <div className="profile-language-select-block full-width">
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="switch-input"
                          checked
                          disabled
                        />
                        <span className="switch-content">Lock for Child</span>
                      </label>
                    </div>
                    <div className="full-width edit-profile-child-control">
                      <button className="btn edit-profile-child" type="button">
                        Edit
                      </button>
                    </div>
                    <div className="each-row-label other-info full-width">
                      Only show titles rated PG and below for this profile
                    </div>
                  </div>
                </div>
              </div>

              <div className="full-width">
                {/* state.activeSubscription === false ? (
                  <div className="full-width subscribe-now-block mt-2 p-2">
                    <div className="subscribe-now-content full-width">
                      <div className="subscribe-now-content-title full-width">
                        Watch more than 100+ movies
                      </div>
                      <div className="subscribe-now-btn-block mt-05 full-width">
                        <Link to="/subscribe" className="subscribe-now-btn">
                          Subscribe Now
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : null */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="full-width pleaseLoginBlock">
          <div className="full-width text-center">
            Hey!!! Check your profile now! <br />
            <button
              className="btn loginNowBtn"
              type="button"
              onClick={() => openLogin()}
            >
              Click here to Login Now
            </button>
          </div>
        </div>
      )}

      {/* Profile Name Change Starts */}
      <div>
        {updateName ? (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="login-outer-block"
          >
            <motion.i
              className="login-outer-overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
            ></motion.i>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="login-form-outer-block"
            >
              <div className="login-block">
                <div className="full-width login-main">
                  <div className="full-width login-main-block">
                    <div className="login-form-header full-width">
                      <div className="login-title">Update Profile Name</div>
                      <button
                        className="btn login-close-btn"
                        type="button"
                        onClick={() => loginPopupClose()}
                      >
                        <CloseCircleOutline color={"#999"} />
                      </button>
                    </div>
                    <div className="login-form-content full-width">
                      <div className="full-width">
                        {profileNameError ? (
                          <div className="alert alert-danger">
                            {profileNameError}
                          </div>
                        ) : null}
                      </div>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          phone: "",
                          country: "+91",
                        }}
                        validationSchema={nameValidation}
                        onSubmit={(values) => {
                          console.log(values);
                          updateProfileName(values);
                        }}
                      >
                        {(formik) => {
                          const { isValid, dirty, isValidating, isSubmitting } =
                            formik;
                          return (
                            <Form className="login-form mt-2">
                              <ul className="form-list list-unstyled full-width mb-0">
                                <li>
                                  <div className="form-item full-width d-flex flex-wrap">
                                    <div className="full-width border-bottom border-gray">
                                      <Field
                                        name="name"
                                        id="name"
                                        type="text"
                                        className="form-control login-input"
                                        placeholder="Enter your name"
                                      />
                                    </div>
                                    <div className="full-width">
                                      <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="form-error"
                                      />
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="form-item full-width mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary submit-phone-btn full-width"
                                      id="loginSubmit"
                                      disabled={
                                        isSubmitting
                                          ? !profileNameError
                                            ? true
                                            : false
                                          : false
                                      }
                                    >
                                      {isSubmitting ? (
                                        !profileNameError ? (
                                          <span>Please wait...</span>
                                        ) : (
                                          <span>
                                            Continue{" "}
                                            <ChevronForward
                                              title={"Back"}
                                              height="14px"
                                              width="14px"
                                              color="white"
                                            />
                                          </span>
                                        )
                                      ) : (
                                        <span>
                                          Update{" "}
                                          <ChevronForward
                                            title={"Back"}
                                            height="14px"
                                            width="14px"
                                            color="white"
                                          />
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        ) : null}
      </div>
      {/* Profile Name Change Ends */}
    </div>
  );
};

export default MyProfile;
